import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UntypedFormBuilder} from '@angular/forms';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {PaginatedDataResponse} from '../../../../../shared/models/respose/PaginatedDataResponse';
import {CostCenter} from '../../../../../shared/models/CostCenter';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CostCenterService} from '../../../../services/cost-center.service';
import {UserService} from '../../../../services/user.service';
import {Order} from '../../../../../shared/models/Order';
import {DirectionType} from '../../../../enum/DirectionType';

@Component({
  selector: 'app-cost-centers',
  templateUrl: './cost-centers.component.html',
  styleUrls: ['./cost-centers.component.css']
})
export class CostCentersComponent extends DictionaryComponent<CostCenter> implements OnInit {
  public paginatedResponse: PaginatedDataResponse<CostCenter> = new PaginatedDataResponse<CostCenter>(0, [], 0, 0, 0);
  
  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    public userService: UserService,
    public service: CostCenterService
  ) {
    super(modalService, fb, service);
  }

  ngOnInit(): void {
    this.order = new Order('name', DirectionType.asc);
    this.form = this.fb.group({
      id: [null],
      name: [''],
      email: [''],
      supervisor_id: [''],
      has_daily_report: [false]
    });
    this.formSearch = this.fb.group({
      name: ['']
    });
    this.updateList({page: 1, elements: this.elements});
  }

  onSubmit({ value, valid }: { value: CostCenter, valid: boolean }): void{
    if (valid){
      if (value.id){
        this.callUpdate(value);
      }
      else{
        this.callRegister(value);
      }
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  onFind({value, valid}: { value: any, valid: boolean }): void {
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(params: any = { page: 1 }): void {
    const orderParam = this.order.attribute === 'supervisor' ? 'supervisor' : this.order.attribute;
    const directionParam = this.order.direction;
  
    this.service.getPaginatedList(100, params.page, this.formSearch.value, new Order(orderParam, directionParam))
      .subscribe(response => {
        this.paginatedResponse = response.data;
        // console.log(this.paginatedResponse);
      });
  }  
  
  getSupervisorService(search: any, id?: number): Observable<any>{
    return this.userService.getFiltered({id: 0, name : search, is_manager : true}, new Order('id' , DirectionType.asc));
  }
}
