import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {Source} from '../../shared/models/Source';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SourceService extends HttpService {

  getAll(): Observable<BaseResponse<Source[]>>{
    return this.get('sources');
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: Source,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<Source>>>{
    const request = new PaginatedDataRequest<Source>(entries, page, data, order);
    return this.post('sources', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Source): Observable<BaseResponse<Source>>{
    return this.post('sources/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Source>>{
    return this.delete('sources', id);
  }

  updateItem(request: Source, id: number): Observable<BaseResponse<Source>>{
    return this.put('sources', request, id);
  }
}
