import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

  @Input() label = '';
  @Input() min = '';
  @Input() max = '';
  @Input() type = 'text';
  @Input() controlName = '';
  @Input() readonly = false;
  // @ts-ignore
  @Input() form: UntypedFormGroup;
  @Input() enableNumeric = false;
  @Input() value: any;

  constructor() { }

  ngOnInit(): void {
    if (this.enableNumeric) {
      this.addNumericValidation();
    }
  }

  addNumericValidation(): void {
    if (this.form !== undefined && this.form != null) {
      const control = this.form.get(this.controlName);
      if (control) {
        control.setValidators(this.numericValidator);

        control.valueChanges.subscribe((newValue) => {
          if (typeof newValue === 'string' && newValue.includes(',')) {
            const newValueWithDot = newValue.replace(',', '.');
            control.setValue(newValueWithDot, { emitEvent: false });
          }
        });
      }
    }
  }

  onInputChange(event: any) {
    if (this.enableNumeric && typeof event.target.value === 'string' && event.target.value.includes(',')) {
      const newValueWithDot = event.target.value.replace(',', '.');
      this.form.get(this.controlName)?.setValue(newValueWithDot, { emitEvent: false });
    }
  }

  numericValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null;
    }

    // Regular expression to match numeric input with comma and dot
    const numericPattern = /^[-+]?[\d.,]+$/;

    if (!numericPattern.test(value)) {
      return { numeric: true };
    }

    return null;
  }

  getInvalidClass(element: string): string {
    if (this.form !== undefined && this.form != null) {
      return (this.form?.get(element)?.invalid && (this.form?.get(element)?.dirty || this.form?.get(element)?.touched)) ? 'is-invalid' : '';
    }
    return '';
  }

}
