import {Validators} from '@angular/forms';

export let companyFormAttributes = {
  id: [null],
  email: ['', [Validators.email]],
  email_list: [''],
  work_number: [''],
  work_number_admin: [''],
  contact_type_id: [''],
  source_id: [''],
  is_company: [true],
  company_name: ['', [Validators.required]],
  cap: ['', ],
  nominativo_amministratore: [''],
  fiscal_code: [''],
  company_activity_id: [''],
  company_type: [''],
  tipo_di_sede: [''],
  forma_legale: [''],
  forma_legale_dettagliata: [''],
  stato_impresa: [''],
  crif_number: [''],
  cciaa: [''],
  rea: [''],
  data_iscrizione_in_camera_di_commercio: [''],
  anno_iscrizione_in_camera_di_commercio: [''],
  data_inizio_attivita: [''],
  data_costituzione: [''],
  data_di_fondazione: [''],
  insegna: [''],
  via: [''],
  frazione: [''],
  city_id: [''],
  province_id: [''],
  marketable_truefalse: [''],
  unita_locali: [''],
  tipologia_unita_locale: [''],
  codice_ateco_primario_2007_primo_livello: [''],
  codice_ateco_primario_2007: [''],
  descrizione_codice_ateco_primario_2007: [''],
  codice_ateco_secondario_2007: [''],
  nace: [''],
  descrizione_codice_nace: [''],
  sic_primario: [''],
  descrizione_codice_sic_primario: [''],
  sic_secondario: [''],
  data_di_bilancio: [''],
  personnel_number_id: [''],
  classe_di_fatturato: [''],
  anno_fatturato: [''],
  fatturato_desercizio: [''],
  trend_fatturato_di_esercizio: [''],
  dipendenti: [''],
  trend_dipendenti: [''],
  ebitda: [''],
  ebit: [''],
  company_dimension_id: [''],
  fatturato_consolidato: [''],
  percentuale_dipendenti_contratto_a_tempo_determinato: [''],
  percentuale_dipendenti_contratto_a_tempo_indeterminato: [''],
  percentuale_dipendenti_contratto_a_tempo_parziale: [''],
  percentuale_dipendenti_contratto_a_tempo_pieno: [''],
  matr_inps: [''],
  matr_inail: [''],
  startup_innovativa: [''],
  pmi_innovative: [''],
  fax: [''],
  indirizzo_e_commerce: [''],
  sito_web: [''],
  indirizzo_pec: [''],
  social_attivi: [''],
  facebook: [''],
  youtube: [''],
  twitter: [''],
  instagram: [''],
  linkedin: [''],
  pinterest: [''],
  vimeo: [''],
  azienda_della_pubblica_amministrazione: [''],
  user_id: [''],
  note: [''],
  codice_interno: [''],
  referente: ['', Validators.required],
  sdi: ['']
};
