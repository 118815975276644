import { Component } from '@angular/core';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {AssignmentType} from '../../../../../shared/models/AssignmentType';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {AssignmentTypeService} from '../../../../services/assignment-type.service';

@Component({
  selector: 'app-assignment-types',
  templateUrl: './assignment-types.component.html',
  styleUrls: ['./assignment-types.component.css']
})
export class AssignmentTypesComponent extends DictionaryComponent<AssignmentType>  {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: AssignmentTypeService
  ) {
    super(modalService, fb, service);
  }

}
