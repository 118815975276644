import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {ContractCategory} from '../../shared/models/ContractCategory';

@Injectable({
  providedIn: 'root'
})
export class ContractCategoryService extends DictionaryService<ContractCategory> {

  path = 'contract_categories';

}
