import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {Department} from '../../../../../shared/models/Department';
import {DepartmentService} from '../../../../services/department.service';
import {Observable} from 'rxjs';
import {ContractCategoryService} from '../../../../services/contract-category.service';
import {Order} from '../../../../../shared/models/Order';
import {DirectionType} from '../../../../enum/DirectionType';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent extends DictionaryComponent<Department> implements OnInit{

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: DepartmentService,
    private contractCategoryService: ContractCategoryService
  ) {
    super(modalService, fb, service);
  }

  ngOnInit(): void {
    this.order = new Order('name', DirectionType.asc);
    this.form = this.fb.group({
      id: [null],
      name: ['', [Validators.required]],
      contract_category_id: ['', [Validators.required]]
    });
    this.formSearch = this.fb.group({
      name: ['']
    });
  }

  edit(object: Department): void {
    this.form.patchValue({
      ...object,
      contract_category_id: object?.contract_category?.id
    });
    this.showHideForm();
  }

  getContractCategoryService(search: any, id?: number): Observable<any>{
    return this.contractCategoryService.getAll();
  }


}
