import {PossibleUndefined} from '../../../shared/types/types';

export class CompanyRequest {
  id?: number;
  company_name?: string;
  company_activity_id?: number;
  iva?: string;
  fiscal_code?: string;
  company_type?: string;
  cap?: string;

  nominativo_amministratore?: string;
  is_company?: boolean;
  ids?: PossibleUndefined<number>[];
  excluded_ids?: PossibleUndefined<number>[];
  city_name?: string;
  fatturato_desercizio_from?: number;
  fatturato_desercizio_to?: number;
  min_activity?: number;
  personnel_number_id?: number;
  azienda_della_pubblica_amministrazione?: boolean;
  startup_innovativa?: boolean;
  pmi_innovative?: boolean;
  company_dimension_id?: boolean;
  searchbar?: string;
}


