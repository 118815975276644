import {Validators} from '@angular/forms';

export let contactFormAttributes = {
  id: [null],
  first_name: ['', [Validators.required]],
  last_name: ['', [Validators.required]],
  birth_date: [''],
  email: ['', [Validators.email]],
  work_number: ['', [Validators.pattern(/^\d{9,}$/)]],
  mobile_number: ['', [Validators.pattern(/^\d{9,}$/)]],
  contact_type_id: [''],
  position_id: [''],
  source_id: [''],
  is_company: [''],
  user_id: [''],
  gender_id: ['', Validators.required],
  fiscal_code: [''],
  note: [''],
  income_type_id: ['', Validators.required]
};
