import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {MachineType} from '../../shared/models/MachineType';

@Injectable({
  providedIn: 'root'
})
export class MachineTypeService extends DictionaryService<MachineType> {

  path = 'machine_types';

}
