import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {Province} from '../../shared/models/Province';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService extends HttpService
{

  getAll(): Observable<BaseResponse<Province[]>>{
    return this.get('provinces');
  }
  
}
