import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  protected getAuthHeader(): HttpHeaders {
    if (this.token !== ''){
      return new HttpHeaders(
        {
          Authorization: 'Bearer ' + this.token
        }
      );
    }
    return new HttpHeaders();
  }
    
  protected getNoAuth<T>(path: string, options?: { responseType?: 'blob' | 'json' | 'text' }): Observable<T> {
    const responseType = (options && options.responseType) || 'json';
    return this.http.get<T>(`${environment.api}${path}`, {
      headers: new HttpHeaders(),
      responseType: responseType as 'json'
    }).pipe(
      tap(response => response)
    );
  }
  
  protected get(path: string): Observable<any> {
    return this.http.get(this.environmentPath + path, {headers: this.getAuthHeader()}).pipe(
      tap(response => response)
    );
  }

  protected post(path: string, request: any, type: 'arraybuffer'|'blob'|'json'|'text' = 'json'): Observable<any> {
      // @ts-ignore
    return this.http.post(this.environmentPath + path, request, {headers: this.getAuthHeader(), responseType: type}).pipe(
        tap((response) => response)
      );
  }

  protected deleteByPath(path: string): Observable<any>{
    return this.http.delete(this.environmentPath + path, {headers: this.getAuthHeader()}).pipe(
      tap((response) => response)
    );
  }

  protected delete(path: string, id: number): Observable<any>{
      return this.http.delete(this.environmentPath + path + '/' + id, {headers: this.getAuthHeader()}).pipe(
        tap((response) => response)
      );
  }

  protected patch(path: string, request: any, id?: number, additionalPath: string = ''): Observable<any>{
    const completePath = (id) ? this.environmentPath + path + '/' + id + additionalPath : this.environmentPath + path;
    return this.http.patch(completePath, request, {headers: this.getAuthHeader()}).pipe(
      tap((response) => response)
    );
  }

  protected put(path: string, request: any, id: number): Observable<any> {
    return this.http.put(this.environmentPath + path + '/' + id, request, {headers: this.getAuthHeader()}).pipe(
      tap((response) => response)
    );
  }

  protected get environmentPath(): string{
    switch (this.router.url.split('/')[1]){
      case 'otp':
        return environment.api_otp;
      default:
        return environment.api;
    }
  }

  private get token(): string{
    switch (this.router.url.split('/')[1]){
      case 'otp':
        return localStorage.getItem('token_otp') ?? '';
      default:
        return localStorage.getItem('token') ?? '';
    }
  }

}
