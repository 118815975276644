import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {PaginatedDataResponse} from '../../../../../shared/models/respose/PaginatedDataResponse';
import {Contact} from '../../../../../shared/models/Contact';
import {Order} from '../../../../../shared/models/Order';
import {DirectionType} from '../../../../enum/DirectionType';
import {Pagination} from '../../../../../shared/models/Pagination';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactService} from '../../../../services/contact.service';
import {AuthService} from '../../../../../shared/services/auth.service';
import {Practice} from '../../../../../shared/models/Practice';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.css']
})
export class ContactTableComponent implements OnInit {
  public project = environment.project;
  @Input() paginatedResponse: PaginatedDataResponse<Contact> = new PaginatedDataResponse<Contact>(0, [], 0, 0, 0);
  @Input() order: Order = new Order('name', DirectionType.asc);
  @Input() enableDeleteButton = true;
  @Input() enableEditButton = true;
  @Input() enablePagination = true;
  @Input() disableDetails = false;
  @Input() elements = 10;
  @Output() elementsChange = new EventEmitter<number>();
  @Output() update = new EventEmitter<Pagination>();
  @Output() clickEdit = new EventEmitter<Contact>();

  constructor(
    private modalService: NgbModal,
    private contactService: ContactService,
    public auth: AuthService
  ) {

  }

  ngOnInit(): void {}

  openTrashModal(content: TemplateRef<any>, contact: Contact): void{
    this.modalService.open(content, {ariaLabelledBy: 'appointment-detail-modal-basic-title'}).result.then((result) => {
      this.contactService.deleteItem(contact.id ?? 0).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void{
    this.elements = pagination.elements;
    this.elementsChange.emit(pagination.elements);
    this.update.emit(pagination);
  }

  edit(practice: Practice): void{
    this.clickEdit.emit(practice);
  }

  onElementsChange(elements: number): void{
    this.elements = elements;
  }

}
