import {Component, OnInit, TemplateRef} from '@angular/core';
import {CrudComponent} from '../../../../shared/components/crud/crud.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {BaseResponse} from '../../../../shared/models/respose/BaseResponse';
import {PaginatedDataResponse} from '../../../../shared/models/respose/PaginatedDataResponse';
import {Contact} from '../../../../shared/models/Contact';
import {ContactService} from '../../../services/contact.service';
import {FileConstants} from '../../../../shared/constants/FileConstants';
import {Source} from '../../../../shared/models/Source';
import {Position} from '../../../../shared/models/Position';
import {PositionService} from "../../../services/position.service";
import {Router} from '@angular/router';
import {Province} from '../../../../shared/models/Province';
import {City} from '../../../../shared/models/City';
import {ProvinceService} from '../../../services/province.service';
import {CityService} from '../../../services/city.service';
import {contactFormAttributes} from '../../../../shared/vars/contactFormAttributes';
import {Order} from "../../../../shared/models/Order";
import {DirectionType} from "../../../enum/DirectionType";
import {User} from "../../../../shared/models/User";
import {UserService} from "../../../services/user.service";
import {ComponentsConstants} from '../../../../shared/constants/ComponentsConstants';
import {Pagination} from '../../../../shared/models/Pagination';
import {environment} from '../../../../../environments/environment';
import {ContactRequest} from '../../../models/request/ContactRequest';
import {Observable} from 'rxjs';
import {AuthService} from '../../../../shared/services/auth.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent extends CrudComponent implements OnInit{
  protected _hasModal = true;
  public paginatedResponse: PaginatedDataResponse<Contact> = new PaginatedDataResponse<Contact>(0, [], 0, 0, 0);
  public imageFileTypes = FileConstants.imageExtensions;
  public selectedContact?: Contact;

  public components = ComponentsConstants;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Inserisci il testo...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'heading',
        'fontName',
        'backgroundColor',
        'link',
        'unlink',
        'insertVideo',
        'insertHorizontalRule',
      ]
    ],
  };

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public contactService: ContactService,
    private positionService: PositionService,
    public router: Router,
    private provinceService: ProvinceService,
    private cityService: CityService,
    private userService: UserService,
    public authService: AuthService
  ) {
    super(modalService, fb);
  }

  public sources: Source[] = [];
  public positions: Position[] = [];
  public project = environment.project;
  public route = '';

  public provinces: Province[] = [];
  public cities: City[] = [];
  public contactUsers: User[] = [];

  public canChangeUser = false;

  ngOnInit(): void {
    this.order = new Order('name' , DirectionType.asc);

    this.form = this.fb.group(contactFormAttributes);

    this.formSearch = this.fb.group({
      name: [''],
      email: [''],
      position_id: [''],
      is_company: [false],
      source_id: [''],
      contact_type_id: [''],
      gender_id: [''],
      birth_date_from: [''],
      birth_date_to: [''],
      assigned_at_from: [''],
      assigned_at_to: [''],
      user_id: [''],
      income_type_id: ['']
    });

    if (this.fromModal){
      this.new();
    }
  }

  onSubmit({ value, valid }: { value: Contact, valid: boolean }): void{
    if (valid){
      if (value.id){
        this.callUpdate(value);
      }
      else{
        this.callRegister(value);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  onFind({ value, valid }: { value: any, valid: boolean }): void{
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void{
    const contact: ContactRequest = this.formSearch.value;
    this.contactService.getPaginatedList(pagination.elements, pagination.page, contact, this.order).subscribe(
      (response: BaseResponse<PaginatedDataResponse<Contact>>) => {
        this.paginatedResponse = response.data;
      }
    );
  }

  openTrashModal(content: TemplateRef<any>, contact: Contact): void{
    this.modalService.open(content, {ariaLabelledBy: 'appointment-detail-modal-basic-title'}).result.then((result) => {
      this.contactService.deleteItem(contact?.id ?? 0).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  edit(contact?: Contact): void{
    this.canChangeUser = false;
    this.updateProvinces();
    this.updateCities(contact?.city?.province.id || 0);

    this.form.patchValue({
      ...contact,
      source_id: contact?.source?.id || '',
      position_id: contact?.position?.id || '',
      contact_type_id: contact?.contact_type?.id || '',
      user_id : contact?.user?.id || '',
      income_type_id: contact?.income_type?.id || '',
    });
    this.checkUserChange(contact);
    this.contactUsers = contact?.user ? [contact.user] : [];
    this.showHideForm();
    this.showHideTable();
  }

  new(): void{
    super.new({is_company: false, user_id: this.authService.getUser().id});
    this.canChangeUser = false;
    this.checkUserChange();
  }

  clearFilters(): void{
    this.formSearch.reset({is_company: false});
  }

  callRegister(contact: Contact): void{
    this.contactService.register(contact).subscribe(response => {
      if (this.fromModal){
        this.modalSaved = true;
      } else {
        this.updateList({page: 1, elements: this.elements});
        this.showHideForm();
        this.showHideTable();
      }
    });
  }

  callUpdate(contact: Contact): void{
    this.contactService.updateItem(contact, contact.id ?? 0).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
      this.showHideTable();
    });
  }

  detail(contact: Contact): void{
    this.selectedContact = contact;
    this.showHideDetail();
    this.showHideTable();
  }

  updatePositions(): void{
    if (this.positions.length === 0){
      this.positionService.getAll().subscribe( result => {this.positions = result.data; });
    }
  }

  updateProvinces(): void {
    if (this.provinces.length === 0) {
      this.provinceService.getAll().subscribe(result => {
        this.provinces = result.data;
      });
    }
  }

  updateCities(province_id: number): void {
    this.cityService.getAll(province_id).subscribe(result => {
      this.cities = result.data;
    });
  }

  getUserService(search: any, id?: number): Observable<any>{
    return this.userService.getPaginatedList(1000, 1,
      {id: this.authService.canManageProspects() ? id : this.authService.getUser().id, name: search},
      new Order('name', DirectionType.asc));
  }

  maxBirthDate(): string {
    const today = new Date();
    const minBirthDate = new Date(today);
    minBirthDate.setFullYear(minBirthDate.getFullYear() - 200);

    const maxBirthDateString = today.toISOString().split('.')[0].replace('Z', '').split('T')[0];
    const minBirthDateString = minBirthDate.toISOString().split('.')[0].replace('Z', '').split('T')[0];

    return maxBirthDateString;
  }

  checkUserChange(contact?: Contact): void{
    if (!this.authService.canManageProspects() && !!this.form.get('id')?.value){ this.canChangeUser = false; }
    else if (contact && contact?.practices && contact?.practices?.length > 0){ this.canChangeUser = false; }
    else { this.canChangeUser = true; }
  }

}
