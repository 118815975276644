<div class="modal-content">
  <div *ngIf="!link">
    <form (ngSubmit)="onSubmit(form)" novalidate [formGroup]="form">
      <app-input [type]="'hidden'" [controlName]="'document_id'" [form]="form"></app-input>
      <div class="modal-header">
        <h4 class="modal-title">Firma Documento id: {{document?.id}}</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <app-input [label]="'Nome'" [controlName]="'first_name'" [form]="form"></app-input>
          </div>
          <div class="col">
            <app-input [label]="'Cognome'" [controlName]="'last_name'" [form]="form"></app-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <app-input [type]="'email'" [label]="'Email'" [controlName]="'email'" [form]="form"></app-input>
          </div>
          <div class="col">
            <app-input [label]="'Numero di telefono (senza prefisso)'" [controlName]="'phone'" [form]="form"></app-input>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Invia</button>
      </div>
    </form>
  </div>
  <div *ngIf="link">
    <div class="modal-header"><p>Effettuare la firma del documento al seguente link:</p></div>
    <div class="modal-body">
      <a [href]="link" target="_blank">{{link}}</a>
    </div>
  </div>
</div>
