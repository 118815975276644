import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {IncomeType} from '../../shared/models/IncomeType';

@Injectable({
  providedIn: 'root'
})
export class IncomeTypeService extends DictionaryService<IncomeType> {

  path = 'income_types';


}

