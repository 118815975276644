import {NgModule} from '@angular/core';
import {MenuComponent} from './components/menu/menu.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {InputErrorsComponent} from './components/input-errors/input-errors.component';
import {ToastComponent} from './components/toast/toast.component';
import {InputFileComponent} from './components/input-file/input-file.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {ProfilePicturePipe} from './pipes/profile-picture.pipe';
import {FullCalendarModule} from '@fullcalendar/angular';
import {TagChipComponent} from './components/tag-chip/tag-chip.component';
import {MatIconModule} from '@angular/material/icon';
import {SelectDictionaryComponent} from './components/select-dictionary/select-dictionary.component';
import {SelectDynamicComponent} from './components/select-dynamic/select-dynamic.component';
import {ContactNamePipe} from './pipes/contact-name.pipe';
import {InputComponent} from './components/input/input.component';
import {TextareaComponent} from './components/textarea/textarea.component';
import {DocumentsComponent} from '../admin/components/utility/documents/documents.component';
import {PaginationComponent} from '../admin/components/utility/pagination/pagination.component';
import {NumericDirective} from './directives/numeric.directive';
import {ColorSketchModule} from 'ngx-color/sketch';
import {ColorGithubModule} from 'ngx-color/github';
import {ColorCircleModule} from 'ngx-color/circle';
import {DocSignModalComponent} from '../admin/components/utility/documents/doc-sign-modal/doc-sign-modal.component';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {ServerSideSelectComponent} from './components/server-side-select/server-side-select.component';
import {InputFileMultipleComponent} from './components/input-file-multiple/input-file-multiple.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DocumentsMultipleComponent} from '../admin/components/utility/documents-multiple/documents-multiple.component';
import {MatListItem} from '@angular/material/list';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    MenuComponent,
    InputErrorsComponent,
    InputFileComponent,
    ToastComponent,
    ProfilePicturePipe,
    ContactNamePipe,
    TagChipComponent,
    SelectDictionaryComponent,
    SelectDynamicComponent,
    ServerSideSelectComponent,
    InputComponent,
    TextareaComponent,
    DocumentsComponent,
    DocSignModalComponent,
    PaginationComponent,
    NumericDirective,
    BackButtonComponent,
    InputFileMultipleComponent,
    DocumentsMultipleComponent,

  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    PdfViewerModule,
    AngularEditorModule,
    MatChipsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    ColorSketchModule,
    ColorGithubModule,
    ColorCircleModule,
    MatListItem,
    FaIconComponent
  ],
  providers: [],
  exports: [
    MenuComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InputErrorsComponent,
    InputFileComponent,
    NgbModule,
    ToastComponent,
    NgSelectModule,
    PdfViewerModule,
    AngularEditorModule,
    ProfilePicturePipe,
    FullCalendarModule,
    TagChipComponent,
    MatChipsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    SelectDictionaryComponent,
    SelectDynamicComponent,
    ServerSideSelectComponent,
    ContactNamePipe,
    InputComponent,
    TextareaComponent,
    DocumentsComponent,
    DocSignModalComponent,
    PaginationComponent,
    NumericDirective,
    ColorSketchModule,
    ColorGithubModule,
    ColorCircleModule,
    BackButtonComponent,
    InputFileMultipleComponent,
    DocumentsMultipleComponent
  ]
})
export class SharedModule {
}
