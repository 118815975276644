import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Document} from '../../../../../shared/models/Document';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DocumentService} from '../../../../services/document.service';
import {SendMailService} from "../../../../services/send-mail.service";

@Component({
  selector: 'app-doc-sign-modal',
  templateUrl: './doc-sign-modal.component.html',
  styleUrls: ['./doc-sign-modal.component.css']
})
export class DocSignModalComponent implements OnInit{
  @Input() document?: Document;

  public link?: string;
  form!: UntypedFormGroup;

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private sendMailService: SendMailService,
    private documentService: DocumentService
  ) {

  }
  ngOnInit(): void {
    this.form = this.fb.group({
      document_id: [this.document?.id, Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      subject: ['Nuovo documento da firmare', Validators.required],
      body: [' '],
      recipient: [' ']
    });

  }
  public open(modal: any): void {
    this.modalService.open(modal);
  }
  public onSubmit(form: UntypedFormGroup): void{
    if (form.valid){
      this.documentService.sign(form.value).subscribe(
        result => {
          this.link = result.data;
          this.form.patchValue({
            recipient: this.form.value.email,
            body: "<p>È presente un nuovo documento da firmare, per completare la procedura cliccare sul link seguente: </p>" +
              "<p>" + this.link + "</p>"
          });
          this.sendMailService.send(form.value).subscribe(
            resultEmail => {
            }
          );
        }
      );

    }
    else {
      form.markAllAsTouched();
    }
  }

}
