import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {Job} from '../../shared/models/Job';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobService extends HttpService {



  getAll(): Observable<BaseResponse<Job[]>>{
    return this.get('jobs');
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: Job,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<Job>>>{
    const request = new PaginatedDataRequest<Job>(entries, page, data, order);
    return this.post('jobs', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Job): Observable<BaseResponse<Job>>{
    return this.post('jobs/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Job>>{
    return this.delete('jobs', id);
  }

  updateItem(request: Job, id: number): Observable<BaseResponse<Job>>{
    return this.put('jobs', request, id);
  }
}
