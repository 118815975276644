<form (ngSubmit)="onSearch(formSearch)" [formGroup]="formSearch" novalidate>
  <div class="card-body">
    <div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Nome azienda</label>
            <input class="form-control" formControlName="company_name" placeholder="Nome azienda" type="text">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Ragione sociale</label>
            <input class="form-control" formControlName="company_type" placeholder="Ragione sociale" type="text">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Iva</label>
            <input class="form-control" formControlName="iva" placeholder="Iva" type="text">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Codice fiscale</label>
            <input class="form-control" formControlName="fiscal_code" placeholder="Codice fiscale" type="text">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Telefono Referente</label>
            <input class="form-control" formControlName="work_number" placeholder="Telefono" type="text">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Email principale</label>
            <input class="form-control" formControlName="email" placeholder="eMail" type="text">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Cap</label>
            <input class="form-control" formControlName="cap" placeholder="Cap" type="text">
          </div>
        </div>
        <div class="col-md-6">
          <app-select-dictionary [dictionary]="'company_activity'" [enableEdit]="false" [form]="formSearch"
                                 [label]="'Attività prevalente'"></app-select-dictionary>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Fatturato di esercizio (da)</label>
          <input class="form-control" formControlName="fatturato_desercizio_from" placeholder="Fatturato di esercizio da..."
                 type="number">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Fatturato di esercizio (a)</label>
          <input class="form-control" formControlName="fatturato_desercizio_to" placeholder="Fatturato di esercizio fino a..."
                 type="number">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Startup innovativa</label>
          <select class="form-control" formControlName="startup_innovativa">
            <option value="0">No</option>
            <option value="1">Si</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Pmi innovativa</label>
          <select class="form-control" formControlName="pmi_innovative">
            <option value="0">No</option>
            <option value="1">Si</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Azienda della pubblica amministrazione</label>
          <select class="form-control" formControlName="azienda_della_pubblica_amministrazione">
            <option value="0">No</option>
            <option value="1">Si</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Data minima inizio Attività</label>
          <input class="form-control" formControlName="min_activity" type="date">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <app-select-dictionary [dictionary]="'personnel_number'" [enableEdit]="false"
                               [form]="formSearch" [label]="'Classe numero dipendenti'"></app-select-dictionary>
      </div>
      <div class="col-md-6">
        <app-select-dictionary [dictionary]="'company_dimension'" [enableEdit]="false" [form]="formSearch"
                               [label]="'Dimensione azienda'"></app-select-dictionary>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Città</label>
          <input class="form-control" formControlName="city_name" type="text">
        </div>
      </div>
      <div class="col-md-6">
        <app-input [controlName]="'referente'" [form]="formSearch" [label]="'Referente'"></app-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Data di assegnazione (intervallo iniziale)</label>
          <input class="form-control" formControlName="assigned_at_from" type="date">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Data di assegnazione (intervallo finale)</label>
          <input class="form-control" formControlName="assigned_at_to" type="date">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-select-dynamic
          [control]="'user_id'"
          [form]="formSearch"
          [label]="'Operatore'"
          [model]="'user'"
          [service]="getUserService.bind(this)"
        >
          <ng-template #optionTemplate let-element>
            {{ element.first_name }} {{ element.last_name }}
          </ng-template>
        </app-select-dynamic>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button (click)="onClearFilters()" class="btn btn-primary" type="button">Pulisci filtri</button>
        <button class="btn btn-primary ms-2" type="submit">Cerca</button>
        <button (click)="onExport()" class="btn btn-primary ms-2" type="button"><i class="fas fa-download"></i></button>
      </div>
    </div>
  </div>
</form>
