import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, forwardRef, NgModule } from '@angular/core';
import * as i1 from 'ngx-color';
import { isValidHex, ColorWrap, AlphaModule, CheckboardModule, EditableInputModule, HueModule, SaturationModule, SwatchModule } from 'ngx-color';
import { TinyColor } from '@ctrl/tinycolor';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const _c0 = (a0, a1) => ({
  input: a0,
  label: a1
});
function SketchFieldsComponent_div_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8)(1, "color-editable-input", 9);
    i0.ɵɵlistener("onChange", function SketchFieldsComponent_div_9_Template_color_editable_input_onChange_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleChange($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(5, _c0, ctx_r1.input, ctx_r1.label));
    i0.ɵɵproperty("value", ctx_r1.round(ctx_r1.rgb.a * 100))("dragLabel", true)("dragMax", 100);
  }
}
function SketchPresetColorsComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "color-swatch", 3);
    i0.ɵɵlistener("onClick", function SketchPresetColorsComponent_div_1_Template_color_swatch_onClick_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleClick($event));
    })("onHover", function SketchPresetColorsComponent_div_1_Template_color_swatch_onHover_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSwatchHover.emit($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const c_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleMap(ctx_r1.swatchStyle);
    i0.ɵɵproperty("color", ctx_r1.normalizeValue(c_r3).color)("focusStyle", ctx_r1.focusStyle(c_r3));
  }
}
function SketchComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12)(1, "color-alpha", 13);
    i0.ɵɵlistener("onChange", function SketchComponent_div_7_Template_color_alpha_onChange_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleValueChange($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("radius", 2)("rgb", ctx_r1.rgb)("hsl", ctx_r1.hsl);
  }
}
function SketchComponent_div_13_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 14)(1, "color-sketch-preset-colors", 15);
    i0.ɵɵlistener("onClick", function SketchComponent_div_13_Template_color_sketch_preset_colors_onClick_1_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleBlockChange($event));
    })("onSwatchHover", function SketchComponent_div_13_Template_color_sketch_preset_colors_onSwatchHover_1_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSwatchHover.emit($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("colors", ctx_r1.presetColors);
  }
}
class SketchFieldsComponent {
  constructor() {
    this.disableAlpha = false;
    this.onChange = new EventEmitter();
    this.input = {
      width: '100%',
      padding: '4px 10% 3px',
      border: 'none',
      boxSizing: 'border-box',
      boxShadow: 'inset 0 0 0 1px #ccc',
      fontSize: '11px'
    };
    this.label = {
      display: 'block',
      textAlign: 'center',
      fontSize: '11px',
      color: '#222',
      paddingTop: '3px',
      paddingBottom: '4px',
      textTransform: 'capitalize'
    };
  }
  round(value) {
    return Math.round(value);
  }
  handleChange({
    data,
    $event
  }) {
    if (data.hex) {
      if (isValidHex(data.hex)) {
        const color = new TinyColor(data.hex);
        this.onChange.emit({
          data: {
            hex: this.disableAlpha || data.hex.length <= 6 ? color.toHex() : color.toHex8(),
            source: 'hex'
          },
          $event
        });
      }
    } else if (data.r || data.g || data.b) {
      this.onChange.emit({
        data: {
          r: data.r || this.rgb.r,
          g: data.g || this.rgb.g,
          b: data.b || this.rgb.b,
          source: 'rgb'
        },
        $event
      });
    } else if (data.a) {
      if (data.a < 0) {
        data.a = 0;
      } else if (data.a > 100) {
        data.a = 100;
      }
      data.a /= 100;
      if (this.disableAlpha) {
        data.a = 1;
      }
      this.onChange.emit({
        data: {
          h: this.hsl.h,
          s: this.hsl.s,
          l: this.hsl.l,
          a: Math.round(data.a * 100) / 100,
          source: 'rgb'
        },
        $event
      });
    } else if (data.h || data.s || data.l) {
      this.onChange.emit({
        data: {
          h: data.h || this.hsl.h,
          s: Number(data.s && data.s || this.hsl.s),
          l: Number(data.l && data.l || this.hsl.l),
          source: 'hsl'
        },
        $event
      });
    }
  }
}
SketchFieldsComponent.ɵfac = function SketchFieldsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SketchFieldsComponent)();
};
SketchFieldsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SketchFieldsComponent,
  selectors: [["color-sketch-fields"]],
  inputs: {
    hsl: "hsl",
    rgb: "rgb",
    hex: "hex",
    disableAlpha: "disableAlpha"
  },
  outputs: {
    onChange: "onChange"
  },
  decls: 10,
  vars: 31,
  consts: [[1, "sketch-fields"], [1, "sketch-double"], ["label", "hex", 3, "onChange", "value"], [1, "sketch-single"], ["label", "r", 3, "onChange", "value", "dragLabel", "dragMax"], ["label", "g", 3, "onChange", "value", "dragLabel", "dragMax"], ["label", "b", 3, "onChange", "value", "dragLabel", "dragMax"], ["class", "sketch-alpha", 4, "ngIf"], [1, "sketch-alpha"], ["label", "a", 3, "onChange", "value", "dragLabel", "dragMax"]],
  template: function SketchFieldsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "color-editable-input", 2);
      i0.ɵɵlistener("onChange", function SketchFieldsComponent_Template_color_editable_input_onChange_2_listener($event) {
        return ctx.handleChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(3, "div", 3)(4, "color-editable-input", 4);
      i0.ɵɵlistener("onChange", function SketchFieldsComponent_Template_color_editable_input_onChange_4_listener($event) {
        return ctx.handleChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(5, "div", 3)(6, "color-editable-input", 5);
      i0.ɵɵlistener("onChange", function SketchFieldsComponent_Template_color_editable_input_onChange_6_listener($event) {
        return ctx.handleChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(7, "div", 3)(8, "color-editable-input", 6);
      i0.ɵɵlistener("onChange", function SketchFieldsComponent_Template_color_editable_input_onChange_8_listener($event) {
        return ctx.handleChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(9, SketchFieldsComponent_div_9_Template, 2, 8, "div", 7);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵstyleMap(i0.ɵɵpureFunction2(19, _c0, ctx.input, ctx.label));
      i0.ɵɵproperty("value", ctx.hex.replace("#", ""));
      i0.ɵɵadvance(2);
      i0.ɵɵstyleMap(i0.ɵɵpureFunction2(22, _c0, ctx.input, ctx.label));
      i0.ɵɵproperty("value", ctx.rgb.r)("dragLabel", true)("dragMax", 255);
      i0.ɵɵadvance(2);
      i0.ɵɵstyleMap(i0.ɵɵpureFunction2(25, _c0, ctx.input, ctx.label));
      i0.ɵɵproperty("value", ctx.rgb.g)("dragLabel", true)("dragMax", 255);
      i0.ɵɵadvance(2);
      i0.ɵɵstyleMap(i0.ɵɵpureFunction2(28, _c0, ctx.input, ctx.label));
      i0.ɵɵproperty("value", ctx.rgb.b)("dragLabel", true)("dragMax", 255);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.disableAlpha === false);
    }
  },
  dependencies: [i1.EditableInputComponent, i2.NgIf],
  styles: [".sketch-fields[_ngcontent-%COMP%]{display:flex;padding-top:4px}.sketch-double[_ngcontent-%COMP%]{flex:2 1 0%}.sketch-single[_ngcontent-%COMP%]{flex:1 1 0%;padding-left:6px}.sketch-alpha[_ngcontent-%COMP%]{flex:1 1 0%;padding-left:6px}[dir=rtl][_nghost-%COMP%]   .sketch-single[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .sketch-single[_ngcontent-%COMP%]{padding-right:6px;padding-left:0}[dir=rtl][_nghost-%COMP%]   .sketch-alpha[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .sketch-alpha[_ngcontent-%COMP%]{padding-right:6px;padding-left:0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SketchFieldsComponent, [{
    type: Component,
    args: [{
      selector: 'color-sketch-fields',
      template: `
  <div class="sketch-fields">
    <div class="sketch-double">
      <color-editable-input
        [style]="{ input: input, label: label }"
        label="hex"
        [value]="hex.replace('#', '')"
        (onChange)="handleChange($event)"
      ></color-editable-input>
    </div>
    <div class="sketch-single">
      <color-editable-input
        [style]="{ input: input, label: label }"
        label="r"
        [value]="rgb.r"
        (onChange)="handleChange($event)"
        [dragLabel]="true"
        [dragMax]="255"
      ></color-editable-input>
    </div>
    <div class="sketch-single">
      <color-editable-input
        [style]="{ input: input, label: label }"
        label="g"
        [value]="rgb.g"
        (onChange)="handleChange($event)"
        [dragLabel]="true"
        [dragMax]="255"
      ></color-editable-input>
    </div>
    <div class="sketch-single">
      <color-editable-input
        [style]="{ input: input, label: label }"
        label="b"
        [value]="rgb.b"
        (onChange)="handleChange($event)"
        [dragLabel]="true"
        [dragMax]="255"
      ></color-editable-input>
    </div>
    <div class="sketch-alpha" *ngIf="disableAlpha === false">
      <color-editable-input
        [style]="{ input: input, label: label }"
        label="a"
        [value]="round(rgb.a * 100)"
        (onChange)="handleChange($event)"
        [dragLabel]="true"
        [dragMax]="100"
      ></color-editable-input>
    </div>
  </div>
  `,
      styles: [`
    .sketch-fields {
      display: flex;
      padding-top: 4px;
    }
    .sketch-double {
      -webkit-box-flex: 2;
      flex: 2 1 0%;
    }
    .sketch-single {
      flex: 1 1 0%;
      padding-left: 6px;
    }
    .sketch-alpha {
      -webkit-box-flex: 1;
      flex: 1 1 0%;
      padding-left: 6px;
    }
    :host-context([dir=rtl]) .sketch-single {
      padding-right: 6px;
      padding-left: 0;
    }
    :host-context([dir=rtl]) .sketch-alpha {
      padding-right: 6px;
      padding-left: 0;
    }
  `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false
    }]
  }], null, {
    hsl: [{
      type: Input
    }],
    rgb: [{
      type: Input
    }],
    hex: [{
      type: Input
    }],
    disableAlpha: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class SketchPresetColorsComponent {
  constructor() {
    this.onClick = new EventEmitter();
    this.onSwatchHover = new EventEmitter();
    this.swatchStyle = {
      borderRadius: '3px',
      boxShadow: 'inset 0 0 0 1px rgba(0,0,0,.15)'
    };
  }
  handleClick({
    hex,
    $event
  }) {
    this.onClick.emit({
      hex,
      $event
    });
  }
  normalizeValue(val) {
    if (typeof val === 'string') {
      return {
        color: val
      };
    }
    return val;
  }
  focusStyle(val) {
    const c = this.normalizeValue(val);
    return {
      boxShadow: `inset 0 0 0 1px rgba(0,0,0,.15), 0 0 4px ${c.color}`
    };
  }
}
SketchPresetColorsComponent.ɵfac = function SketchPresetColorsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SketchPresetColorsComponent)();
};
SketchPresetColorsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SketchPresetColorsComponent,
  selectors: [["color-sketch-preset-colors"]],
  inputs: {
    colors: "colors"
  },
  outputs: {
    onClick: "onClick",
    onSwatchHover: "onSwatchHover"
  },
  decls: 2,
  vars: 1,
  consts: [[1, "sketch-swatches"], ["class", "sketch-wrap", 4, "ngFor", "ngForOf"], [1, "sketch-wrap"], [1, "swatch", 3, "onClick", "onHover", "color", "focusStyle"]],
  template: function SketchPresetColorsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, SketchPresetColorsComponent_div_1_Template, 2, 4, "div", 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.colors);
    }
  },
  dependencies: [i1.SwatchComponent, i2.NgForOf],
  styles: [".sketch-swatches[_ngcontent-%COMP%]{position:relative;display:flex;flex-wrap:wrap;margin:0 -10px;padding:10px 0 0 10px;border-top:1px solid rgb(238,238,238)}.sketch-wrap[_ngcontent-%COMP%]{width:16px;height:16px;margin:0 10px 10px 0}[dir=rtl][_nghost-%COMP%]   .sketch-swatches[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .sketch-swatches[_ngcontent-%COMP%]{padding-right:10px;padding-left:0}[dir=rtl][_nghost-%COMP%]   .sketch-wrap[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .sketch-wrap[_ngcontent-%COMP%]{margin-left:10px;margin-right:0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SketchPresetColorsComponent, [{
    type: Component,
    args: [{
      selector: 'color-sketch-preset-colors',
      template: `
  <div class="sketch-swatches">
    <div class="sketch-wrap" *ngFor="let c of colors">
      <color-swatch
        [color]="normalizeValue(c).color"
        [style]="swatchStyle"
        [focusStyle]="focusStyle(c)"
        (onClick)="handleClick($event)"
        (onHover)="onSwatchHover.emit($event)"
        class="swatch"
      ></color-swatch>
    </div>
  </div>
  `,
      styles: [`
    .sketch-swatches {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 0px -10px;
      padding: 10px 0px 0px 10px;
      border-top: 1px solid rgb(238, 238, 238);
    }
    .sketch-wrap {
      width: 16px;
      height: 16px;
      margin: 0px 10px 10px 0px;
    }
    :host-context([dir=rtl]) .sketch-swatches {
      padding-right: 10px;
      padding-left: 0;
    }
    :host-context([dir=rtl]) .sketch-wrap {
      margin-left: 10px;
      margin-right: 0;
    }
  `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false
    }]
  }], null, {
    colors: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    onSwatchHover: [{
      type: Output
    }]
  });
})();
class SketchComponent extends ColorWrap {
  constructor() {
    super();
    /** Remove alpha slider and options from picker */
    this.disableAlpha = false;
    /** Hex strings for default colors at bottom of picker */
    this.presetColors = ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'];
    /** Width of picker */
    this.width = 200;
  }
  afterValidChange() {
    const alpha = this.disableAlpha ? 1 : this.rgb.a;
    this.activeBackground = `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, ${alpha})`;
  }
  handleValueChange({
    data,
    $event
  }) {
    this.handleChange(data, $event);
  }
  handleBlockChange({
    hex,
    $event
  }) {
    if (isValidHex(hex)) {
      // this.hex = hex;
      this.handleChange({
        hex,
        source: 'hex'
      }, $event);
    }
  }
}
SketchComponent.ɵfac = function SketchComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SketchComponent)();
};
SketchComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SketchComponent,
  selectors: [["color-sketch"]],
  inputs: {
    disableAlpha: "disableAlpha",
    presetColors: "presetColors",
    width: "width"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SketchComponent),
    multi: true
  }, {
    provide: ColorWrap,
    useExisting: forwardRef(() => SketchComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 14,
  vars: 16,
  consts: [[1, "sketch-saturation"], [3, "onChange", "hsl", "hsv"], [1, "sketch-controls"], [1, "sketch-sliders"], [1, "sketch-hue"], [3, "onChange", "hsl"], ["class", "sketch-alpha", 4, "ngIf"], [1, "sketch-color"], [1, "sketch-active"], [1, "sketch-fields-container"], [3, "onChange", "rgb", "hsl", "hex", "disableAlpha"], ["class", "sketch-swatches-container", 4, "ngIf"], [1, "sketch-alpha"], [3, "onChange", "radius", "rgb", "hsl"], [1, "sketch-swatches-container"], [3, "onClick", "onSwatchHover", "colors"]],
  template: function SketchComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div")(1, "div", 0)(2, "color-saturation", 1);
      i0.ɵɵlistener("onChange", function SketchComponent_Template_color_saturation_onChange_2_listener($event) {
        return ctx.handleValueChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(3, "div", 2)(4, "div", 3)(5, "div", 4)(6, "color-hue", 5);
      i0.ɵɵlistener("onChange", function SketchComponent_Template_color_hue_onChange_6_listener($event) {
        return ctx.handleValueChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(7, SketchComponent_div_7_Template, 2, 3, "div", 6);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(8, "div", 7);
      i0.ɵɵelement(9, "color-checkboard")(10, "div", 8);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(11, "div", 9)(12, "color-sketch-fields", 10);
      i0.ɵɵlistener("onChange", function SketchComponent_Template_color_sketch_fields_onChange_12_listener($event) {
        return ctx.handleValueChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(13, SketchComponent_div_13_Template, 2, 1, "div", 11);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate1("sketch-picker ", ctx.className, "");
      i0.ɵɵstyleProp("width", ctx.width);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("hsl", ctx.hsl)("hsv", ctx.hsv);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("hsl", ctx.hsl);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.disableAlpha === false);
      i0.ɵɵadvance(3);
      i0.ɵɵstyleProp("background", ctx.activeBackground);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("rgb", ctx.rgb)("hsl", ctx.hsl)("hex", ctx.hex)("disableAlpha", ctx.disableAlpha);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.presetColors && ctx.presetColors.length);
    }
  },
  dependencies: () => [i1.SaturationComponent, i1.HueComponent, i1.AlphaComponent, i1.CheckboardComponent, SketchFieldsComponent, SketchPresetColorsComponent, i2.NgIf],
  styles: [".sketch-picker[_ngcontent-%COMP%]{padding:10px 10px 3px;box-sizing:initial;background:#fff;border-radius:4px;box-shadow:0 0 0 1px #00000026,0 8px 16px #00000026}.sketch-saturation[_ngcontent-%COMP%]{width:100%;padding-bottom:75%;position:relative;overflow:hidden}.sketch-fields-container[_ngcontent-%COMP%]{display:block}.sketch-swatches-container[_ngcontent-%COMP%]{display:block}.sketch-controls[_ngcontent-%COMP%]{display:flex}.sketch-sliders[_ngcontent-%COMP%]{padding:4px 0;flex:1 1 0%}.sketch-hue[_ngcontent-%COMP%]{position:relative;height:10px;overflow:hidden}.sketch-alpha[_ngcontent-%COMP%]{position:relative;height:10px;margin-top:4px;overflow:hidden}.sketch-color[_ngcontent-%COMP%]{width:24px;height:24px;position:relative;margin-top:4px;margin-left:4px;border-radius:3px}.sketch-active[_ngcontent-%COMP%]{position:absolute;top:0px;right:0px;bottom:0px;left:0px;border-radius:2px;box-shadow:#00000026 0 0 0 1px inset,#00000040 0 0 4px inset}[dir=rtl][_nghost-%COMP%]   .sketch-color[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .sketch-color[_ngcontent-%COMP%]{margin-right:4px;margin-left:0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SketchComponent, [{
    type: Component,
    args: [{
      selector: 'color-sketch',
      template: `
  <div class="sketch-picker {{ className }}" [style.width]="width">
    <div class="sketch-saturation">
      <color-saturation [hsl]="hsl" [hsv]="hsv"
        (onChange)="handleValueChange($event)"
      >
      </color-saturation>
    </div>
    <div class="sketch-controls">
      <div class="sketch-sliders">
        <div class="sketch-hue">
          <color-hue [hsl]="hsl"
            (onChange)="handleValueChange($event)"
          ></color-hue>
        </div>
        <div class="sketch-alpha" *ngIf="disableAlpha === false">
          <color-alpha
            [radius]="2" [rgb]="rgb" [hsl]="hsl"
            (onChange)="handleValueChange($event)"
          ></color-alpha>
        </div>
      </div>
      <div class="sketch-color">
        <color-checkboard></color-checkboard>
        <div class="sketch-active" [style.background]="activeBackground"></div>
      </div>
    </div>
    <div class="sketch-fields-container">
      <color-sketch-fields
        [rgb]="rgb" [hsl]="hsl" [hex]="hex"
        [disableAlpha]="disableAlpha"
        (onChange)="handleValueChange($event)"
      ></color-sketch-fields>
    </div>
    <div class="sketch-swatches-container" *ngIf="presetColors && presetColors.length">
      <color-sketch-preset-colors
        [colors]="presetColors"
        (onClick)="handleBlockChange($event)"
        (onSwatchHover)="onSwatchHover.emit($event)"
      ></color-sketch-preset-colors>
    </div>
  </div>
  `,
      styles: [`
    .sketch-picker {
      padding: 10px 10px 3px;
      box-sizing: initial;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 8px 16px rgba(0,0,0,.15);
    }
    .sketch-saturation {
      width: 100%;
      padding-bottom: 75%;
      position: relative;
      overflow: hidden;
    }
    .sketch-fields-container {
      display: block;
    }
    .sketch-swatches-container {
      display: block;
    }
    .sketch-controls {
      display: flex;
    }
    .sketch-sliders {
      padding: 4px 0px;
      -webkit-box-flex: 1;
      flex: 1 1 0%;
    }
    .sketch-hue {
      position: relative;
      height: 10px;
      overflow: hidden;
    }
    .sketch-alpha {
      position: relative;
      height: 10px;
      margin-top: 4px;
      overflow: hidden;
    }
    .sketch-color {
      width: 24px;
      height: 24px;
      position: relative;
      margin-top: 4px;
      margin-left: 4px;
      border-radius: 3px;
    }
    .sketch-active {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      border-radius: 2px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 4px inset;
    }
    :host-context([dir=rtl]) .sketch-color {
      margin-right: 4px;
      margin-left: 0;
    }
  `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SketchComponent),
        multi: true
      }, {
        provide: ColorWrap,
        useExisting: forwardRef(() => SketchComponent)
      }]
    }]
  }], function () {
    return [];
  }, {
    disableAlpha: [{
      type: Input
    }],
    presetColors: [{
      type: Input
    }],
    width: [{
      type: Input
    }]
  });
})();
class ColorSketchModule {}
ColorSketchModule.ɵfac = function ColorSketchModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColorSketchModule)();
};
ColorSketchModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ColorSketchModule
});
ColorSketchModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, AlphaModule, CheckboardModule, EditableInputModule, HueModule, SaturationModule, SwatchModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorSketchModule, [{
    type: NgModule,
    args: [{
      declarations: [SketchComponent, SketchFieldsComponent, SketchPresetColorsComponent],
      exports: [SketchComponent, SketchFieldsComponent, SketchPresetColorsComponent],
      imports: [CommonModule, AlphaModule, CheckboardModule, EditableInputModule, HueModule, SaturationModule, SwatchModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ColorSketchModule, SketchComponent, SketchFieldsComponent, SketchPresetColorsComponent };
