import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapse-button',
  templateUrl: './collapse-button.component.html',
  styleUrls: ['./collapse-button.component.css']
})
export class CollapseButtonComponent implements OnInit {

  @Input() label = '';
  @Input() ref = '';

  @Input() collapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

  getHref(): string{
    return '#' + this.ref;
  }

  getChevronClass(): string{
    return this.collapsed ? 'fa-chevron-down' : 'fa-chevron-up';
  }

}
