import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {DocSignModalComponent} from '../components/utility/documents/doc-sign-modal/doc-sign-modal.component';
import {Document} from '../../shared/models/Document';
import {DocFileSignModalComponent} from '../components/utility/documents/doc-file-sign-modal/doc-file-sign-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DocSignModalService {

  constructor(private modalService: NgbModal) { }

  openModal(document: Document): any{
    const modal = this.modalService.open(DocSignModalComponent, {centered: true, size: 'xl'});
    modal.componentInstance.document = document;
    return modal;
  }

  openSignFileModal(document: Document): any{
    const modal = this.modalService.open(DocFileSignModalComponent, {centered: true, size: 'xl'});
    const component: DocFileSignModalComponent = modal.componentInstance;
    component.document = document;
    return modal;
  }
}
