import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {Department} from '../../shared/models/Department';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends DictionaryService<Department> {

  path = 'departments';

  getDepartmentIdByName(name: string): Observable<number | null> {
    return this.getAll().pipe(
      map(response => {
        const departments: Department[] = response.data;
        const foundDepartment = departments.find(department => department.name === name);
        return foundDepartment ? foundDepartment.id : null;
      })
    );
  }

  getDepartmentNameById(id: number | string): Observable<string | null | undefined> {
    return this.getAll().pipe(
      map(response => {
        if (!response.data || !Array.isArray(response.data)) {
          return null;
        }
        const departments: Department[] = response.data;
        const foundDepartment = departments.find(department => department.id === id);
        return foundDepartment ? foundDepartment.name : null;
      })
    );
  }

}