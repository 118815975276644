import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {Gender} from '../../shared/models/Gender';

@Injectable({
  providedIn: 'root'
})
export class GenderService extends DictionaryService<Gender> {

  path = 'genders';

}
