import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {AssignmentType} from '../../shared/models/AssignmentType';

@Injectable({
  providedIn: 'root'
})
export class AssignmentTypeService extends DictionaryService<AssignmentType> {

  path = 'assignment_types';

}
