import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DirectionType} from 'src/app/admin/enum/DirectionType';
import {Order} from '../../../../shared/models/Order';

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.css']
})
export class SortButtonComponent implements OnInit {

  @Input() label = '';
  @Input() column = '';
  @Input() order: Order = new Order('', DirectionType.asc);
  @Output() orderChange = new EventEmitter<Order>();

  constructor() { }

  ngOnInit(): void {
  }

  setOrder(): void{
    if (this.order.attribute !== this.column){
      this.order.direction = DirectionType.asc;
    }
    else{
      this.order.direction = this.order.direction === DirectionType.asc ? DirectionType.desc : DirectionType.asc;
    }
    this.order.attribute = this.column;
    this.orderChange.emit(this.order);
  }

  getIconClass(): string{
    if (this.order.attribute !== this.column){
      return 'fa-sort';
    }
    else if(this.order.direction === DirectionType.asc){
      return 'fa-sort-down';
    }
    else{
      return 'fa-sort-up';
    }
  }

}
