import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, forwardRef, NgModule } from '@angular/core';
import * as i1 from 'ngx-color';
import { ColorWrap, isValidHex, SwatchModule } from 'ngx-color';
import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
function GithubComponent_color_github_swatch_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "color-github-swatch", 3);
    i0.ɵɵlistener("onClick", function GithubComponent_color_github_swatch_3_Template_color_github_swatch_onClick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleBlockChange($event));
    })("onSwatchHover", function GithubComponent_color_github_swatch_3_Template_color_github_swatch_onSwatchHover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSwatchHover.emit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const color_r3 = ctx.$implicit;
    i0.ɵɵproperty("color", color_r3);
  }
}
class GithubSwatchComponent {
  constructor() {
    this.onClick = new EventEmitter();
    this.onSwatchHover = new EventEmitter();
    this.focusStyle = {
      position: 'relative',
      'z-index': '2',
      outline: '2px solid #fff',
      'box-shadow': '0 0 5px 2px rgba(0,0,0,0.25)'
    };
  }
  handleClick({
    hex,
    $event
  }) {
    this.onClick.emit({
      hex,
      $event
    });
  }
}
GithubSwatchComponent.ɵfac = function GithubSwatchComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GithubSwatchComponent)();
};
GithubSwatchComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: GithubSwatchComponent,
  selectors: [["color-github-swatch"]],
  inputs: {
    color: "color"
  },
  outputs: {
    onClick: "onClick",
    onSwatchHover: "onSwatchHover"
  },
  decls: 3,
  vars: 2,
  consts: [[1, "github-swatch"], [1, "swatch", 3, "onClick", "onHover", "color", "focusStyle"], [1, "clear"]],
  template: function GithubSwatchComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "color-swatch", 1);
      i0.ɵɵlistener("onClick", function GithubSwatchComponent_Template_color_swatch_onClick_1_listener($event) {
        return ctx.handleClick($event);
      })("onHover", function GithubSwatchComponent_Template_color_swatch_onHover_1_listener($event) {
        return ctx.onSwatchHover.emit($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelement(2, "div", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("color", ctx.color)("focusStyle", ctx.focusStyle);
    }
  },
  dependencies: [i1.SwatchComponent],
  styles: [".github-swatch[_ngcontent-%COMP%]{width:25px;height:25px;font-size:0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GithubSwatchComponent, [{
    type: Component,
    args: [{
      selector: 'color-github-swatch',
      template: `
    <div class="github-swatch">
      <color-swatch
        [color]="color"
        [focusStyle]="focusStyle"
        (onClick)="handleClick($event)"
        (onHover)="onSwatchHover.emit($event)"
        class="swatch"
      ></color-swatch>
      <div class="clear"></div>
    </div>
  `,
      styles: [`
      .github-swatch {
        width: 25px;
        height: 25px;
        font-size: 0;
      }
    `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false
    }]
  }], null, {
    color: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    onSwatchHover: [{
      type: Output
    }]
  });
})();
class GithubComponent extends ColorWrap {
  constructor() {
    super();
    /** Pixel value for picker width */
    this.width = 212;
    /** Color squares to display */
    this.colors = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB'];
    this.triangle = 'top-left';
  }
  handleBlockChange({
    hex,
    $event
  }) {
    if (isValidHex(hex)) {
      this.handleChange({
        hex,
        source: 'hex'
      }, $event);
    }
  }
  handleValueChange({
    data,
    $event
  }) {
    this.handleChange(data, $event);
  }
}
GithubComponent.ɵfac = function GithubComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GithubComponent)();
};
GithubComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: GithubComponent,
  selectors: [["color-github"]],
  inputs: {
    width: "width",
    colors: "colors",
    triangle: "triangle"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GithubComponent),
    multi: true
  }, {
    provide: ColorWrap,
    useExisting: forwardRef(() => GithubComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 7,
  consts: [[1, "triangleShadow"], [1, "triangle"], [3, "color", "onClick", "onSwatchHover", 4, "ngFor", "ngForOf"], [3, "onClick", "onSwatchHover", "color"]],
  template: function GithubComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div");
      i0.ɵɵelement(1, "div", 0)(2, "div", 1);
      i0.ɵɵtemplate(3, GithubComponent_color_github_swatch_3_Template, 1, 1, "color-github-swatch", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate2("github-picker ", ctx.triangle, "-triangle ", ctx.className, "");
      i0.ɵɵstyleProp("width", ctx.width, "px");
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngForOf", ctx.colors);
    }
  },
  dependencies: () => [GithubSwatchComponent, i1$1.NgForOf],
  styles: [".github-picker[_ngcontent-%COMP%]{background:rgb(255,255,255);border:1px solid rgba(0,0,0,.2);box-shadow:#00000026 0 3px 12px;border-radius:4px;position:relative;padding:5px;display:flex;flex-wrap:wrap;box-sizing:border-box}.triangleShadow[_ngcontent-%COMP%]{position:absolute;border-width:8px;border-style:solid;border-color:transparent transparent rgba(0,0,0,.15);border-image:initial}.triangle[_ngcontent-%COMP%]{position:absolute;border-width:7px;border-style:solid;border-color:transparent transparent rgb(255,255,255);border-image:initial}.hide-triangle[_ngcontent-%COMP%] > .triangle[_ngcontent-%COMP%]{display:none}.hide-triangle[_ngcontent-%COMP%] > .triangleShadow[_ngcontent-%COMP%]{display:none}.top-left-triangle[_ngcontent-%COMP%] > .triangle[_ngcontent-%COMP%]{top:-14px;left:10px}.top-left-triangle[_ngcontent-%COMP%] > .triangleShadow[_ngcontent-%COMP%]{top:-16px;left:9px}.top-right-triangle[_ngcontent-%COMP%] > .triangle[_ngcontent-%COMP%]{top:-14px;right:10px}.top-right-triangle[_ngcontent-%COMP%] > .triangleShadow[_ngcontent-%COMP%]{top:-16px;right:9px}.bottom-right-triangle[_ngcontent-%COMP%] > .triangle[_ngcontent-%COMP%]{top:35px;right:10px;transform:rotate(180deg)}.bottom-right-triangle[_ngcontent-%COMP%] > .triangleShadow[_ngcontent-%COMP%]{top:37px;right:9px;transform:rotate(180deg)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GithubComponent, [{
    type: Component,
    args: [{
      selector: 'color-github',
      template: `
  <div class="github-picker {{ triangle }}-triangle {{ className }}"
    [style.width.px]="width"
  >
    <div class="triangleShadow"></div>
    <div class="triangle"></div>
    <color-github-swatch *ngFor="let color of colors"
      [color]="color"
      (onClick)="handleBlockChange($event)"
      (onSwatchHover)="onSwatchHover.emit($event)"
    ></color-github-swatch>
  </div>
  `,
      styles: [`
  .github-picker {
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
    border-radius: 4px;
    position: relative;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .triangleShadow {
    position: absolute;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.15);
    border-image: initial;
  }
  .triangle {
    position: absolute;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent rgb(255, 255, 255);
    border-image: initial;
  }
  .hide-triangle > .triangle {
    display: none;
  }
  .hide-triangle > .triangleShadow {
    display: none;
  }
  .top-left-triangle > .triangle {
    top: -14px;
    left: 10px;
  }
  .top-left-triangle > .triangleShadow {
    top: -16px;
    left: 9px;
  }
  .top-right-triangle > .triangle {
    top: -14px;
    right: 10px;
  }
  .top-right-triangle > .triangleShadow {
    top: -16px;
    right: 9px;
  }
  .bottom-right-triangle > .triangle {
    top: 35px;
    right: 10px;
    transform: rotate(180deg);
  }
  .bottom-right-triangle > .triangleShadow {
    top: 37px;
    right: 9px;
    transform: rotate(180deg);
  }
  `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => GithubComponent),
        multi: true
      }, {
        provide: ColorWrap,
        useExisting: forwardRef(() => GithubComponent)
      }]
    }]
  }], function () {
    return [];
  }, {
    width: [{
      type: Input
    }],
    colors: [{
      type: Input
    }],
    triangle: [{
      type: Input
    }]
  });
})();
class ColorGithubModule {}
ColorGithubModule.ɵfac = function ColorGithubModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColorGithubModule)();
};
ColorGithubModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ColorGithubModule
});
ColorGithubModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, SwatchModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorGithubModule, [{
    type: NgModule,
    args: [{
      declarations: [GithubComponent, GithubSwatchComponent],
      exports: [GithubComponent, GithubSwatchComponent],
      imports: [CommonModule, SwatchModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ColorGithubModule, GithubComponent, GithubSwatchComponent };
