import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, forwardRef, NgModule } from '@angular/core';
import * as i1 from 'ngx-color';
import { ColorWrap, isValidHex, SwatchModule } from 'ngx-color';
import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, blueGrey } from 'material-colors';
import { TinyColor } from '@ctrl/tinycolor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
function CircleComponent_color_circle_swatch_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "color-circle-swatch", 1);
    i0.ɵɵlistener("onClick", function CircleComponent_color_circle_swatch_1_Template_color_circle_swatch_onClick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleBlockChange($event));
    })("onSwatchHover", function CircleComponent_color_circle_swatch_1_Template_color_circle_swatch_onSwatchHover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onSwatchHover.emit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const color_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("circleSize", ctx_r1.circleSize)("circleSpacing", ctx_r1.circleSpacing)("color", color_r3)("focus", ctx_r1.isActive(color_r3));
  }
}
class CircleSwatchComponent {
  constructor() {
    this.circleSize = 28;
    this.circleSpacing = 14;
    this.focus = false;
    this.onClick = new EventEmitter();
    this.onSwatchHover = new EventEmitter();
    this.focusStyle = {};
    this.swatchStyle = {
      borderRadius: '50%',
      background: 'transparent',
      transition: '100ms box-shadow ease 0s'
    };
  }
  ngOnChanges() {
    this.swatchStyle.boxShadow = `inset 0 0 0 ${this.circleSize / 2}px ${this.color}`;
    this.focusStyle.boxShadow = `inset 0 0 0 ${this.circleSize / 2}px ${this.color}, 0 0 5px ${this.color}`;
    if (this.focus) {
      this.focusStyle.boxShadow = `inset 0 0 0 3px ${this.color}, 0 0 5px ${this.color}`;
    }
  }
  handleClick({
    hex,
    $event
  }) {
    this.onClick.emit({
      hex,
      $event
    });
  }
}
CircleSwatchComponent.ɵfac = function CircleSwatchComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CircleSwatchComponent)();
};
CircleSwatchComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CircleSwatchComponent,
  selectors: [["color-circle-swatch"]],
  inputs: {
    color: "color",
    circleSize: "circleSize",
    circleSpacing: "circleSpacing",
    focus: "focus"
  },
  outputs: {
    onClick: "onClick",
    onSwatchHover: "onSwatchHover"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 3,
  vars: 13,
  consts: [[1, "circle-swatch"], [3, "onClick", "onHover", "color", "focus", "focusStyle"], [1, "clear"]],
  template: function CircleSwatchComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "color-swatch", 1);
      i0.ɵɵlistener("onClick", function CircleSwatchComponent_Template_color_swatch_onClick_1_listener($event) {
        return ctx.handleClick($event);
      })("onHover", function CircleSwatchComponent_Template_color_swatch_onHover_1_listener($event) {
        return ctx.onSwatchHover.emit($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelement(2, "div", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("width", ctx.circleSize, "px")("height", ctx.circleSize, "px")("margin-right", ctx.circleSpacing, "px")("margin-bottom", ctx.circleSpacing, "px");
      i0.ɵɵadvance();
      i0.ɵɵstyleMap(ctx.swatchStyle);
      i0.ɵɵproperty("color", ctx.color)("focus", ctx.focus)("focusStyle", ctx.focusStyle);
    }
  },
  dependencies: [i1.SwatchComponent],
  styles: [".circle-swatch[_ngcontent-%COMP%]{transform:scale(1);transition:transform .1s ease}.circle-swatch[_ngcontent-%COMP%]:hover{transform:scale(1.2)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircleSwatchComponent, [{
    type: Component,
    args: [{
      selector: 'color-circle-swatch',
      template: `
  <div class="circle-swatch"
    [style.width.px]="circleSize" [style.height.px]="circleSize"
    [style.margin-right.px]="circleSpacing" [style.margin-bottom.px]="circleSpacing"
    >
    <color-swatch
      [color]="color" [style]="swatchStyle" [focus]="focus" [focusStyle]="focusStyle"
      (onClick)="handleClick($event)" (onHover)="onSwatchHover.emit($event)">
    </color-swatch>
    <div class="clear"></div>
  </div>
  `,
      styles: [`
  .circle-swatch {
    transform: scale(1);
    transition: transform 100ms ease;
  }
  .circle-swatch:hover {
    transform: scale(1.2);
  }
  `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false
    }]
  }], null, {
    color: [{
      type: Input
    }],
    circleSize: [{
      type: Input
    }],
    circleSpacing: [{
      type: Input
    }],
    focus: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    onSwatchHover: [{
      type: Output
    }]
  });
})();
class CircleComponent extends ColorWrap {
  constructor() {
    super();
    /** Pixel value for picker width */
    this.width = 252;
    /** Color squares to display */
    this.colors = [red['500'], pink['500'], purple['500'], deepPurple['500'], indigo['500'], blue['500'], lightBlue['500'], cyan['500'], teal['500'], green['500'], lightGreen['500'], lime['500'], yellow['500'], amber['500'], orange['500'], deepOrange['500'], brown['500'], blueGrey['500']];
    /** Value for circle size */
    this.circleSize = 28;
    /** Value for spacing between circles */
    this.circleSpacing = 14;
  }
  isActive(color) {
    return new TinyColor(this.hex).equals(color);
  }
  handleBlockChange({
    hex,
    $event
  }) {
    if (isValidHex(hex)) {
      this.handleChange({
        hex,
        source: 'hex'
      }, $event);
    }
  }
  handleValueChange({
    data,
    $event
  }) {
    this.handleChange(data, $event);
  }
}
CircleComponent.ɵfac = function CircleComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CircleComponent)();
};
CircleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CircleComponent,
  selectors: [["color-circle"]],
  inputs: {
    width: "width",
    colors: "colors",
    circleSize: "circleSize",
    circleSpacing: "circleSpacing"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CircleComponent),
    multi: true
  }, {
    provide: ColorWrap,
    useExisting: forwardRef(() => CircleComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 10,
  consts: [[3, "circleSize", "circleSpacing", "color", "focus", "onClick", "onSwatchHover", 4, "ngFor", "ngForOf"], [3, "onClick", "onSwatchHover", "circleSize", "circleSpacing", "color", "focus"]],
  template: function CircleComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div");
      i0.ɵɵtemplate(1, CircleComponent_color_circle_swatch_1_Template, 1, 4, "color-circle-swatch", 0);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate1("circle-picker ", ctx.className, "");
      i0.ɵɵstyleProp("width", ctx.width, "px")("margin-right", -ctx.circleSpacing, "px")("margin-bottom", -ctx.circleSpacing, "px");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.colors);
    }
  },
  dependencies: () => [CircleSwatchComponent, i1$1.NgForOf],
  styles: [".circle-picker[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircleComponent, [{
    type: Component,
    args: [{
      selector: 'color-circle',
      template: `
    <div
      class="circle-picker {{ className }}"
      [style.width.px]="width"
      [style.margin-right.px]="-circleSpacing"
      [style.margin-bottom.px]="-circleSpacing"
    >
      <color-circle-swatch
        *ngFor="let color of colors"
        [circleSize]="circleSize"
        [circleSpacing]="circleSpacing"
        [color]="color"
        [focus]="isActive(color)"
        (onClick)="handleBlockChange($event)"
        (onSwatchHover)="onSwatchHover.emit($event)"
      ></color-circle-swatch>
    </div>
  `,
      styles: [`
      .circle-picker {
        display: flex;
        flex-wrap: wrap;
      }
    `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CircleComponent),
        multi: true
      }, {
        provide: ColorWrap,
        useExisting: forwardRef(() => CircleComponent)
      }]
    }]
  }], function () {
    return [];
  }, {
    width: [{
      type: Input
    }],
    colors: [{
      type: Input
    }],
    circleSize: [{
      type: Input
    }],
    circleSpacing: [{
      type: Input
    }]
  });
})();
class ColorCircleModule {}
ColorCircleModule.ɵfac = function ColorCircleModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColorCircleModule)();
};
ColorCircleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ColorCircleModule
});
ColorCircleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, SwatchModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorCircleModule, [{
    type: NgModule,
    args: [{
      declarations: [CircleComponent, CircleSwatchComponent],
      exports: [CircleComponent, CircleSwatchComponent],
      imports: [CommonModule, SwatchModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CircleComponent, CircleSwatchComponent, ColorCircleModule };
