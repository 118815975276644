import { Component } from '@angular/core';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {PracticeType} from '../../../../../shared/models/PracticeType';
import {PracticeTypeService} from '../../../../services/practice-type.service';

@Component({
  selector: 'app-practice-types',
  templateUrl: './practice-types.component.html',
  styleUrls: ['./practice-types.component.css']
})
export class PracticeTypesComponent  extends DictionaryComponent<PracticeType> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: PracticeTypeService
  ) {
    super(modalService, fb, service);
  }

}
