import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {JobsComponent} from '../components/routes/jobs/jobs.component';
import {CrudComponent} from '../../shared/components/crud/crud.component';

@Injectable({
  providedIn: 'root'
})
export class CreateModalService {

  constructor(private modalService: NgbModal) { }

  openModal(content: CrudComponent): any{
    const modal = this.modalService.open(content, {centered: true, size: 'xl'});
    const component: CrudComponent = modal.componentInstance;
    component.hideHeader = true;
    component.disableDetails = true;
    if (component.hasModal){
      component.fromModal = true;
    }
    return modal;
  }
}
