import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseResponse} from 'src/app/shared/models/respose/BaseResponse';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {CompanyActivity} from '../../shared/models/CompanyActivity';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyActivityService extends DictionaryService<CompanyActivity> {

  path = 'company_activities';

  getItem(id: number): Observable<BaseResponse<CompanyActivity>> {
    return this.getAll().pipe(
      map(response => {
        const activity = response.data.find(activity => activity.id === id);
        if (activity) {
          return { data: activity } as BaseResponse<CompanyActivity>;
        } else {
          throw new Error('Attività aziendale non trovata');
        }
      })
    );
  }

}
