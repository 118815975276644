import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/shared/models/respose/BaseResponse';
import { HttpService } from 'src/app/shared/services/http.service';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {DocumentRequest} from '../models/request/DocumentRequest';
import {Document} from '../../shared/models/Document';
import {DocumentSignRequest} from '../models/request/DocumentSignRequest';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends HttpService {

  // tslint:disable-next-line:max-line-length
  getPaginatedList(entries: number, page: number, data?: DocumentRequest, order?: Order): Observable<BaseResponse<PaginatedDataResponse<Document>>>{
    const request = new PaginatedDataRequest<DocumentRequest>(entries, page, data, order);
    return this.post('documents', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: DocumentRequest): Observable<BaseResponse<Document>>{
    return this.post('documents/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Document>>{
    return this.delete('documents', id);
  }

  getItem(id: number): Observable<BaseResponse<Document>>{
    return this.get('documents/' + id);
  }

  getSignedItem(id: number): Observable<BaseResponse<string>>{
    return this.get('documents/signed/' + id);
  }

  sign(documentSignRequest: DocumentSignRequest): Observable<BaseResponse<string>>{
    return this.post('documents/sign', documentSignRequest);
  }

  signFile(request: {document: any}, id: number): Observable<BaseResponse<string>>{
    return this.post('documents/sign_file/' + id, request );
  }

  downloadPrivacyPolicy(id: number, related_type: string): Observable<BaseResponse<string>>{
    return this.post('documents/privacy_policy', {id, related_type});
  }
}
