import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {ConfigurationWhatsappTemplate} from '../../shared/models/ConfigurationWhatsappTemplate';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationWhatsappTemplateService extends DictionaryService<ConfigurationWhatsappTemplate> {

  path = 'configuration_whatsapp_templates';

}
