<div [formGroup]="form" class="form-group">
  <label for="{{model}}">{{ label }}</label>
  <div class="d-flex">
    <ng-select
      (change)="changeValue($event)"
      (clear)="clearSelection()"
      (open)="open()"
      [clearable]="clearable"
      [multiple]="multiple"
      [placeholder]="placeholder"
      [readonly]="readonly"
      bindLabel="{{model}}"
      class="select-dynamic"
      formControlName="{{control}}"
      id="{{model}}"
    >
      <ng-option *ngFor="let element of elements; index as i" [value]="element[optionValue]">
        <ng-container
          [ngTemplateOutletContext]="{ $implicit: element, index: i}"
          [ngTemplateOutlet]="optionTemplate || defaultSelected"
        >
        </ng-container>
      </ng-option>
    </ng-select>
    <button (click)="create()" *ngIf="enableEdit && !greenButton" class="btn btn-primary btn-sm ms-2" type="button"><i
      class="fas fa-edit"></i></button>
    <button (click)="create()" *ngIf="enableEdit && greenButton" class="btn btn-success btn-sm ms-2" type="button"><i
      class="fas fa-edit"></i></button>
  </div>
  <app-input-errors [element]="form.get(control)"></app-input-errors>
</div>

<ng-template #defaultSelected let-element> {{ element.first_name }}</ng-template>
