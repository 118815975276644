import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {RestType} from '../../shared/models/RestType';

@Injectable({
  providedIn: 'root'
})
export class RestTypeService extends DictionaryService<RestType> {

  path = 'rest_types';

}
