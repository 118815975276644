import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {CostCenter} from '../../shared/models/CostCenter';

@Injectable({
  providedIn: 'root'
})
export class CostCenterService extends DictionaryService<CostCenter> {

  path = 'cost_centers';

  getCostCenterIdByName(name: string): Observable<number | null> {
    return this.getAll().pipe(
      map(response => {
        const costcenters: CostCenter[] = response.data;
        const foundCostCenter = costcenters.find(costcenter => costcenter.name === name);
        return foundCostCenter ? foundCostCenter.id : null;
      })
    );
  }

  getCostCenterNameById(id: number | string): Observable<string | null | undefined> {
    return this.getAll().pipe(
      map(response => {
        if (!response.data || !Array.isArray(response.data)) {
          return null;
        }
        const costcenters: CostCenter[] = response.data;
        const foundCostCenter = costcenters.find(costcenter => costcenter.id === id);
        return foundCostCenter ? foundCostCenter.name : null;
      })
    );
  }

}
