import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {Task} from '../../shared/models/Task';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends HttpService {

  getAll(): Observable<BaseResponse<Task[]>>{
    return this.get('tasks');
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: Task,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<Task>>>{
    const request = new PaginatedDataRequest<Task>(entries, page, data, order);
    return this.post('tasks', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Task): Observable<BaseResponse<Task>>{
    return this.post('tasks/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Task>>{
    return this.delete('tasks', id);
  }

  updateItem(request: Task, id: number): Observable<BaseResponse<Task>>{
    return this.put('tasks', request, id);
  }
}
