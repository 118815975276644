import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {CrudModalService} from '../../../admin/services/crudModal.service';

@Component({
  selector: 'app-select-dynamic',
  templateUrl: './select-dynamic.component.html',
  styleUrls: ['./select-dynamic.component.css']
})
export class SelectDynamicComponent implements OnInit {

  @Input()  service!: (arg0?: any, arg1?: number) => Observable<any>;
  @Input() model = '';
  @Input() control = '';
  @Input() public form!: UntypedFormGroup;
  @Input() multiple = false;
  @Input() label = '';
  @Input() readonly = false;
  @Input() enableEdit = false;
  @Input() createComponent: any;
  @Input() greenButton = false;
  @Input() clearable = true;
  @Input() optionValue = 'id';
  @Input() placeholder = '';
  @ContentChild("optionTemplate", { static: false }) optionTemplate?: TemplateRef<any>;
  @Output() changeSelected = new EventEmitter<any>();

  public elements: any[] = [];

  constructor(private crudModalService: CrudModalService) { }

  ngOnInit(): void {
    this.updateElements('', this.form?.get(this.control)?.value);
  }

  updateElements(search: string = '', id: number = 0): void {
    this.service(search, id)
      .subscribe(result => {
        this.elements = (result.data.data) ? result.data.data : result.data;
      });
  }
  
  clearSelection(): void {
    this.updateElements('', this.form?.get(this.control)?.value);
  }

  open(): void{
    if (this.elements.length === 0) {
      this.updateElements();
    }
  }

  changeValue(element: number): void{
    this.changeSelected.emit(this.elements.find(el => el.id === element));
  }

  create(): void {
    this.crudModalService.openModal(this.createComponent).result.then(() => {
    }, () => {
      this.updateElements();
    });
  }

}
