import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {City} from '../../shared/models/City';

@Injectable({
  providedIn: 'root'
})
export class CityService extends HttpService {

  getAll(province_id: number): Observable<BaseResponse<City[]>>{
    return this.get('cities/' + province_id);
  }
}
