import {Component, Injector, Input, OnInit, TemplateRef} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormArray, UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {CrudComponent} from "../../../../../shared/components/crud/crud.component";
import {CustomValidationService} from "../../../../../shared/services/custom-validation.service";
import {ToastService} from "../../../../../shared/services/toast.service";
import {Document} from '../../../../../shared/models/Document';
import {DocumentService} from '../../../../services/document.service';

@Component({
  selector: 'app-doc-file-sign-modal',
  templateUrl: './doc-file-sign-modal.component.html'
})
export class DocFileSignModalComponent extends CrudComponent implements OnInit{

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    public validationService: CustomValidationService,
    public toastService: ToastService,
    private documentService: DocumentService
  ) {
    super(modalService, fb);
  }

  document?: Document;
  fileTypes: string[] = this.pdfFileTypes;

  ngOnInit(): void {
    this.form = this.fb.group({
      document: ['', [Validators.required]]
    });
  }

  onSubmit({ value, valid }: { value: {document: any}, valid: boolean }): void{
    if (valid){
      this.callRegister(value);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  callRegister(request: {document: any}): void{
    if (this.document?.id){
      this.documentService.signFile(request, this.document?.id).subscribe(() => {
        this.activeModal.close();
      });
    }
  }
}
