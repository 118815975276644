import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {CrudComponent} from '../../../../shared/components/crud/crud.component';
import {BaseResponse} from '../../../../shared/models/respose/BaseResponse';
import {PaginatedDataResponse} from '../../../../shared/models/respose/PaginatedDataResponse';
import {Job} from '../../../../shared/models/Job';
import {JobService} from '../../../services/job.service';
import {Pagination} from '../../../../shared/models/Pagination';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent extends CrudComponent {
  public paginatedResponse: PaginatedDataResponse<Job> = new PaginatedDataResponse<Job>(0, [], 0, 0, 0);

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private service: JobService
  ) {
    super(modalService, fb);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      name: ['', [Validators.required]]
    });
    this.formSearch = this.fb.group({
      name: ['']
    });
    this.updateList({page: 1, elements: this.elements});
  }

  onSubmit({value, valid}: { value: Job, valid: boolean }): void {
    if (valid) {
      if (value.id) {
        this.callUpdate(value);
      } else {
        this.callRegister(value);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  onFind({value, valid}: { value: any, valid: boolean }): void {
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void{
    const job: Job = this.formSearch.value;
    this.service.getPaginatedList(pagination.elements, pagination.page, job, this.order).subscribe(
      (response: BaseResponse<PaginatedDataResponse<Job>>) => {
        this.paginatedResponse = response.data;
      }
    );
  }

  openTrashModal(content: TemplateRef<any>, job: Job): void {
    this.modalService.open(content, {ariaLabelledBy: 'appointment-detail-modal-basic-title'}).result.then((result) => {
      this.service.deleteItem(job.id).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  edit(job: Job): void {
    this.form.setValue(
      {
        id: job.id,
        name: job.name
      }
    );
    this.showHideForm();
  }

  callRegister(job: Job): void {
    this.service.register(job).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }

  callUpdate(job: Job): void {
    this.service.updateItem(job, job.id).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }
}
