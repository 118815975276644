<app-header *ngIf="!hideHeader" [text]="project === 'hrss' ? 'Strutture' : 'Aziende'"></app-header>
<section class="content">
  <div class="container-fluid" style="padding-bottom: 65px;">
    <form (ngSubmit)="onSubmit(form)" *ngIf=formVisible [formGroup]="form" novalidate>
      <div class="row text-right mb-3">
        <div *ngIf="!fromModal" class="col-md-12">
          <br>
          <button (click)="showHideForm(); showHideTable()" *ngIf="action !== 'edit'" class="btn btn-primary"
                  type="button"><i
            class="fas fa-caret-square-left"></i> Indietro
          </button>
          <button (click)="back()" *ngIf="action === 'edit'" class="btn btn-primary"
                  type="button"><i
            class="fas fa-caret-square-left"></i> Indietro
          </button>
        </div>
      </div>
      <input formControlName="id" type="hidden">
      <input formControlName="is_company" type="hidden">
      <div>
        <div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title mb-0">Informazioni di contatto</h3>
            <div class="card-tools">
              <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="company_name">Nome azienda</label>
                  <input [ngClass]="getInvalidClass('company_name')" class="form-control" formControlName="company_name"
                         id="company_name"
                         placeholder="Nome azienda" type="text">
                  <app-input-errors [element]="form.get('company_name')"></app-input-errors>
                </div>
              </div>
              <div class="col-md-6">
                <app-input [controlName]="'iva'" [form]="form" [label]="'Partita IVA'"></app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <app-input [controlName]="'referente'" [form]="form" [label]="'Referente'"></app-input>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="work_number">Numeri di telefono Referente (uno o più separati da ';')</label>
                  <input [ngClass]="getInvalidClass('work_number')" class="form-control" formControlName="work_number"
                         id="work_number"
                         placeholder="Numero di telefono" type="text">
                </div>
                <app-input-errors [element]="form.get('work_number')"></app-input-errors>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <app-input [controlName]="'nominativo_amministratore'" [form]="form"
                           [label]="'Nominativo Amministratore'"></app-input>
              </div>
              <div class="col-md-6">
                <app-input [controlName]="'work_number_admin'"
                           [form]="form"
                           [label]="'Numeri di telefono Amministratore (uno o più separati da \';\')'"></app-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label for="email_company">Email principale</label>
                <input [ngClass]="getInvalidClass('email')" class="form-control" formControlName="email"
                       id="email_company"
                       placeholder="Email principale" type="text">
                <app-input-errors [element]="form.get('email')"></app-input-errors>
              </div>
              <div class="col-md-6">
                <app-input [controlName]="'email_list'" [form]="form"
                           label="Altri indirizzi email (uno o più separati da ';')"></app-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fiscal_code">Codice fiscale</label>
                  <input [ngClass]="getInvalidClass('fiscal_code')" class="form-control" formControlName="fiscal_code"
                         id="fiscal_code"
                         maxlength="16" placeholder="Codice fiscale" type="text">
                </div>
                <app-input-errors [element]="form.get('fiscal_code')"></app-input-errors>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <app-select-dictionary [createComponent]="components.ContactTypesComponent"
                                       [dictionary]="'contact_type'" [form]="form"
                                       [label]="'Tipo contatto'"></app-select-dictionary>
              </div>
              <div class="col-md-6">
                <app-select-dictionary [createComponent]="components.CompanyActivitiesComponent"
                                       [dictionary]="'company_activity'"
                                       [form]="form"
                                       [label]="'Attività prevalente azienda'"></app-select-dictionary>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <app-select-dictionary [createComponent]="components.SourcesComponent" [dictionary]="'source'"
                                       [form]="form"
                                       [label]="'Fonte'"></app-select-dictionary>
              </div>
              <div class="col-md-6">
                <app-select-dynamic
                  [control]="'user_id'"
                  [form]="form"
                  [label]="'Operatore'"
                  [model]="'user'"
                  [readonly]="!canChangeUser"
                  [service]="getUserService.bind(this)"
                >
                  <ng-template #optionTemplate let-element>
                    {{ element.first_name }} {{ element.last_name }}
                  </ng-template>
                </app-select-dynamic>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title mb-0">Note</h3>
        </div>
        <div class="card-body">
          <label for="note">Note</label>
          <angular-editor [config]="editorConfig" [ngClass]="getInvalidClass('note')" formControlName="note"
                          id="note"></angular-editor>
          <app-input-errors [element]="form.get('note')"></app-input-errors>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="d-flex flex-fill flex-column me-2">
          <div class="card card-primary collapsed-card">
            <div class="card-header">
              <h3 class="card-title mb-0">Anagrafica</h3>
              <div class="card-tools">
                <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>

            <div class="card-body">
              <strong>Sito WEB</strong>
              <input [ngClass]="getInvalidClass('sito_web')" class="form-control" formControlName="sito_web"
                     id="sito_web"
                     placeholder="Sito Web" type="text">
              <app-input-errors [element]="form.get('sito_web')"></app-input-errors>
              <hr>
              <strong>Fax</strong>
              <input [ngClass]="getInvalidClass('fax')" class="form-control" formControlName="fax" id="fax"
                     placeholder="Fax"
                     type="text">
              <app-input-errors [element]="form.get('fax')"></app-input-errors>
              <hr>
              <strong>Via</strong>
              <input [ngClass]="getInvalidClass('via')" class="form-control" formControlName="via" id="via"
                     placeholder="Via"
                     type="text">
              <app-input-errors [element]="form.get('via')"></app-input-errors>
              <hr>
              <strong>Frazione</strong>
              <input [ngClass]="getInvalidClass('frazione')" class="form-control" formControlName="frazione"
                     id="frazione"
                     placeholder="Frazione" type="text">
              <app-input-errors [element]="form.get('frazione')"></app-input-errors>
              <hr>
              <strong>Provincia / Città</strong>
              <div class="row">
                <div class="col-6">
                  <ng-select
                    (change)="updateCities($event)"
                    (open)="updateProvinces()"
                    bindLabel="province"
                    formControlName="province_id"
                  >
                    <ng-option *ngFor="let province of provinces" [value]="province.id">
                      {{ province.name }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-6">
                  <ng-select
                    bindLabel="city"
                    formControlName="city_id">
                    <ng-option *ngFor="let city of cities" [value]="city.id">
                      {{ city.name }}
                    </ng-option>
                  </ng-select>
                  <app-input-errors [element]="form.get('city_id')"></app-input-errors>
                </div>
              </div>
              <hr>
              <strong>Cap</strong>
              <input class="form-control" formControlName="cap" placeholder="Cap" type="text">
              <hr>
              <strong>Tipo Sede</strong>
              <input [ngClass]="getInvalidClass('tipo_di_sede')" class="form-control" formControlName="tipo_di_sede"
                     id="tipo_di_sede"
                     placeholder="Tipo Sede" type="text">
              <app-input-errors [element]="form.get('tipo_di_sede')"></app-input-errors>
              <hr>
              <strong>Indirizzo E-Commerce</strong>
              <input [ngClass]="getInvalidClass('indirizzo_e_commerce')" class="form-control"
                     formControlName="indirizzo_e_commerce"
                     id="indirizzo_e_commerce" placeholder="Indirizzo E-Commerce"
                     type="text">
              <app-input-errors [element]="form.get('indirizzo_e_commerce')"></app-input-errors>
              <hr>
              <strong>Pec</strong>
              <input [ngClass]="getInvalidClass('indirizzo_pec')" class="form-control" formControlName="indirizzo_pec"
                     id="indirizzo_pec"
                     placeholder="Pec" type="text">
              <app-input-errors [element]="form.get('indirizzo_pec')"></app-input-errors>
              <hr>
              <app-input [controlName]="'sdi'" [form]="form" [label]="'SDI'"></app-input>
              <hr>
            </div>

          </div>
          <div class="card card-success collapsed-card">
            <div class="card-header">
              <h3 class="card-title mb-0">Social</h3>
              <div class="card-tools">
                <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>

            <div class="card-body">
              <strong>Social Attivi</strong>
              <select class="form-control" formControlName="social_attivi" id="social_attivi">
                <option value="1">SI</option>
                <option value="0">NO</option>
              </select>
              <app-input-errors [element]="form.get('social_attivi')"></app-input-errors>
              <hr>
              <strong>Facebook</strong>
              <input [ngClass]="getInvalidClass('facebook')" class="form-control" formControlName="facebook"
                     id="facebook"
                     placeholder="Facebook" type="text">
              <app-input-errors [element]="form.get('facebook')"></app-input-errors>
              <hr>
              <strong>Instagram</strong>
              <input [ngClass]="getInvalidClass('instagram')" class="form-control" formControlName="instagram"
                     id="instagram"
                     placeholder="Instagram" type="text">
              <app-input-errors [element]="form.get('instagram')"></app-input-errors>
              <hr>
              <strong>Linkedin</strong>
              <input [ngClass]="getInvalidClass('linkedin')" class="form-control" formControlName="linkedin"
                     id="linkedin"
                     placeholder="Linkedin" type="text">
              <app-input-errors [element]="form.get('linkedin')"></app-input-errors>
              <hr>
              <strong>Pinterest</strong>
              <input [ngClass]="getInvalidClass('pinterest')" class="form-control" formControlName="pinterest"
                     id="pinterest"
                     placeholder="Pinterest" type="text">
              <app-input-errors [element]="form.get('pinterest')"></app-input-errors>
              <hr>
              <strong>YouTube</strong>
              <input [ngClass]="getInvalidClass('youtube')" class="form-control" formControlName="youtube" id="youtube"
                     placeholder="Youtube" type="text">
              <app-input-errors [element]="form.get('youtube')"></app-input-errors>
              <hr>
              <strong>Twitter</strong>
              <input [ngClass]="getInvalidClass('twitter')" class="form-control" formControlName="twitter" id="twitter"
                     placeholder="Twitter" type="text">
              <app-input-errors [element]="form.get('twitter')"></app-input-errors>
              <hr>
              <strong>Vimeo</strong>
              <input [ngClass]="getInvalidClass('vimeo')" class="form-control" formControlName="vimeo" id="vimeo"
                     placeholder="Vimeo" type="text">
              <app-input-errors [element]="form.get('vimeo')"></app-input-errors>
              <hr>
            </div>

          </div>
          <div class="card card-warning collapsed-card">
            <div class="card-header">
              <h3 class="card-title mb-0">Dipendenti</h3>
              <div class="card-tools">
                <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>

            <div class="card-body">
              <strong>Numero dipendenti</strong>
              <input [ngClass]="getInvalidClass('dipendenti')" class="form-control" formControlName="dipendenti"
                     id="dipendenti"
                     min="0" placeholder="Numero Dipendenti"
                     type="number">
              <app-input-errors [element]="form.get('dipendenti')"></app-input-errors>
              <hr>
              <strong>Trend dipendenti</strong>
              <input [ngClass]="getInvalidClass('trend_dipendenti')" class="form-control"
                     formControlName="trend_dipendenti"
                     id="trend_dipendenti" placeholder="Trend dipendenti"
                     type="text">
              <app-input-errors [element]="form.get('trend_dipendenti')"></app-input-errors>
              <hr>
              <app-select-dictionary [dictionary]="'personnel_number'" [enableEdit]="false"
                                     [form]="form" [label]="'Classe numero dipendenti'"></app-select-dictionary>
              <hr>
              <strong>Dipendenti contratto a tempo determinato</strong>
              <input [ngClass]="getInvalidClass('percentuale_dipendenti_contratto_a_tempo_determinato')"
                     class="form-control" formControlName="percentuale_dipendenti_contratto_a_tempo_determinato"
                     id="percentuale_dipendenti_contratto_a_tempo_determinato"
                     min="0"
                     placeholder="Dipendenti contratto a tempo determinato"
                     type="number">
              <app-input-errors
                [element]="form.get('percentuale_dipendenti_contratto_a_tempo_determinato')"></app-input-errors>
              <hr>
              <strong>Dipendenti contratto a tempo indeterminato</strong>
              <input [ngClass]="getInvalidClass('percentuale_dipendenti_contratto_a_tempo_indeterminato')"
                     class="form-control" formControlName="percentuale_dipendenti_contratto_a_tempo_indeterminato"
                     id="percentuale_dipendenti_contratto_a_tempo_indeterminato"
                     min="0"
                     placeholder="Dipendenti contratto a tempo indeterminato"
                     type="number">
              <app-input-errors
                [element]="form.get('percentuale_dipendenti_contratto_a_tempo_indeterminato')"></app-input-errors>
              <hr>
              <strong>Dipendenti contratto a tempo parziale</strong>
              <input [ngClass]="getInvalidClass('percentuale_dipendenti_contratto_a_tempo_parziale')"
                     class="form-control" formControlName="percentuale_dipendenti_contratto_a_tempo_parziale"
                     id="percentuale_dipendenti_contratto_a_tempo_parziale"
                     min="0"
                     placeholder="Dipendenti contratto a tempo parziale"
                     type="number">
              <app-input-errors
                [element]="form.get('percentuale_dipendenti_contratto_a_tempo_parziale')"></app-input-errors>
              <hr>
              <strong>Dipendenti contratto a tempo pieno</strong>
              <input [ngClass]="getInvalidClass('percentuale_dipendenti_contratto_a_tempo_pieno')" class="form-control"
                     formControlName="percentuale_dipendenti_contratto_a_tempo_pieno"
                     id="percentuale_dipendenti_contratto_a_tempo_pieno"
                     min="0"
                     placeholder="Dipendenti contratto a tempo pieno"
                     type="number">
              <app-input-errors
                [element]="form.get('percentuale_dipendenti_contratto_a_tempo_pieno')"></app-input-errors>
              <hr>
            </div>

          </div>
        </div>
        <div class="d-flex flex-fill flex-column ms-2">
          <div class="card card-info collapsed-card">
            <div class="card-header">
              <h3 class="card-title mb-0">Info Azienda</h3>
              <div class="card-tools">
                <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>

            <div class="card-body">
              <strong>Forma Legale</strong>
              <input [ngClass]="getInvalidClass('forma_legale')" class="form-control" formControlName="forma_legale"
                     id="forma_legale"
                     placeholder="Forma Legale" type="text">
              <app-input-errors [element]="form.get('forma_legale')"></app-input-errors>
              <hr>
              <strong>Forma Legale Dettagliata</strong>
              <input [ngClass]="getInvalidClass('forma_legale_dettagliata')" class="form-control"
                     formControlName="forma_legale_dettagliata"
                     id="forma_legale_dettagliata" placeholder="Forma Legale Dettagliata"
                     type="text">
              <app-input-errors [element]="form.get('forma_legale_dettagliata')"></app-input-errors>
              <hr>
              <strong>Azienda della pubblica amministrazione</strong>
              <select class="form-control" formControlName="azienda_della_pubblica_amministrazione"
                      id="azienda_della_pubblica_amministrazione">
                <option value="1">SI</option>
                <option value="0">NO</option>
              </select>
              <app-input-errors [element]="form.get('azienda_della_pubblica_amministrazione')"></app-input-errors>
              <hr>
              <strong>Data di fondazione</strong>
              <input class="form-control" formControlName="data_di_fondazione" id="data_di_fondazione" max="2199-12-31"
                     min="1900-01-01"
                     placeholder="Data di fondazione" type="date">
              <app-input-errors [element]="form.get('data_di_fondazione')"></app-input-errors>
              <hr>
              <strong>Data di inizio attività</strong>
              <input class="form-control" formControlName="data_inizio_attivita" id="data_inizio_attivita"
                     max="2199-12-31" min="1900-01-01"
                     placeholder="Data di inizio attività" type="date">
              <app-input-errors [element]="form.get('data_inizio_attivita')"></app-input-errors>
              <hr>
              <strong>Data costituzione</strong>
              <input class="form-control" formControlName="data_costituzione" id="data_costituzione" max="2199-12-31"
                     min="1900-01-01"
                     placeholder="Data costituzione" type="date">
              <app-input-errors [element]="form.get('data_costituzione')"></app-input-errors>
              <hr>
              <strong>Data di iscrizione alla camera di commercio</strong>
              <input class="form-control" formControlName="data_iscrizione_in_camera_di_commercio"
                     id="data_iscrizione_in_camera_di_commercio" max="2199-12-31"
                     min="1900-01-01" placeholder="Data di iscrizione alla camera di commercio"
                     type="date">
              <app-input-errors [element]="form.get('data_iscrizione_in_camera_di_commercio')"></app-input-errors>
              <hr>
              <strong>Anno di iscrizione alla camera di commercio</strong>
              <input class="form-control" formControlName="anno_iscrizione_in_camera_di_commercio"
                     id="anno_iscrizione_in_camera_di_commercio"
                     type="number">
              <app-input-errors [element]="form.get('anno_iscrizione_in_camera_di_commercio')"></app-input-errors>
              <hr>
              <app-input [controlName]="'matr_inps'" [form]="form" [label]="'Matricola INPS'"></app-input>
              <hr>
              <app-input [controlName]="'matr_inail'" [form]="form" [label]="'Matricola INAIL'"></app-input>
              <hr>
              <strong>Startup innovativa</strong>
              <select class="form-control" formControlName="startup_innovativa" id="startup_innovativa">
                <option value="1">SI</option>
                <option value="0">NO</option>
              </select>
              <app-input-errors [element]="form.get('startup_innovativa')"></app-input-errors>
              <hr>
              <strong>Pmi innovativa</strong>
              <select class="form-control" formControlName="pmi_innovative" id="pmi_innovative">
                <option value="1">SI</option>
                <option value="0">NO</option>
              </select>
              <app-input-errors [element]="form.get('pmi_innovative')"></app-input-errors>
              <hr>
              <strong>Commerciabile</strong>
              <select class="form-control" formControlName="marketable_truefalse" id="marketable_truefalse">
                <option value="1">SI</option>
                <option value="0">NO</option>
              </select>
              <app-input-errors [element]="form.get('marketable_truefalse')"></app-input-errors>
              <hr>
              <strong>Codice ATECO primario 2007</strong>
              <input [ngClass]="getInvalidClass('codice_ateco_primario_2007')" class="form-control"
                     formControlName="codice_ateco_primario_2007"
                     id="codice_ateco_primario_2007" placeholder="Codice ATECO primario 2007"
                     type="text">
              <app-input-errors [element]="form.get('codice_ateco_primario_2007')"></app-input-errors>
              <hr>
              <strong>Descrizione Codice ATECO primario 2007</strong>
              <input [ngClass]="getInvalidClass('descrizione_codice_ateco_primario_2007')" class="form-control"
                     formControlName="descrizione_codice_ateco_primario_2007"
                     id="descrizione_codice_ateco_primario_2007"
                     placeholder="Descrizione Codice ATECO primario 2007"
                     type="text">
              <app-input-errors [element]="form.get('descrizione_codice_ateco_primario_2007')"></app-input-errors>
              <hr>
              <strong>Codice ATECO secondario 2007</strong>
              <input [ngClass]="getInvalidClass('codice_ateco_secondario_2007')" class="form-control"
                     formControlName="codice_ateco_secondario_2007"
                     id="codice_ateco_secondario_2007"
                     placeholder="Codice ATECO secondario 2007" type="text">
              <app-input-errors [element]="form.get('codice_ateco_secondario_2007')"></app-input-errors>
              <hr>
              <strong>Codice ATECO primario 2007 primo livello</strong>
              <input [ngClass]="getInvalidClass('codice_ateco_primario_2007_primo_livello')" class="form-control"
                     formControlName="codice_ateco_primario_2007_primo_livello"
                     id="codice_ateco_primario_2007_primo_livello"
                     placeholder="Codice ATECO primario 2007 primo livello"
                     type="text">
              <app-input-errors [element]="form.get('codice_ateco_primario_2007_primo_livello')"></app-input-errors>
              <hr>
              <app-select-dictionary [dictionary]="'company_dimension'" [enableEdit]="false" [form]="form"
                                     [label]="'Dimensione azienda'"></app-select-dictionary>
              <hr>
              <strong>SIC primario</strong>
              <input [ngClass]="getInvalidClass('sic_primario')" class="form-control" formControlName="sic_primario"
                     id="sic_primario"
                     placeholder="SIC primario" type="text">
              <app-input-errors [element]="form.get('sic_primario')"></app-input-errors>
              <hr>
              <strong>Descrizione SIC primario</strong>
              <input [ngClass]="getInvalidClass('descrizione_codice_sic_primario')" class="form-control"
                     formControlName="descrizione_codice_sic_primario"
                     id="descrizione_codice_sic_primario"
                     placeholder="DescrizioneSIC primario" type="text">
              <app-input-errors [element]="form.get('descrizione_codice_sic_primario')"></app-input-errors>
              <hr>
              <strong>SIC secondario</strong>
              <input [ngClass]="getInvalidClass('sic_secondario')" class="form-control" formControlName="sic_secondario"
                     id="sic_secondario"
                     placeholder="SIC secondario" type="text">
              <app-input-errors [element]="form.get('sic_secondario')"></app-input-errors>
              <hr>
              <strong>Codice NACE</strong>
              <input [ngClass]="getInvalidClass('nace')" class="form-control" formControlName="nace" id="nace"
                     placeholder="Codice NACE" type="text">
              <app-input-errors [element]="form.get('nace')"></app-input-errors>
              <hr>
              <strong>Descrizione Codice NACE</strong>
              <input [ngClass]="getInvalidClass('descrizione_codice_nace')" class="form-control"
                     formControlName="descrizione_codice_nace"
                     id="descrizione_codice_nace" placeholder="Descrizione Codice NACE"
                     type="text">
              <app-input-errors [element]="form.get('descrizione_codice_nace')"></app-input-errors>
              <hr>
              <strong>EBIT</strong>
              <input [ngClass]="getInvalidClass('ebit')" class="form-control" formControlName="ebit" id="ebit"
                     placeholder="EBIT"
                     type="text">
              <app-input-errors [element]="form.get('ebit')"></app-input-errors>
              <hr>
              <strong>EBITDA</strong>
              <input [ngClass]="getInvalidClass('ebitda')" class="form-control" formControlName="ebitda" id="ebitda"
                     placeholder="EBITDA" type="text">
              <app-input-errors [element]="form.get('ebitda')"></app-input-errors>
              <hr>
              <strong>Numero CRIF</strong>
              <input [ngClass]="getInvalidClass('crif_number')" class="form-control" formControlName="crif_number"
                     id="crif_number"
                     placeholder="Numero CRIF" type="text">
              <app-input-errors [element]="form.get('crif_number')"></app-input-errors>
              <hr>
              <strong>REA</strong>
              <input [ngClass]="getInvalidClass('rea')" class="form-control" formControlName="rea" id="rea"
                     placeholder="REA"
                     type="text">
              <app-input-errors [element]="form.get('rea')"></app-input-errors>
              <hr>
              <strong>Unità locali</strong>
              <input [ngClass]="getInvalidClass('unita_locali')" class="form-control" formControlName="unita_locali"
                     id="unita_locali"
                     placeholder="Unità locali" type="text">
              <app-input-errors [element]="form.get('unita_locali')"></app-input-errors>
              <hr>
              <strong>Tipologia unità locali</strong>
              <input [ngClass]="getInvalidClass('tipologia_unita_locale')" class="form-control"
                     formControlName="tipologia_unita_locale"
                     id="tipologia_unita_locale" placeholder="Tipologia unità locali"
                     type="text">
              <app-input-errors [element]="form.get('tipologia_unita_locale')"></app-input-errors>
              <hr>
            </div>

          </div>
          <div class="card card-danger collapsed-card">
            <div class="card-header">
              <h3 class="card-title mb-0">Fatturato</h3>
              <div class="card-tools">
                <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>

            <div class="card-body">
              <strong>Anno Fatturato</strong>
              <input [ngClass]="getInvalidClass('anno_fatturato')" class="form-control" formControlName="anno_fatturato"
                     id="anno_fatturato" placeholder="Anno Fatturato"
                     type="number">
              <app-input-errors [element]="form.get('anno_fatturato')"></app-input-errors>
              <hr>
              <strong>Fatturato d'esercizio</strong>
              <input [ngClass]="getInvalidClass('fatturato_desercizio')" class="form-control"
                     formControlName="fatturato_desercizio"
                     id="fatturato_desercizio" placeholder="Fatturato d'esercizio"
                     type="number">
              <app-input-errors [element]="form.get('fatturato_desercizio')"></app-input-errors>
              <hr>
              <strong>Fatturato Consolidato</strong>
              <input [ngClass]="getInvalidClass('fatturato_consolidato')" class="form-control"
                     formControlName="fatturato_consolidato"
                     id="fatturato_consolidato" placeholder="Fatturato Consolidato"
                     type="number">
              <app-input-errors [element]="form.get('fatturato_consolidato')"></app-input-errors>
              <hr>
              <strong>Trend Fatturato d'esercizio</strong>
              <input [ngClass]="getInvalidClass('trend_fatturato_di_esercizio')" class="form-control"
                     formControlName="trend_fatturato_di_esercizio"
                     id="trend_fatturato_di_esercizio"
                     placeholder="Trend Fatturato d'esercizio" type="number">
              <app-input-errors [element]="form.get('trend_fatturato_di_esercizio')"></app-input-errors>
              <hr>
            </div>

          </div>
        </div>
      </div>
      <div class="card">
        <div *ngIf="!modalSaved" class="bottom-bar">
          <button *ngIf="!modalSaved" class="btn-lg btn-primary" type="submit">Salva</button>
          <div *ngIf="modalSaved" class="callout callout-success">
            <h5>Oggetto salvato</h5>
            <p>E' possibile chiudere la finestra premendo ESC o cliccando fuori dalla sua area.</p>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf=tableVisible class="row">
      <div class="col-md-12 text-right mb-3">
        <button (click)="new()" class="btn btn-success" type="button"><i class="fas fa-plus-square"></i> Nuovo</button>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header d-flex">
            <h3 class="card-title">
              {{ project === 'hrss' ? 'Lista Strutture' : 'Lista Aziende' }} ({{ paginatedResponse.total }})
            </h3>
            <app-collapse-button [label]="'Filtri'" [ref]="'collapseFilters'" class="ms-auto"></app-collapse-button>
          </div>

          <div class="collapse" id="collapseFilters">
            <app-company-filters (export)="exportCompanies($event)" (search)="onFind($event)"></app-company-filters>
          </div>

          <app-company-table
            (clickEdit)="edit($event)"
            (update)="updateList($event)"
            [(elements)]="elements"
            [order]="order"
            [paginatedResponse]="paginatedResponse"
          ></app-company-table>
        </div>
      </div>
    </div>
  </div>
</section>
