<div class="row text-right mb-3">
  <div class="col-md-12">
    <button type="button" class="btn btn-primary" (click)="goBack()" i18n="pulsante indietro"><i class="fas fa-caret-square-left"></i> Indietro</button>
  </div>
</div>

<div class="row">
  <div class="col">
    <app-contact-profile [contact]="contact"></app-contact-profile>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col text-center">
        <div class="btn btn-primary" (click)="goEdit()">Modifica</div>
      </div>
    </div>
  </div>
</div>

