import {Component, Input} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../../models/Order';
import {DirectionType} from '../../../admin/enum/DirectionType';
import {DocumentContentType} from '../../enum/DocumentContentType';
import {environment} from '../../../../environments/environment';
import {FileConstants} from '../../constants/FileConstants';

@Component({
  template: ''
})
export abstract class CrudComponent{
  public pdfFileTypes = FileConstants.pdfExtension;
  public excelFileTypes = FileConstants.excelExtensions;
  public imageFileTypes = FileConstants.imageExtensions;
  public max_file_size_kb = environment.max_file_size_kb;
  private _form!: UntypedFormGroup;
  private _formSearch!: UntypedFormGroup;
  private _order: Order = new Order('id', DirectionType.desc);
  private _formVisible = false;
  private _tableVisible = true;
  private _detailVisible = false;
  private _elements = 10;
  private _fromModal = false;
  protected _hasModal = false;
  private _modalSaved = false;
  @Input() hideHeader = false;
  @Input() disableDetails = false;

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder) { }

  showHideForm(): void{
    this.formVisible = !this.formVisible;
  }

  showHideTable(): void{
    this.tableVisible = !this.tableVisible;
  }

  showHideDetail(): void{
    this.detailVisible = !this.detailVisible;
  }

  new(formResetValues = {}): void{
    this.form.reset(formResetValues);
    this.showHideTable();
    this.showHideForm();
  }

  getInvalidClass(element: string): string{
      // @ts-ignore
    return (this.form.get(element).invalid && (this.form.get(element).dirty || this.form.get(element).touched)) ? 'is-invalid' : '';
  }

  getInvalidClassByElement(element: UntypedFormControl): string{
    return (element.invalid && element.dirty) ? 'is-invalid' : '';
  }

  clearFilters(): void{
    this.formSearch.reset();
  }

  downloadPdf(base64String: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = base64String;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  downloadFile(base64String: string, fileName: string, extension: string = '.pdf'): void{
    const link = document.createElement('a');
    link.href = base64String;
    link.download = fileName + extension;
    link.click();
  }

  downloadBlobFile(blob: Blob, fileName: string, extension: string = '.pdf'): void{
    const binaryData = [];
    binaryData.push(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
    downloadLink.setAttribute('download', fileName + extension);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  getFileExtension(file: any): string{
    // @ts-ignore
    return DocumentContentType[file.split(';')[0].split('/')[1]] !== undefined ?
      // @ts-ignore
      DocumentContentType[file.split(';')[0].split('/')[1]] :
      file.split(';')[0].split('/')[1];
  }

  getTextSize(kb_size: number): string{
    if (kb_size > 1024){
      return kb_size / 1024 + ' mb';
    }
    return kb_size + ' kb';
  }

  onElementsChange(elements: number): void{
    this.elements = elements;
  }

  public get form(): UntypedFormGroup{return this._form; }
  public set form(form: UntypedFormGroup){this._form = form; }

  public get formSearch(): UntypedFormGroup{return this._formSearch; }
  public set formSearch(formSearch: UntypedFormGroup){this._formSearch = formSearch; }

  public get order(): Order{return this._order; }
  public set order(order: Order){this._order = order; }

  public get formVisible(): boolean {return this._formVisible; }
  public set formVisible(formVisible: boolean) {this._formVisible = formVisible; }

  public get tableVisible(): boolean {return this._tableVisible; }
  public set tableVisible(tableVisible: boolean) {this._tableVisible = tableVisible; }

  public get detailVisible(): boolean {return this._detailVisible; }
  public set detailVisible(detailVisible: boolean) {this._detailVisible = detailVisible; }

  public get elements(): number {return this._elements; }
  public set elements(elements: number) {this._elements = elements; }

  public get fromModal(): boolean {
    return this._fromModal;
  }
  public set fromModal(value: boolean) {
    this._fromModal = value;
  }

  public get hasModal(): boolean {
    return this._hasModal;
  }

  public get modalSaved(): boolean {
    return this._modalSaved;
  }
  public set modalSaved(value: boolean) {
    this._modalSaved = value;
  }

  public export(service: any, payload: any, fileName = 'export'): void {
    service.export(this.formSearch.value, this.order, payload).subscribe(
      (result: Blob) =>
      {
        this.downloadBlobFile(result, 'export', '.xlsx');
      }
    );
  }

}
