import { Pipe, PipeTransform } from '@angular/core';
import {Contact} from '../models/Contact';

@Pipe({name: 'contactName'})
export class ContactNamePipe implements PipeTransform {
  transform(contact?: Contact): string {
    if (contact?.is_company){
      return `${contact.company_name}`;
    }
    else {
      return `${contact?.last_name} ${contact?.first_name}`;
    }
  }
}
