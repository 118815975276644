import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import {Order} from '../../shared/models/Order';
import {DirectionType} from '../../admin/enum/DirectionType';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {UserRequest} from '../models/request/UserRequest';
import {ProjectRequest} from '../models/request/ProjectRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportService<RequestType> extends HttpService {

  protected exportPath = '';

  export(data?: UserRequest | ProjectRequest, order?: Order, payload?: any): Observable<Blob> {
    if (payload) {
      order = new Order('last_name', DirectionType.asc);
    }
    const request: PaginatedDataRequest<UserRequest | ProjectRequest> & { payload?: any } = {
      entries:100000,
      page: 1,
      data,
      order
    };
    if (payload) {
      request.payload = payload;
    }
    return this.post(this.exportPath + '/export', request, 'blob').pipe(
      map(response => {
        return response;
      })
    );
  }

}
