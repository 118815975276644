import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {ExperienceYear} from '../../shared/models/ExperienceYear';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExperienceYearService extends HttpService {



  getAll(): Observable<BaseResponse<ExperienceYear[]>>{
    return this.get('experience_years');
  }
  getPaginatedList(entries: number,
                   page: number,
                   data?: ExperienceYear,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<ExperienceYear>>>{
    const request = new PaginatedDataRequest<ExperienceYear>(entries, page, data, order);
    return this.post('experience_years', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: ExperienceYear): Observable<BaseResponse<ExperienceYear>>{
    return this.post('experience_years/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<ExperienceYear>>{
    return this.delete('experience_years', id);
  }

  updateItem(request: ExperienceYear, id: number): Observable<BaseResponse<ExperienceYear>>{
    return this.put('experience_years', request, id);
  }
}
