<app-header *ngIf="!hideHeader" [text]="project === 'hrss' ? 'Assistiti' : 'Persone fisiche'"></app-header>
<section class="content">
  <div class="container-fluid" style="padding-bottom: 65px;">
    <app-contacts-detail (back)="showHideDetail(); showHideTable()" (edit)="showHideDetail(); showHideTable(); edit(selectedContact)"
                         *ngIf="detailVisible"
                         [contact]="selectedContact"></app-contacts-detail>
    <form (ngSubmit)="onSubmit(form)" *ngIf=formVisible [formGroup]="form" novalidate>
      <div class="row text-right mb-3">
        <div *ngIf="!fromModal" class="col-md-12">
          <br>
          <button (click)="showHideForm(); showHideTable()" class="btn btn-primary" type="button"><i
            class="fas fa-caret-square-left"></i> Indietro
          </button>
        </div>
      </div>
      <input formControlName="id" type="hidden">
      <input formControlName="is_company" type="hidden">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title mb-0">Informazioni Base</h3>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="first_name">Nome</label>
                <input [ngClass]="getInvalidClass('first_name')" class="form-control" formControlName="first_name" id="first_name"
                       placeholder="Nome" type="text">
              </div>
              <app-input-errors [element]="form.get('first_name')"></app-input-errors>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="last_name">Cognome</label>
                <input [ngClass]="getInvalidClass('last_name')" class="form-control" formControlName="last_name" id="last_name"
                       placeholder="Cognome" type="text">
                <app-input-errors [element]="form.get('last_name')"></app-input-errors>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col">
              <app-select-dictionary [dictionary]="'gender'" [enableEdit]="false" [form]="form"
                                     [label]="'Sesso'"></app-select-dictionary>
            </div>
            <div class="col">
              <app-input [controlName]="'fiscal_code'" [form]="form" [label]="'Codice Fiscale'"></app-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="birth_date">Data di nascita</label>
                <input [max]="maxBirthDate()" class="form-control" formControlName="birth_date" id="birth_date"
                       placeholder="Data di nascita" type="date">
                <app-input-errors [element]="form.get('birth_date')"></app-input-errors>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email</label>
                <input [ngClass]="getInvalidClass('email')" class="form-control" formControlName="email" id="email"
                       placeholder="Email" type="text">
              </div>
              <app-input-errors [element]="form.get('email')"></app-input-errors>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="work_number">Telefono Lavoro</label>
                <input [ngClass]="getInvalidClass('work_number')" class="form-control" formControlName="work_number" id="work_number"
                       placeholder="Telefono Lavoro" type="text">
              </div>
              <app-input-errors [element]="form.get('work_number')"></app-input-errors>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="mobile_number">Telefono Personale</label>
                <input [ngClass]="getInvalidClass('mobile_number')" class="form-control" formControlName="mobile_number" id="mobile_number"
                       placeholder="Telefono Personale" type="text">
              </div>
              <app-input-errors [element]="form.get('mobile_number')"></app-input-errors>
            </div>
          </div>
          <div class="row">

            <div class="col-md">
              <app-select-dictionary [createComponent]="components.PositionsComponent" [dictionary]="'position'" [form]="form"
                                     [label]="'Tipo di cliente'"></app-select-dictionary>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <app-select-dynamic
                  [control]="'user_id'"
                  [form]="form"
                  [label]="'Operatore'"
                  [model]="'user'"
                  [readonly]="!canChangeUser"
                  [service]="getUserService.bind(this)"
                >
                  <ng-template #optionTemplate let-element>
                    {{ element.first_name }} {{ element.last_name }}
                  </ng-template>
                </app-select-dynamic>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <app-select-dictionary [createComponent]="components.ContactTypesComponent" [dictionary]="'contact_type'" [form]="form"
                                     [label]="'Tipo contatto'"></app-select-dictionary>
            </div>
            <div class="col-md-6">
              <app-select-dictionary [createComponent]="components.IncomeTypesComponent" [dictionary]="'income_type'" [form]="form"
                                     [label]="'Tipologia di Reddito'"></app-select-dictionary>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-select-dictionary [createComponent]="components.SourcesComponent" [dictionary]="'source'" [form]="form"
                                     [label]="'Fonte'"></app-select-dictionary>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title mb-0">Note</h3>
        </div>
        <div class="card-body">
          <label for="note">Note</label>
          <angular-editor [config]="editorConfig" [ngClass]="getInvalidClass('note')" formControlName="note"
                          id="note"></angular-editor>
          <app-input-errors [element]="form.get('note')"></app-input-errors>
        </div>
      </div>
      <div class="card">
        <div *ngIf="!modalSaved" class="bottom-bar">
          <button *ngIf="!modalSaved" class="btn btn-primary" type="submit">Salva</button>
          <div *ngIf="modalSaved" class="callout callout-success">
            <h5>Oggetto salvato</h5>
            <p>E' possibile chiudere la finestra premendo ESC o cliccando fuori dalla sua area.</p>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf=tableVisible class="row">
      <div class="col-md-12 text-right mb-3">
        <button (click)="new()" class="btn btn-success" type="button"><i class="fas fa-plus-square"></i> Nuovo</button>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header d-flex">
            <h3 class="card-title">
              {{ project === 'hrss' ? 'Lista Assistiti' : 'Lista Persone fisiche' }} ({{ paginatedResponse.total }})
            </h3>
            <app-collapse-button [label]="'Filtri'" [ref]="'collapseFilters'" class="ms-auto"></app-collapse-button>
          </div>

          <div class="collapse" id="collapseFilters">
            <form (ngSubmit)="onFind(formSearch)" [formGroup]="formSearch" novalidate>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name">Nominativo Cliente</label>
                      <input class="form-control" formControlName="name" id="name" placeholder="Nome" type="text">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input class="form-control" formControlName="email" placeholder="Email" type="text">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="position">Tipo di cliente</label>
                      <ng-select
                        (open)="updatePositions()"
                        formControlName="position_id"
                        id="position">
                        <ng-option *ngFor="let position of positions" [value]="position.id">
                          {{ position.name }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <app-input-errors [element]="formSearch.get('position_id')"></app-input-errors>
                  </div>
                  <div class="col-md-6">
                    <app-select-dictionary [createComponent]="components.SourcesComponent" [dictionary]="'source'" [enableEdit]="false"
                                           [form]="formSearch"
                                           [label]="'Fonte'"></app-select-dictionary>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Data di nascita (intervallo iniziale)</label>
                      <input class="form-control" formControlName="birth_date_from" type="date">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Data di nascita (intervallo finale)</label>
                      <input class="form-control" formControlName="birth_date_to" type="date">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <app-select-dictionary [dictionary]="'contact_type'" [enableEdit]="false" [form]="formSearch"
                                           [label]="'Tipo contatto'"></app-select-dictionary>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <app-select-dictionary [dictionary]="'gender'" [enableEdit]="false" [form]="formSearch"
                                             [label]="'Sesso'"></app-select-dictionary>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Data di assegnazione (intervallo iniziale)</label>
                      <input class="form-control" formControlName="assigned_at_from" type="date">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Data di assegnazione (intervallo finale)</label>
                      <input class="form-control" formControlName="assigned_at_to" type="date">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <app-select-dictionary [createComponent]="components.IncomeTypesComponent" [dictionary]="'income_type'"
                                           [form]="formSearch"
                                           [label]="'Tipologia di Reddito'"></app-select-dictionary>
                  </div>
                  <div class="col">
                    <app-select-dynamic
                      [control]="'user_id'"
                      [form]="formSearch"
                      [label]="'Operatore'"
                      [model]="'user'"
                      [service]="getUserService.bind(this)"
                    >
                      <ng-template #optionTemplate let-element>
                        {{ element.first_name }} {{ element.last_name }}
                      </ng-template>
                    </app-select-dynamic>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-right">
                    <button (click)="clearFilters()" class="btn btn-primary" type="button">Pulisci filtri</button>
                    <button class="btn btn-primary ms-2" type="submit">Cerca</button>
                    <button (click)="export(contactService, null)" class="btn btn-primary ms-2" type="button"><i
                      class="fas fa-download"></i></button>
                  </div>

                </div>
              </div>
            </form>
          </div>

          <app-contact-table
            (clickEdit)="edit($event)"
            (update)="updateList($event)"
            [(elements)]="elements"
            [order]="order"
            [paginatedResponse]="paginatedResponse"
          ></app-contact-table>

        </div>
      </div>
    </div>
  </div>
</section>



