<div class="form-group" [formGroup]="form">
  <label for="{{model}}">{{label}}</label>
  <div class="d-flex">
    <ng-select
      class="server-side-select"
      id="{{model}}"
      bindLabel="{{model}}"
      (open) = "open()"
      (change)="changeValue($event)"
      formControlName="{{control}}"
      [multiple]="multiple"
    >
      <ng-option *ngFor="let element of elements; index as i" [value]="element.id">
        <ng-container
          [ngTemplateOutlet]="optionTemplate || defaultSelected"
          [ngTemplateOutletContext]="{ $implicit: element, index: i}"
        >
        </ng-container>
      </ng-option>
    </ng-select>
  </div>
  <app-input-errors [element]="form.get(control)"></app-input-errors>
</div>

<ng-template #defaultSelected let-element> {{element.first_name}} </ng-template>
