import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {InternalProject} from '../../shared/models/InternalProject';

@Injectable({
  providedIn: 'root'
})
export class InternalProjectService extends DictionaryService<InternalProject> {

  path = 'internal_projects';

}
