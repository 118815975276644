import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-server-side-select',
  templateUrl: './server-side-select.component.html',
  styleUrls: ['./server-side-select.component.css']
})
export class ServerSideSelectComponent implements OnInit {

  @Input() service!: (arg0?: any) => Observable<any>;
  @Input() model = '';
  @Input() control = '';
  @Input() public form!: UntypedFormGroup;
  @Input() label = '';
  @Input() multiple = false;
  @Input() updateOnLoad = true;
  @Input() sortField?: string;
  @ContentChild('optionTemplate', { static: false }) optionTemplate?: TemplateRef<any>;
  @Output() changeSelected = new EventEmitter<any>();

  public elements: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.updateElements();
  }

  updateElements(search: string = '', sortField?: string): void {
    this.service(search)
      .subscribe(result => {
        if (sortField) {
          this.elements = result.data.sort((a: any, b: any) => {
            if (a[sortField] < b[sortField]) return -1;
            if (a[sortField] > b[sortField]) return 1;
            return 0;
          });
        } else {
          this.elements = result.data;
        }
      });
  }  

  open(): void{
    if (this.elements.length === 0) {
      this.updateElements();
    }
  }

  changeValue(element: number): void{
    this.changeSelected.emit(this.elements.find(el => el.id === element));
  }



}
