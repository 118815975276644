import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {PracticeStateNoteType} from '../../shared/models/PracticeStateNoteType';

@Injectable({
  providedIn: 'root'
})
export class PracticeStateNoteTypeService extends DictionaryService<PracticeStateNoteType> {

  path = 'practice_state_note_types';

}
