import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/shared/models/respose/BaseResponse';
import { HttpService } from 'src/app/shared/services/http.service';
import {Mail} from '../../shared/models/Mail';

@Injectable({
  providedIn: 'root'
})
export class SendMailService extends HttpService {

  send(mail: Mail): Observable<BaseResponse<string>>{
    return this.post('send_mail', mail);
  }

}
