import { Component } from '@angular/core';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {IncomeType} from '../../../../../shared/models/IncomeType';
import {IncomeTypeService} from '../../../../services/income-type.service';

@Component({
  selector: 'app-income-types',
  templateUrl: './income-types.component.html',
  styleUrls: ['./income-types.component.css']
})
export class IncomeTypesComponent extends DictionaryComponent<IncomeType> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: IncomeTypeService
  ) {
    super(modalService, fb, service);
  }

}
