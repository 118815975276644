import {Order} from '../Order';
import {User} from '../User';

export class DataRequest<T>{
    data: T | undefined;
    order: Order | undefined;

  constructor(data: T | undefined, order?: Order | undefined){
        this.data = data;
        this.order = order;
    }
}
