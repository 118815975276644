import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {PaginatedDataResponse} from '../../../../shared/models/respose/PaginatedDataResponse';
import {Pagination} from '../../../../shared/models/Pagination';
import {PaginationStorageService} from '../../../../shared/services/pagination-storage.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  page = 1;
  elements = 10;
  @Output() elementsChange = new EventEmitter<number>();
  @Input() paginatedResponse: PaginatedDataResponse<any> = new PaginatedDataResponse<any>(1, [], 1, 100, 100);
  @Output() pageChangeEvent = new EventEmitter<Pagination>();

  constructor(
    private paginationStorageService: PaginationStorageService
  ) { }

  ngOnInit(): void {
    this.elements = this.paginationStorageService.getElements();
    this.elementsChange.emit(this.elements);
  }

  changePage(page: number): void{
    this.page = page;
    this.emitChangePage();
  }

  changeElements(elements: any): void{
    this.paginationStorageService.setElements(elements);
    this.elements = elements;
    this.page = 1;
    this.elementsChange.emit(this.elements);
    this.emitChangePage();
  }

  private emitChangePage(): void{
    this.pageChangeEvent.emit({page: this.page, elements: this.elements});
  }

}
