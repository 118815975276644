<mat-form-field class="tag-list" appearance="fill">
  <mat-label>Tag</mat-label>
  <mat-chip-grid #tagList aria-label="Seleziona tag">
    <mat-chip-row
      *ngFor="let tag of selectedTags"
      (removed)="removeSelectedTag(tag)">
      {{tag.name}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      placeholder="Seleziona Tag..."
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="tagList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    >
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{tag.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
