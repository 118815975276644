<section class="content">
  <div class="container-fluid">

    <form (ngSubmit)="onSubmit(form)" *ngIf=formVisible [formGroup]="form" novalidate>
      <div class="row text-right mb-3">
        <div class="col-md-12">
          <button (click)="showHideForm()" class="btn btn-primary" style="margin-top: 20px;" type="button"><i
            class="fas fa-caret-square-left"></i> Indietro
          </button>
        </div>
      </div>
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title">{{ form.get('id')?.value ? 'Aggiornamento dati' : 'Nuovo' }}</h3>
        </div>
        <input formControlName="id" type="hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Nome</label>
                <input [ngClass]="getInvalidClass('name')" class="form-control" formControlName="name" id="name" placeholder="Nome"
                       type="text">
              </div>
              <app-input-errors [element]="form.get('name')"></app-input-errors>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <app-select-dynamic
                  [control]="'supervisor_id'"
                  [form]="form"
                  [label]="'Referente'"
                  [model]="'supervisor'"
                  [service]="getSupervisorService.bind(this)"
                >
                  <ng-template #optionTemplate let-element>
                    {{ element.first_name }} {{ element.last_name }} ({{ element.email }})
                  </ng-template>
                </app-select-dynamic>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">eMail</label>
                <input [ngClass]="getInvalidClass('email')" autocomplete="off" class="form-control" formControlName="email"
                       i18n-placeholder="eMail" placeholder="eMail" type="email">
                <app-input-errors [element]="form.get('email')"></app-input-errors>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="has_daily_report">Invio report quotidiano sulle presenze</label>
                <ng-select bindLabel="has_daily_report" formControlName="has_daily_report" id="has_daily_report">
                  <ng-option [value]="false">No</ng-option>
                  <ng-option [value]="true">Si</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" type="submit">Salva</button>
      </div>
    </form>

    <div *ngIf=!formVisible class="row mt-2">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex">
            <h3 class="card-title">Elenco Centri di Costo ({{ paginatedResponse.total }})</h3>
            <app-collapse-button [label]="'Filtri'" [ref]="'collapseFilters'" class="ms-auto"></app-collapse-button>
            <button (click)="new()" class="btn btn-success ms-2" type="button"><i class="fas fa-plus-square"></i> Nuovo
            </button>
          </div>

          <div class="collapse" id="collapseFilters">
            <form (ngSubmit)="onFind(formSearch)" [formGroup]="formSearch" novalidate>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name">Nome</label>
                      <input class="form-control" formControlName="name" placeholder="Nome" type="text">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <button (click)="clearFilters()" class="btn btn-primary" type="button">Pulisci filtri</button>
                    <button class="btn btn-primary ms-2" type="submit">Cerca</button>
                  </div>

                </div>
              </div>
            </form>
          </div>

          <!-- /.card-header -->
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>
                  <app-sort-button (orderChange)="updateList({ page: 1 })" [(order)]="order" [column]="'name'"
                                   [label]="'Nome'"></app-sort-button>
                </th>
                <th>
                  <app-sort-button (orderChange)="updateList({ page: 1 })" [(order)]="order" [column]="'email'"
                                   [label]="'eMail'"></app-sort-button>
                </th>
                <th>
                  <app-sort-button (orderChange)="updateList({ page: 1 })" [(order)]="order" [column]="'has_daily_report'"
                                   [label]="'Invio quotidiano report'"></app-sort-button>
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let object of paginatedResponse.data">
                <td>{{ object.name | titlecase }}</td>
                <td>{{ object.email }}</td>
                <td>{{ object.has_daily_report ? 'Si' : 'No' }}</td>
                <td class="text-right">
                  <button (click)="edit(object)" class="btn btn-primary" type="button"><i class="fas fa-edit"></i>
                  </button>
                  <button (click)="openTrashModal(content,object)" class="btn btn-danger ms-2" type="button"><i
                    class="fas fa-trash-alt"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Elimina</h4>
    <button (click)="modal.dismiss('cancel')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Confermi l'eliminazione di questa riga?
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('confirm')" class="btn btn-outline-dark" type="button">Conferma</button>
    <button (click)="modal.dismiss('cancel')" class="btn btn-outline-dark" type="button">Annulla</button>
  </div>
</ng-template>
