<div class="card">
  <div class="card-header">
    <h3 class="card-title">Invia Whatsapp</h3>

  </div>
  <form (ngSubmit)="onSubmit(form)" [formGroup]="form" novalidate>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <strong>Destinatario</strong>
          <p class="text-muted">
            {{ whatsapp }}
          </p>
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Template</label>
          <ng-select
            (change)="changeTemplate($event)"
            bindLabel="name"
          >
            <ng-option *ngFor="let whatsappTemplate of whatsappTemplates"
                       [value]="whatsappTemplate">{{ whatsappTemplate.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-input [controlName]="'subject'" [form]="form" [label]="'Oggetto'"></app-input>
        </div>
      </div>
    </div>
    <div class="card-footer" style="text-align: right">
      <button (click)="closeAllModals()" class="btn btn-secondary me-2" type="button">Annulla</button>
      <button class="btn btn-primary" type="submit">Invia</button>
    </div>
  </form>
</div>
