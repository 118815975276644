import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {UserService} from '../../../../services/user.service';
import {ContactService} from '../../../../services/contact.service';
import {Observable} from 'rxjs';
import {Order} from '../../../../../shared/models/Order';
import {DirectionType} from '../../../../enum/DirectionType';

@Component({
  selector: 'app-company-filters',
  templateUrl: './company-filters.component.html'
})
export class CompanyFiltersComponent implements OnInit {

  formSearch!: UntypedFormGroup;
  @Output() clearFilters = new EventEmitter<void>();
  @Output() search = new EventEmitter<UntypedFormGroup>();
  @Output() export = new EventEmitter<UntypedFormGroup>();

  constructor(
    private fb: UntypedFormBuilder,
    public contactService: ContactService,
    private userService: UserService)
    {}

  ngOnInit(): void {
    this.formSearch = this.fb.group({
      is_company: [true],
      company_name: [''],
      company_activity_id: [''],
      iva: [''],
      fiscal_code: [''],
      company_type: [''],
      cap: [''],
      email: [''],
      work_number: [''],
      nominativo_amministratore: [''],
      fatturato_desercizio_from: [''],
      fatturato_desercizio_to: [''],
      min_activity: [''],
      city_name: [''],
      personnel_number_id: [''],
      azienda_della_pubblica_amministrazione: [''],
      startup_innovativa: [''],
      pmi_innovative: [''],
      company_dimension_id: [''],
      assigned_at_from: [''],
      assigned_at_to: [''],
      user_id: [''],
      referente: ['']
    });
  }

  onSearch(form: UntypedFormGroup): void{
    this.search.emit(form);
  }

  onExport(): void{
    this.export.emit(this.formSearch);
  }

  onClearFilters(): void{
    this.formSearch.reset({is_company: true});
    this.clearFilters.emit();
  }

  getUserService(search: any, id?: number): Observable<any>{
    return this.userService.getPaginatedList(1000, 1,
      {id, name : search}, new Order('name', DirectionType.asc));
  }
}
