<div>
  <div class="modal-content">
    <form (ngSubmit)="onSubmit(form)" [formGroup]="form" novalidate>
      <div class="modal-body">

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label i18n="documento">Documento</label>
              <app-input-file [controlName]="'document'" [fileTypes]="fileTypes" [form]="form"
                              id="document"></app-input-file>
              <span>(Supportati formati <span
                *ngFor="let type of fileTypes">{{ type }} </span>di massimo {{ getTextSize(max_file_size_kb) }})</span>
              <app-input-errors [element]="form.get('document')"></app-input-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-sm btn-primary me-2" type="submit">Carica documento firmato</button>
        <button (click)="activeModal.close(false)"
                class="btn btn-sm btn-danger me-2"
                type="button">Chiudi senza firmare
        </button>
      </div>
    </form>
  </div>
</div>
