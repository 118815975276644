import { Component, OnInit } from '@angular/core';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {DailyActivity} from '../../../../../shared/models/DailyActivity';
import {DailyActivityService} from '../../../../services/daily-activity.service';

@Component({
  selector: 'app-daily-activity-types',
  templateUrl: './daily-activity-types.component.html',
  styleUrls: ['./daily-activity-types.component.css']
})
export class DailyActivityTypesComponent extends DictionaryComponent<DailyActivity> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: DailyActivityService
  ) {
    super(modalService, fb, service);
  }

}
