<div *ngIf="paginatedResponse.data.length">
  <table class="table table-hover text-nowrap">
    <thead>
    <tr>
      <th>Tipo Documento</th>
      <th>Nome Documento</th>
      <th>Firmato</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let document of paginatedResponse.data">
      <td>{{ document?.document_type?.name }}</td>
      <td>{{ document?.original_name }}</td>
      <td>{{ document?.signed ? 'SI' : 'NO' }}</td>
      <td class="text-right">
        <button (click)="openSignFileModal(document)" *ngIf="!document?.signed" class="btn btn-warning ms-2"
                type="button"><i class="fas fa-upload"></i></button>
        <button (click)="openSignModal(document)" *ngIf="!document?.signed" class="btn btn-warning ms-2" type="button">
          <i class="fas fa-pen"></i></button>
        <button (click)="openPdfPreviewModal(pdfPreview,document)" *ngIf="isPdf(document?.original_name ?? '')"
                class="btn btn-success ms-2"
                type="button"><i class="fas fa-search"></i></button>
        <button (click)="download(document)" class="btn btn-primary ms-2" type="button"><i class="fas fa-download"></i>
        </button>
        <button (click)="onSignedDownloadClick(document)" *ngIf="document?.signed" class="btn btn-warning ms-2"
                type="button"><i class="fas fa-download"></i></button>
        <button (click)="openTrashModal(content,document)" class="btn btn-danger ms-2" type="button"><i
          class="fas fa-trash-alt"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
  <app-pagination (elementsChange)="onElementsChange($event)" (pageChangeEvent)="updateList($event)"
                  [paginatedResponse]="paginatedResponse"></app-pagination>
</div>

<div *ngIf="!paginatedResponse.data.length">
  Nessun documento presente
</div>
<hr>
<h3>Allega documenti</h3>
<form (ngSubmit)="onSubmit(form)" [formGroup]="form" novalidate>
  <div class="row">
    <div class="col">
      <app-select-dictionary [createComponent]="components.DocumentTypesComponent" [dictionary]="'document_type'"
                             [enableEdit]="enableDocumentTypeEdit" [form]="form"
                             [label]="'Tipologia Documento'"></app-select-dictionary>
    </div>
    <div class="col">
      <div class="form-group">
        <label i18n="documento">Documento</label>
        <app-input-file-multiple [controlName]="'documents'" [fileTypes]="excelFileTypes.concat(pdfFileTypes)"
                                 [form]="form"
                                 id="document"></app-input-file-multiple>
        <span>(Supportati formati Excel e PDF - massimo {{ getTextSize(max_file_size_kb) }})</span>
        <app-input-errors [element]="form.get('documents')"></app-input-errors>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col text-right">
      <button (click)="downloadPrivacyPolicy()" class="btn btn-success" type="button">Scarica informativa sulla
        privacy
      </button>
      <button class="btn btn-primary ms-2" i18n="pulsante salva" type="submit">Inserisci</button>
    </div>
  </div>
</form>

<ng-template #pdfPreview let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="pulsante elimina" id="modal-pdf-title">Anteprima</h4>
    <button (click)="modal.dismiss('cancel')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="previewLoading" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Caricamento...</span>
      </div>
    </div>
    <pdf-viewer
      (after-load-complete)="loadedPreview($event)"
      [autoresize]="true"
      [external-link-target]="'blank'"
      [fit-to-page]="false"
      [original-size]="false"
      [render-text]="true"
      [rotation]="0"
      [show-all]="true"
      [show-borders]="false"
      [src]="pdfSrc"
      [stick-to-page]="false"
      [zoom-scale]="'page-width'"
      [zoom]="1"
      style="width: 100%; height: 600px;"
    ></pdf-viewer>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss('cancel')" class="btn btn-outline-dark" i18n="pulsante chiudi" type="button">Chiudi
    </button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="pulsante elimina" id="modal-basic-title">Elimina</h4>
    <button (click)="modal.dismiss('cancel')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Confermi l'eliminazione definitiva del file?
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('confirm')" class="btn btn-outline-dark" i18n="pulsante conferma" type="button">
      Conferma
    </button>
    <button (click)="modal.dismiss('cancel')" class="btn btn-outline-dark" i18n="pulsante annulla" type="button">
      Annulla
    </button>
  </div>
</ng-template>
