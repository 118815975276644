import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/shared/models/respose/BaseResponse';
import { HttpService } from 'src/app/shared/services/http.service';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {DocumentRequest} from '../models/request/DocumentRequest';
import {Document} from '../../shared/models/Document';
import {Curriculum} from '../../shared/models/Curriculum';
import {DocumentSignRequest} from '../models/request/DocumentSignRequest';
import {DocumentService} from './document.service';
import {DocumentMultipleRequest} from '../models/request/DocumentMultipleRequest';

@Injectable({
  providedIn: 'root'
})
export class DocumentMultipleService extends DocumentService {

  register(request: DocumentMultipleRequest): Observable<BaseResponse<Document>>{
    return this.post('documents/store/multiple', request);
  }

}
