import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfigurationEmailTemplateService} from '../../../services/configuration-email-template.service';
import {ConfigurationEmailTemplate} from '../../../../shared/models/ConfigurationEmailTemplate';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {SendMailService} from '../../../services/send-mail.service';
import {Router} from '@angular/router';
import {DocumentService} from '../../../services/document.service';
import {Document} from '../../../../shared/models/Document';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Inserisci il testo...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'heading',
        'fontName',
        'backgroundColor',
        'link',
        'unlink',
        'insertVideo',
        'insertHorizontalRule',
      ]
    ],
  };

  emailTemplates?: ConfigurationEmailTemplate[];

  @Input() email = '';
  @Input() mittente = '';
  public form!: UntypedFormGroup;

  constructor(
    protected fb: UntypedFormBuilder,
    private configurationEmailTemplateService: ConfigurationEmailTemplateService,
    private sendMailService: SendMailService,
    private modalService: NgbModal,
    private router: Router,
    private documentService: DocumentService
  ) {
  }

  get templateAttachments(): UntypedFormArray {
    return this.form.controls.templateAttachments as UntypedFormArray;
  }

  ngOnInit(): void {

    this.configurationEmailTemplateService.getAll().subscribe(result => this.emailTemplates = result.data);

    this.form = this.fb.group({
      recipient: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
      sender: ['', [Validators.required]],
      attachments: this.fb.array([]),
      templateAttachments: this.fb.array([]),
    });

    this.form.patchValue({
      recipient: this.email,
      sender: this.mittente
    });
  }

  onSubmit(form: UntypedFormGroup): void {
    if (form.valid) {
      this.sendMailService.send(form.value).subscribe(result => this.router.navigate(['/admin/mail-logs']));
    }
    this.form.markAllAsTouched();
    this.modalService.dismissAll();
  }

  addTemplateAttachment(document: Document): void {
    const templateAttachmentForm = this.fb.group({
      id: [document.id, Validators.required],
      original_name: [document.original_name, Validators.required],
      selected: [true],
    });
    this.templateAttachments.push(templateAttachmentForm);
  }

  changeTemplate(emailTemplate: ConfigurationEmailTemplate): void {

    this.documentService
      .getPaginatedList(100, 1, {related_id: emailTemplate.id, related_type: 'App\\Models\\ConfigurationEmailTemplate'})
      .subscribe(result => {
        this.templateAttachments.clear();
        result.data.data.forEach(attachment => this.addTemplateAttachment(attachment));
      });

    this.form.patchValue({
      subject: emailTemplate.subject,
      body: emailTemplate.body
    });
  }

  closeAllModals(): void {
    this.modalService.dismissAll();
  }


}
