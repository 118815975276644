import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {PracticeType} from '../../shared/models/PracticeType';

@Injectable({
  providedIn: 'root'
})
export class PracticeTypeService extends DictionaryService<PracticeType> {
  path = 'practice_types';
}
