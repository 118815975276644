<section class="content">
  <div class="container-fluid">

    <form (ngSubmit)="onSubmit(form)" *ngIf=formVisible [formGroup]="form" novalidate>
      <div class="row text-right mb-3">
        <div class="col-md-12">
          <br>
          <button (click)="showHideForm()" class="btn btn-primary" i18n="pulsante indietro" type="button"><i
            class="fas fa-caret-square-left"></i> Indietro
          </button>
        </div>
      </div>
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title" i18n="titolo nuovo">{{ form.get('id')?.value ? 'Aggiornamento dati' : 'Nuovo' }}</h3>
        </div>
        <input formControlName="id" type="hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="name" i18n="nome form creazione offerte">Nome</label>
                <input [ngClass]="getInvalidClass('name')" class="form-control" formControlName="name"
                       i18n-placeholder="placeholder nome form creazione offerte" placeholder="Nome"
                       type="text">
              </div>
              <app-input-errors [element]="form.get('name')"></app-input-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" i18n="pulsante salva" type="submit">Salva</button>
      </div>
    </form>


    <div *ngIf=!formVisible class="row mt-2">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex">
            <h3 class="card-title" i18n="titolo tabella utenti">Lista titoli di studio ({{ paginatedResponse.total }}
              )</h3>
            <app-collapse-button [label]="'Filtri'" [ref]="'collapseFilters'" class="ms-auto"></app-collapse-button>
            <button (click)="new()" class="btn btn-success ms-2" i18n="pulsante nuovo" type="button"><i
              class="fas fa-plus-square"></i> Nuovo
            </button>
          </div>

          <div class="collapse" id="collapseFilters">
            <form (ngSubmit)="onFind(formSearch)" [formGroup]="formSearch" novalidate>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name" i18n="nome form filtri offerte">Nome</label>
                      <input class="form-control" formControlName="name" i18n-placeholder="placeholder nome filtri offerte"
                             placeholder="Nome" type="text">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <button (click)="clearFilters()" class="btn btn-primary" i18n="pulsante pulisci filtri"
                            type="button">Pulisci filtri
                    </button>
                    <button class="btn btn-primary ms-2" i18n="pulsante cerca" type="submit">Cerca</button>
                  </div>

                </div>
              </div>
            </form>
          </div>


          <!-- /.card-header -->
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th i18n="nome tabella offerte">
                  <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'name'"
                                   [label]="'Nome'"></app-sort-button>
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let studyCertificate of paginatedResponse.data">
                <td>{{ studyCertificate.name | titlecase }}</td>
                <td class="text-right">
                  <button (click)="edit(studyCertificate)" class="btn btn-primary" type="button"><i
                    class="fas fa-edit"></i></button>
                  <button (click)="openTrashModal(content,studyCertificate)" class="btn btn-danger ms-2" type="button">
                    <i class="fas fa-trash-alt"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">

            <app-pagination (elementsChange)="onElementsChange($event)" (pageChangeEvent)="updateList($event)"
                            [paginatedResponse]="paginatedResponse"></app-pagination>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="pulsante elimina" id="modal-basic-title">Elimina</h4>
    <button (click)="modal.dismiss('cancel')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Confermi l'eliminazione del titolo?
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('confirm')" class="btn btn-outline-dark" i18n="pulsante conferma" type="button">
      Conferma
    </button>
    <button (click)="modal.dismiss('cancel')" class="btn btn-outline-dark" i18n="pulsante annulla" type="button">
      Annulla
    </button>
  </div>
</ng-template>


