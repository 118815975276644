import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {CrudComponent} from '../../../../shared/components/crud/crud.component';
import {PaginatedDataResponse} from '../../../../shared/models/respose/PaginatedDataResponse';
import {BaseResponse} from '../../../../shared/models/respose/BaseResponse';
import {DictionaryService} from '../../../../shared/services/dictionary.service';
import {Dictionary} from '../../../../shared/models/Dictionary';
import {Order} from '../../../../shared/models/Order';
import {DirectionType} from '../../../enum/DirectionType';
import {Pagination} from '../../../../shared/models/Pagination';

@Component({template: ''})
export abstract class DictionaryComponent<ModelType extends Dictionary> extends CrudComponent implements OnInit{
  public paginatedResponse: PaginatedDataResponse<ModelType> = new PaginatedDataResponse<ModelType>(0, [], 0, 0, 0);

  constructor(
    protected modalService: NgbModal,
    protected fb: UntypedFormBuilder,
    protected service: DictionaryService<ModelType>
  ) {
    super(modalService, fb);
  }

  ngOnInit(): void {
    this.order = new Order('name', DirectionType.asc);
    this.form = this.fb.group({
      id: [null],
      name: ['', [Validators.required]]
    });
    this.formSearch = this.fb.group({
      name: ['']
    });
  }

  onSubmit({value, valid}: { value: ModelType, valid: boolean }): void {
    if (valid) {
      if (value.id) {
        this.callUpdate(value);
      } else {
        this.callRegister(value);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  onFind({value, valid}: { value: any, valid: boolean }): void {
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void{
    const object: ModelType = this.formSearch.value;
    this.service.getPaginatedList(pagination.elements, pagination.page, object, this.order).subscribe(
      (response: BaseResponse<PaginatedDataResponse<ModelType>>) => {
        this.paginatedResponse = response.data;
      }
    );
  }

  openTrashModal(content: TemplateRef<any>, object: ModelType): void {
    this.modalService.open(content, {ariaLabelledBy: 'appointment-detail-modal-basic-title'}).result.then((result) => {
      this.service.deleteItem(object.id).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  edit(object: ModelType): void {
    this.form.patchValue(object);
    this.showHideForm();
  }

  callRegister(object: ModelType): void {
    this.service.register(object).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }

  callUpdate(object: ModelType): void {
    this.service.updateItem(object, object.id).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }
}
