import {Component, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {CrudComponent} from '../../../../shared/components/crud/crud.component';
import {BaseResponse} from '../../../../shared/models/respose/BaseResponse';
import {PaginatedDataResponse} from '../../../../shared/models/respose/PaginatedDataResponse';
import {StudyCertificateService} from '../../../services/study-certificate.service';
import {StudyCertificate} from '../../../../shared/models/StudyCertificate';
import {Order} from '../../../../shared/models/Order';
import {DirectionType} from '../../../enum/DirectionType';
import {Pagination} from '../../../../shared/models/Pagination';

@Component({
  selector: 'app-study-certificates',
  templateUrl: './studyCertificates.component.html',
  styleUrls: ['./studyCertificates.component.css']
})
export class StudyCertificatesComponent extends CrudComponent {
  public paginatedResponse: PaginatedDataResponse<StudyCertificate> = new PaginatedDataResponse<StudyCertificate>(0, [], 0, 0, 0);

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private service: StudyCertificateService
  ) {
    super(modalService, fb);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.order = new Order('name', DirectionType.asc);
    this.form = this.fb.group({
      id: [null],
      name: ['', [Validators.required]]
    });
    this.formSearch = this.fb.group({
      name: ['']
    });
    this.updateList({page: 1, elements: this.elements});
  }

  onSubmit({value, valid}: { value: StudyCertificate, valid: boolean }): void {
    if (valid) {
      if (value.id) {
        this.callUpdate(value);
      } else {
        this.callRegister(value);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  onFind({value, valid}: { value: any, valid: boolean }): void {
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void{
    const studyCertificate: StudyCertificate = this.formSearch.value;
    this.service.getPaginatedList(pagination.elements, pagination.page, studyCertificate, this.order).subscribe(
      (response: BaseResponse<PaginatedDataResponse<StudyCertificate>>) => {
        this.paginatedResponse = response.data;
      }
    );
  }

  openTrashModal(content: TemplateRef<any>, studyCertificate: StudyCertificate): void {
    this.modalService.open(content, {ariaLabelledBy: 'appointment-detail-modal-basic-title'}).result.then((result) => {
      this.service.deleteItem(studyCertificate.id).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  edit(studyCertificate: StudyCertificate): void {
    this.form.setValue(
      {
        id: studyCertificate.id,
        name: studyCertificate.name
      }
    );
    this.showHideForm();
  }

  callRegister(studyCertificate: StudyCertificate): void {
    this.service.register(studyCertificate).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }

  callUpdate(studyCertificate: StudyCertificate): void {
    this.service.updateItem(studyCertificate, studyCertificate.id).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }
}
