import {Component, OnInit} from '@angular/core';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DocumentType} from '../../../../../shared/models/DocumentType';
import {DocumentTypeService} from '../../../../services/document-type.service';
import {Order} from '../../../../../shared/models/Order';
import {DirectionType} from '../../../../enum/DirectionType';

@Component({
  selector: 'app-document-types',
  templateUrl: './document-types.component.html',
  styleUrls: ['./document-types.component.css']
})
export class DocumentTypesComponent extends DictionaryComponent<DocumentType> implements OnInit {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: DocumentTypeService
  ) {
    super(modalService, fb, service);
  }

  ngOnInit(): void {
    this.order = new Order('name', DirectionType.asc);
    this.form = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      area: ['']
    });
    this.formSearch = this.fb.group({
      area: [''],
      name: ['']
    });
    this.updateList({page: 1, elements: this.elements});
  }

  onSubmit({ value, valid }: { value: DocumentType, valid: boolean }): void{
    if (valid){
      if (value.id){
        this.callUpdate(value);
      }
      else{
        this.callRegister(value);
      }
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  onFind({value, valid}: { value: any, valid: boolean }): void {
    this.updateList({page: 1, elements: this.elements});
  }

}
