<div class="modal-header">
  <h3 class="card-title">Invia Email</h3>

</div>
<form (ngSubmit)="onSubmit(form)" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <app-input [controlName]="'sender'" [form]="form" [label]="'Mittente'" [readonly]="true"></app-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-input [controlName]="'recipient'" [form]="form" [label]="'Destinatario'"></app-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>Template</label>
        <ng-select
          (change)="changeTemplate($event)"
          bindLabel="name"
        >
          <ng-option *ngFor="let emailTemplate of emailTemplates" [value]="emailTemplate">{{ emailTemplate.name }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-input [controlName]="'subject'" [form]="form" [label]="'Oggetto'"></app-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Corpo del messaggio</label>
          <angular-editor [config]="editorConfig" formControlName="body" id="body"></angular-editor>
          <app-input-errors [element]="form.get('body')"></app-input-errors>
        </div>
      </div>
    </div>
    <app-template-attachments [form]="form"></app-template-attachments>
    <app-attachments [form]="form" [title]="'Allegati aggiuntivi'"></app-attachments>
  </div>
  <div class="modal-footer" style="text-align: right">
    <button (click)="closeAllModals()" class="btn btn-secondary me-2" type="button">Annulla</button>
    <button class="btn btn-primary" type="submit">Invia</button>
  </div>
</form>

