import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {ConfigurationEmailTemplate} from '../../shared/models/ConfigurationEmailTemplate';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationEmailTemplateService extends DictionaryService<ConfigurationEmailTemplate> {
  path = 'configuration_email_templates';
}
