import {Order} from '../Order';

export class PaginatedDataRequest<T> {
  entries?: number;
  page?: number;
  data?: T | undefined;
  order?: Order | undefined;
  relations?: string[];

  constructor(entries?: number, page?: number, data?: T | undefined, order?: Order | undefined, relations?: string[]) {
    this.entries = entries;
    this.page = page;
    this.data = data;
    this.order = order;
    this.relations = relations;
  }
}
