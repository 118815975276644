import {DirectionType} from '../../admin/enum/DirectionType';

export class Order{
  constructor(attribute: string, direction: DirectionType) {
    this.attribute = attribute;
    this.direction = direction;
  }
    attribute = '';
    direction: DirectionType = DirectionType.asc;

    thenBy(attribute: string, direction: DirectionType): Order {
      return new Order(attribute, direction);
  }
}
