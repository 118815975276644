import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {ContactType} from '../../shared/models/ContactType';

@Injectable({
  providedIn: 'root'
})
export class SchedulableTypeService extends HttpService {

  getAll(): Observable<BaseResponse<ContactType[]>>{
    return this.get('schedulable_types');
  }
}
