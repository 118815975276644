import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {ScheduleEvent} from "../../shared/models/ScheduleEvent";

@Injectable({
  providedIn: 'root'
})
export class ScheduleEventService extends DictionaryService<ScheduleEvent> {

  path = 'schedule_events';


}
