import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseResponse} from 'src/app/shared/models/respose/BaseResponse';
import {HttpService} from 'src/app/shared/services/http.service';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {Practice} from '../../shared/models/Practice';
import {PracticeRequest} from '../models/request/PracticeRequest';

@Injectable({
  providedIn: 'root'
})
export class PracticeService extends HttpService {

  getPaginatedList(entries: number,
                   page: number,
                   data?: PracticeRequest,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<Practice>>>{
    const request = new PaginatedDataRequest<PracticeRequest>(entries, page, data, order);
    return this.post('practices', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Practice): Observable<BaseResponse<Practice>>{
    return this.post('practices/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Practice>>{
    return this.delete('practices', id);
  }

  updateItem(request: PracticeRequest, id: number): Observable<BaseResponse<Practice>>{
    return this.put('practices', request, id);
  }

  getItem(id: number): Observable<BaseResponse<Practice>>{
    return this.get('practices/' + id);
  }

  getContact(id: number): Observable<number> {
    return this.get('practices/contact/' + id);
  }

}
