import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewChild } from '@angular/core';
import {FileConstants} from '../../constants/FileConstants';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.css']
})
export class InputFileComponent implements OnInit {

  @Input() public form: UntypedFormGroup | undefined;
  @Input() public fileTypes: string[];
  @Input() public controlName = '';
  @Input() public controlFileName = '';
  @Input() filePath = '';

  @ViewChild('fileUpload', {static: false}) inputVariable: ElementRef | undefined;

  @Input() public maxFileSizeKb = environment.max_file_size_kb;

  constructor(public toastService: ToastService) {
    this.fileTypes = FileConstants.imageExtensions.concat(FileConstants.documentExtension.concat(FileConstants.pdfExtension.concat(FileConstants.excelExtensions)));
  }

  ngOnInit(): void {
  }

  checkExt(event: any): boolean{
    const regex = new RegExp('([a-zA-Z0-9\s_\\.\-:])+(' +
      this.fileTypes.join('|')
      + ')$');
    if (!regex.test(event.type.toLowerCase()) && !regex.test(event.name.toLowerCase())) {
        return false;
    }
    return true;
  }

  checkSize(event: any): boolean{
    const file_size_byte: number = event.size;
    const max_size_byte = this.maxFileSizeKb * 1024;
    return file_size_byte < max_size_byte;
  }

  getTextSize(kb_size: number): string{
    if (kb_size > 1024){
      return kb_size / 1024 + ' mb';
    }
    return kb_size + ' kb';
  }

  onFileChange(event: any): void{
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      if (!this.checkSize(event.target.files[0])){
        this.filePath = '';
        this.form?.patchValue({
          [this.controlName]: ''
        });
        if (this.controlFileName !== '') {
          this.form?.patchValue({
            [this.controlFileName]: ''
          });
        }

        this.toastService.show('Attenzione! Il file è superiore a ' + this.getTextSize(this.maxFileSizeKb) + '!',
          {class: 'bg-warning text-light', delay: 3000});
        return;
      }

      if (this.checkExt(event.target.files[0])){
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.filePath = file.name;
          this.form?.patchValue({
            [this.controlName]: reader.result
          });
          if (this.controlFileName !== '') {
            this.form?.patchValue({
              [this.controlFileName]: file.name
            });
          }
        };
      }
      else {
        this.filePath = '';
        this.form?.patchValue({
          [this.controlName]: ''
        });
        if (this.controlFileName !== '') {
          this.form?.patchValue({
            [this.controlFileName]: ''
          });
        }
        this.toastService.show('Attenzione! Formato file errato!', {class: 'bg-warning text-light', delay: 3000});
      }
    }
  }

}
