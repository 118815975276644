import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {PracticeState} from '../../shared/models/PracticeState';
import {ContractType} from '../../shared/models/ContractType';

@Injectable({
  providedIn: 'root'
})
export class ContractTypeService extends DictionaryService<ContractType> {

  path = 'contract_types';

}
