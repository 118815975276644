import {ContactService} from '../../admin/services/contact.service';
import {PracticeService} from '../../admin/services/practice.service';
import {DocumentService} from '../../admin/services/document.service';
import {DocumentOtpService} from '../../otp/services/document-otp.service';
import {DocumentMultipleService} from '../../admin/services/document-multiple.service';

export const ModelListConstants = {
  contact: ContactService,
  practice: PracticeService,
  document: DocumentService,
  documentOtp: DocumentOtpService,
  documentMultiple: DocumentMultipleService
};
