import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfigurationWhatsappTemplateService} from '../../../services/configuration-whatsapp-template.service';
import {ConfigurationWhatsappTemplate} from '../../../../shared/models/ConfigurationWhatsappTemplate';
import {Router} from '@angular/router';

@Component({
  selector: 'app-send-whatsapp',
  templateUrl: './send-whatsapp.component.html',
  styleUrls: ['./send-whatsapp.component.css']
})
export class SendWhatsappComponent implements OnInit {

  whatsappTemplates?: ConfigurationWhatsappTemplate[];

  @Input() whatsapp = '';
  @Input() mittente = '';
  public form!: UntypedFormGroup;

  constructor(
    protected fb: UntypedFormBuilder,
    private configurationWhatsappTemplateService: ConfigurationWhatsappTemplateService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.configurationWhatsappTemplateService.getAll().subscribe(result => this.whatsappTemplates = result.data);

    this.form = this.fb.group({
      recipient: [''],
      subject: ['', [Validators.required]],
      sender: ['']
    });

    this.form.patchValue({
      recipient: this.whatsapp,
      sender: this.mittente
    });
  }

  onSubmit(form: UntypedFormGroup): void {
    if (form && form.valid) {
      const subjectControl = this.form.get("subject");
      if (subjectControl) {
        const subjectValue = subjectControl.value ?? '';
        const URL = 'https://api.whatsapp.com/send?phone=' + this.whatsapp + '&text=' + encodeURIComponent(subjectValue);
        window.open(URL);
      }
    }
    this.form.markAllAsTouched();
    this.modalService.dismissAll();
  }
  
  changeTemplate(whatsappTemplate: ConfigurationWhatsappTemplate): void{
    this.form.patchValue({
      subject: whatsappTemplate.subject,
    });
  }

  closeAllModals() {
    this.modalService.dismissAll();
  }

}
