import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {CrudComponent} from '../../../../../shared/components/crud/crud.component';
import {BaseResponse} from '../../../../../shared/models/respose/BaseResponse';
import {PaginatedDataResponse} from '../../../../../shared/models/respose/PaginatedDataResponse';
import {Position} from '../../../../../shared/models/Position';
import {PositionService} from '../../../../services/position.service';
import {Pagination} from '../../../../../shared/models/Pagination';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.css']
})
export class PositionsComponent extends CrudComponent {
  public paginatedResponse: PaginatedDataResponse<Position> = new PaginatedDataResponse<Position>(0, [], 0, 0, 0);

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private service: PositionService
  ) {
    super(modalService, fb);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      name: ['', [Validators.required]]
    });
    this.formSearch = this.fb.group({
      name: ['']
    });
    this.updateList({page: 1, elements: this.elements});
  }

  onSubmit({value, valid}: { value: Position, valid: boolean }): void {
    if (valid) {
      if (value.id) {
        this.callUpdate(value);
      } else {
        this.callRegister(value);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  onFind({value, valid}: { value: any, valid: boolean }): void {
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void{
    const position: Position = this.formSearch.value;
    this.service.getPaginatedList(pagination.elements, pagination.page, position, this.order).subscribe(
      (response: BaseResponse<PaginatedDataResponse<Position>>) => {
        this.paginatedResponse = response.data;
      }
    );
  }

  openTrashModal(content: TemplateRef<any>, position: Position): void {
    this.modalService.open(content, {ariaLabelledBy: 'appointment-detail-modal-basic-title'}).result.then((result) => {
      this.service.deleteItem(position.id).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  edit(position: Position): void {
    this.form.setValue(
      {
        id: position.id,
        name: position.name
      }
    );
    this.showHideForm();
  }

  callRegister(position: Position): void {
    this.service.register(position).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }

  callUpdate(position: Position): void {
    this.service.updateItem(position, position.id).subscribe(response => {
      this.updateList({page: 1, elements: this.elements});
      this.showHideForm();
    });
  }
}
