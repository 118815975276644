import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {PracticeState} from '../../../../../shared/models/PracticeState';
import {PracticeStateService} from '../../../../services/practice-state.service';
import {CustomValidationService} from '../../../../../shared/services/custom-validation.service';

@Component({
  selector: 'app-practice-states',
  templateUrl: './practice-states.component.html',
  styleUrls: ['./practice-states.component.css']
})
export class PracticeStatesComponent extends DictionaryComponent<PracticeState> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: PracticeStateService,
    public validationService: CustomValidationService
  ) {
    super(modalService, fb, service);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      name: ['', [Validators.required]],
      with_calendar: ['', [Validators.required]],
      send_mail: ['', [Validators.required]],
      mail_text: [''],
      whatsapp_text: [''],
      send_whatsapp: ['', [Validators.required]],
      email_list: ['', [this.validationService.listEmailValidator]]
    });
    this.formSearch = this.fb.group({
      name: ['']
    });
    this.updateList({page: 1, elements: this.elements});
  }

  new(): void{
    this.form.reset({with_calendar: 0});
    this.showHideTable();
    this.showHideForm();
  }

  changeSendMail(sendMail: number): void{
    sendMail === 1
      ? this.form.get('mail_text')?.addValidators(Validators.required)
      : this.form.get('mail_text')?.removeValidators(Validators.required);
  }

  changeSendWhatsapp(sendWhatsapp: number): void{
    sendWhatsapp === 1
      ? this.form.get('whatsapp_text')?.addValidators(Validators.required)
      : this.form.get('whatsapp_text')?.removeValidators(Validators.required);
  }

}
