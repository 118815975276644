import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../shared/models/Contact';
import {ConfigurationService} from '../../../../services/configuration.service';
import {ConfigurationType} from '../../../../../shared/enum/ConfigurationType';
import {SendMailModalService} from '../../../../services/sendMailModal.service';
import {SendWhatsappModalService} from '../../../../services/sendWhatsappModal.service';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-contact-profile',
  templateUrl: './contact-profile.component.html',
  styleUrls: ['./contact-profile.component.css']
})
export class ContactProfileComponent implements OnInit {

  public whatsappMessage = "";
  @Input() contact?: Contact;
  @Output() clickTelephone = new EventEmitter<void>();
  companyFieldsVisible = false;

  constructor(
    private configurationService: ConfigurationService,
    private sendMailModalService: SendMailModalService,
    private sendWhatsappModalService: SendWhatsappModalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.companyFieldsVisible = (this.contact?.is_company || false);
    this.configurationService.getConfiguration(ConfigurationType.whatsapp).subscribe(
      result => this.whatsappMessage = result.data.attributes?.message ?? ''
    );
  }

  openModalSendMail(): void {
    const contactEmail = this.contact?.email ?? '';
    const userId = this.contact?.user_id;

    if (userId) {
      this.userService.getItem(userId).subscribe((response) => {
        if (response.data) {
          const user = response.data; // Ora hai accesso ai dati dell'utente
          const userIdEmail = user.email;
          if (userIdEmail) {
            this.sendMailModalService.openModal(contactEmail, userIdEmail);
          } else {
            this.sendMailModalService.openModal(contactEmail, '');
          }
        } else {
          this.sendMailModalService.openModal(contactEmail, '');
        }
      });
    } else {
      this.sendMailModalService.openModal(contactEmail, '');
    }
  }

  openModalSendWhatsapp(numero: string): void {
    const contactWhatsapp = this.trasformaNumeroDiTelefono(numero ?? '');
    this.sendWhatsappModalService.openModal(contactWhatsapp, '');
  }

  trasformaNumeroDiTelefono(numero: any): string {
    if (numero === null) {
      return "";
    }
    // Rimuovi "+39" o "0039" se presenti all'inizio
    numero = numero.replace(/^\+?39|^0039/g, '');

    // Aggiungi "39" davanti al numero
    numero = '39' + numero;

    return numero;
  }

}
