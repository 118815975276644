import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Dictionary} from '../../models/Dictionary';
import {DocumentType} from './../../models/DocumentType';
import {DictionaryListConstants} from '../../constants/DictionaryListConstants';
import {UntypedFormGroup} from '@angular/forms';
import {DictionaryModalService} from '../../../admin/services/dictionaryModal.service';

type ElementType = Dictionary | DocumentType;

@Component({
  selector: 'app-select-dictionary',
  templateUrl: './select-dictionary.component.html',
  styleUrls: ['./select-dictionary.component.css']
})

export class SelectDictionaryComponent implements OnInit {

  @Input() enableEdit = true;
  @Input() label = '';
  @Input() dictionary = '';
  @Input() control = '';
  @Input() multiple = false;
  @Input() public form!: UntypedFormGroup;
  @Input() createComponent: any;
  @Input() readonly = false;
  @Input() area? = '';
  @Output() changeSelected = new EventEmitter<any>();

  private service: any;
  elements: ElementType[] = [];

  constructor(
    private injector: Injector,
    private dictionaryModalService: DictionaryModalService
  ) {
  }

  ngOnInit(): void {
    this.service = this.injector.get<any>(DictionaryListConstants[this.dictionary as keyof typeof DictionaryListConstants]);
    if (this.control === ''){
      this.control = this.dictionary + '_id';
    }
    this.updateElements();
  }

  open(): void{
    if (this.elements.length === 0) {
        this.updateElements();
    }
  }

 updateElements(): void {
    this.service.getAll().subscribe((result: any) => {
      if (this.area) {
        // Filtra gli elementi in base all'area specificata
        this.elements = result.data.filter((el: { area: string; }) => el.area === this.area);
      } else {
        // Senza specificare l'area, si presume che gli elementi siano di tipo Dictionary
        this.elements = result.data;
      }
    });
  }

  create(): void {
    this.dictionaryModalService.openModal(this.createComponent).result.then(() => {
    }, () => {
      this.updateElements();
    });
  }

  changeValue(element: number): void{
    const selectedElement = this.elements.find(el => el.id === element);
    if (selectedElement) {
      this.changeSelected.emit(selectedElement);
    }
  }

}
