<div [formGroup]="form" class="form-group">
  <label for="{{dictionary}}">{{ label }}</label>
  <div class="d-flex">
    <ng-select
      (change)="changeValue($event)"
      (open)="open()"
      [multiple]="multiple"
      [readonly]="readonly"
      bindLabel="{{dictionary}}"
      class="select-dictionary"
      formControlName="{{control}}"
      id="{{dictionary}}"
    >
      <ng-option *ngFor="let element of elements" [value]="element.id">
        {{ element.name }}
      </ng-option>
    </ng-select>
    <button (click)="create()" *ngIf="enableEdit" class="btn btn-primary btn-sm ms-2" type="button"><i
      class="fas fa-edit"></i></button>
  </div>
  <app-input-errors [element]="form.get(control)"></app-input-errors>
</div>
