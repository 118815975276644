import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendWhatsappComponent} from '../components/utility/send-whatsapp/send-whatsapp.component';

@Injectable({
  providedIn: 'root'
})
export class SendWhatsappModalService {

  constructor(private modalService: NgbModal) { }

  openModal(whatsapp: string, mittente: string): any{
    const modal = this.modalService.open(SendWhatsappComponent, {centered: true, size: 'xl'});
    modal.componentInstance.whatsapp = whatsapp;
    modal.componentInstance.mittente = mittente;
    return modal;
  }
}
