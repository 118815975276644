<section class="content">
  <div class="container-fluid">
    <form (ngSubmit)="onSubmit(form)" *ngIf="formVisible" [formGroup]="form" novalidate>
      <div class="row text-right mb-3">
        <div class="col-md-12">
          <br>
          <button (click)="showHideForm()" class="btn btn-primary" i18n="pulsante indietro" type="button"><i
            class="fas fa-caret-square-left"></i> Indietro
          </button>
        </div>
      </div>
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title" i18n="titolo nuovo">{{ form.get('id')?.value ? 'Aggiornamento dati' : 'Nuovo' }}</h3>
        </div>
        <input formControlName="id" type="hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name" i18n="nome form scadenziario eventi">Nome</label>
                <input [ngClass]="getInvalidClass('name')" [readOnly]="form.get('is_system')?.value" class="form-control" formControlName="name"
                       id="name" placeholder="Nome" type="text">
              </div>
              <app-input-errors [element]="form.get('name')"></app-input-errors>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="periodicity">Periodicità</label>
                <input [ngClass]="getInvalidClass('periodicity')" class="form-control" formControlName="periodicity" id="periodicity"
                       min="1" placeholder="Periodicità"
                       type="number">
              </div>
              <app-input-errors [element]="form.get('periodicity')"></app-input-errors>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="monthly_frequency">Frequenza mensile (0 = no frequenza)</label>
                <input [ngClass]="getInvalidClass('monthly_frequency')" class="form-control" formControlName="monthly_frequency" id="monthly_frequency" max="12"
                       min="0" placeholder="Freq. mensile"
                       type="number">
              </div>
              <app-input-errors [element]="form.get('monthly_frequency')"></app-input-errors>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-input [controlName]="'expiration_days'" [form]="form"
                         [label]="'Quanti giorni prima inviare la notifica'" [type]="'number'"></app-input>
            </div>
            <div class="col">
              <app-input [controlName]="'after_expiration_days'" [form]="form"
                         [label]="'Per quanti giorni inviare la notifica dopo la scadenza'" [type]="'number'"></app-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="notify_client" i18n="notifica esterna per scadenziario eventi">Notifica esterna</label>
                <ng-select (change)="onNotifyClientChange($event)" bindLabel="notify_client" formControlName="notify_client"
                           id="notify_client">
                  <ng-option [value]="false">No</ng-option>
                  <ng-option [value]="true">Si</ng-option>
                </ng-select>
              </div>
              <app-input-errors [element]="form.get('notify_client')"></app-input-errors>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Oggetto email esterna</label>
                <input [attr.disabled]="isDisabled('notify_client')" [ngClass]="!isDisabled('notify_client') ? getInvalidClass('client_mail_subject') : ''" class="form-control"
                       formControlName="client_mail_subject"
                       placeholder="Oggetto email esterna" type="text">
              </div>
              <app-input-errors *ngIf="!isDisabled('notify_client')"
                                [element]="form.get('client_mail_subject')"></app-input-errors>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="client_mail_text" i18n="client_mail_text form scadenziario eventi">Wording email
                  esterna</label>
                <textarea [attr.disabled]="isDisabled('notify_client')" [ngClass]="!isDisabled('notify_client') ? getInvalidClass('client_mail_text') : ''" class="form-control"
                          formControlName="client_mail_text"
                          i18n-placeholder="placeholder client_mail_text form creazione offerte"
                          id="client_mail_text"
                          placeholder="Wording email esterna" rows="5"></textarea>
              </div>
              <app-input-errors *ngIf="!isDisabled('notify_client')"
                                [element]="form.get('client_mail_text')"></app-input-errors>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="notify_operator" i18n="notifica interna da scadenziario eventi">Notifica interna</label>
                <ng-select (change)="onNotifyOperatorChange($event)" bindLabel="notify_operator" formControlName="notify_operator"
                           id="notify_operator">
                  <ng-option [value]="false">No</ng-option>
                  <ng-option [value]="true">Si</ng-option>
                </ng-select>
              </div>
              <app-input-errors [element]="form.get('notify_operator')"></app-input-errors>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Oggetto email interna</label>
                <input [attr.disabled]="isDisabled('notify_operator')" [ngClass]="!isDisabled('notify_operator') ? getInvalidClass('operator_mail_subject') : ''" class="form-control"
                       formControlName="operator_mail_subject"
                       placeholder="Oggetto email operatore" type="text">
              </div>
              <app-input-errors *ngIf="!isDisabled('notify_operator')"
                                [element]="form.get('operator_mail_subject')"></app-input-errors>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="operator_mail_text" i18n="operator_mail_text form scadenziario eventi">Wording email
                  interna</label>
                <textarea [attr.disabled]="isDisabled('notify_operator')" [ngClass]="!isDisabled('notify_operator') ? getInvalidClass('operator_mail_text') : ''" class="form-control"
                          formControlName="operator_mail_text"
                          i18n-placeholder="placeholder operator_mail_text form creazione offerte"
                          id="operator_mail_text"
                          placeholder="Wording email operatore" rows="5"></textarea>
              </div>
              <app-input-errors *ngIf="!isDisabled('notify_operator')"
                                [element]="form.get('operator_mail_text')"></app-input-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" type="submit">Salva</button>
      </div>
    </form>

    <div *ngIf="!formVisible" class="row mt-2">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex">
            <h3 class="card-title" i18n="titolo tabella utenti">Elenco eventi ({{ paginatedResponse.total }})</h3>
            <app-collapse-button [label]="'Filtri'" [ref]="'collapseFilters'" class="ms-auto"></app-collapse-button>
            <button (click)="new()" class="btn btn-success ms-2" i18n="pulsante nuovo" type="button"><i
              class="fas fa-plus-square"></i> Nuovo
            </button>
          </div>

          <div class="collapse" id="collapseFilters">
            <form (ngSubmit)="onFind(formSearch)" [formGroup]="formSearch" novalidate>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name" i18n="nome form filtri offerte">Nome</label>
                      <input class="form-control" formControlName="name" i18n-placeholder="placeholder nome filtri offerte"
                             placeholder="Nome" type="text">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="periodicity" i18n="periodicità form filtri offerte">Periodicità</label>
                      <input class="form-control" formControlName="periodicity" i18n-placeholder="placeholder periodicity filtri offerte"
                             placeholder="Periodicità" type="text">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right">
                    <button (click)="clearFilters()" class="btn btn-primary" i18n="pulsante pulisci filtri"
                            type="button">Pulisci filtri
                    </button>
                    <button class="btn btn-primary ms-2" i18n="pulsante cerca" type="submit">Cerca</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th i18n="nome">
                  <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'name'"
                                   [label]="'Nome'"></app-sort-button>
                </th>
                <th i18n="periodicità">
                  <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'periodicity'"
                                   [label]="'Periodicità'"></app-sort-button>
                </th>
                <th i18n="freq. mensile">
                  <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'monthly_frequency'"
                                   [label]="'Freq. mensile'"></app-sort-button>
                </th>
                <th i18n="notifica esterna">
                  <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'notify_client'"
                                   [label]="'Notifica esterna'"></app-sort-button>
                </th>
                <th i18n="notifica interna">
                  <app-sort-button (orderChange)="updateList()" [(order)]="order" [column]="'notify_operator'"
                                   [label]="'Notifica interna'"></app-sort-button>
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let object of paginatedResponse.data">
                <td>{{ object.name }}</td>
                <td>{{ object.periodicity }}</td>
                <td>{{ object.monthly_frequency }}</td>
                <td>{{ object.notify_client ? 'SI' : 'NO' }}</td>
                <td>{{ object.notify_operator ? 'SI' : 'NO' }}</td>
                <td class="text-right">
                  <button (click)="edit(object)" class="btn btn-primary" type="button"><i class="fas fa-edit"></i>
                  </button>
                  <button (click)="openTrashModal(content, object)" *ngIf="!object.is_system" class="btn btn-danger ms-2"
                          type="button"><i class="fas fa-trash-alt"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <app-pagination (elementsChange)="onElementsChange($event)" (pageChangeEvent)="updateList($event)"
                            [paginatedResponse]="paginatedResponse"></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="pulsante elimina" id="modal-basic-title">Elimina</h4>
    <button (click)="modal.dismiss('cancel')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Confermi l'eliminazione dello stato?
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('confirm')" class="btn btn-outline-dark" i18n="pulsante conferma" type="button">
      Conferma
    </button>
    <button (click)="modal.dismiss('cancel')" class="btn btn-outline-dark" i18n="pulsante annulla" type="button">
      Annulla
    </button>
  </div>
</ng-template>
