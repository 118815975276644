import {Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {RestTypeService} from '../../../../services/rest-type.service';
import {RestType} from '../../../../../shared/models/RestType';

@Component({
  selector: 'app-rest-types',
  templateUrl: './rest-types.component.html',
  styleUrls: ['./rest-types.component.css']
})
export class RestTypesComponent extends DictionaryComponent<RestType> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: RestTypeService
  ) {
    super(modalService, fb, service);
  }

}
