import {Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {GroupCompanyService} from '../../../../services/groupcompany.service';
import {GroupCompany} from '../../../../../shared/models/GroupCompany';

@Component({
  selector: 'app-groupcompany',
  templateUrl: './groupcompany.component.html',
  styleUrls: ['./groupcompany.component.css']
})
export class GroupCompanyComponent extends DictionaryComponent<GroupCompany> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: GroupCompanyService
  ) {
    super(modalService, fb, service);
  }

}
