import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tag} from '../../models/Tag';
import {Observable} from 'rxjs';
import {TagService} from '../../../admin/services/tag.service';
import {filter, map, startWith, switchMap} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'app-tag-chip',
  templateUrl: './tag-chip.component.html',
  styleUrls: ['./tag-chip.component.css']
})
export class TagChipComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() selectedTags: Tag[] = [];
  @Output() changeSelectedTags = new EventEmitter<Tag[]>();
  public filteredTags: Observable<any>;
  public tagCtrl = new UntypedFormControl();

  constructor(
    private tagService: TagService
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map(
        (tag: Tag | string) => this._filter(tag)
      ));
  }

  private static removeTag(tags: Tag[], tag: Tag): void {
    const index = tags.indexOf(tag);
    if (index >= 0) {
      tags.splice(index, 1);
    }
  }
  private static addTag(tags: Tag[], tag: Tag): void {
    tags.push(tag);
  }

  ngOnInit(): void {
    this.filteredTags = this.tagCtrl.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this._filter(value))
      );
  }

  removeSelectedTag(tag: Tag): void {
    TagChipComponent.removeTag(this.selectedTags, tag);
    this.changeSelectedTags.emit(this.selectedTags);
    this.tagCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    TagChipComponent.addTag(this.selectedTags, event.option.value);
    this.changeSelectedTags.emit(this.selectedTags);
    this.tagCtrl.setValue(null);
  }

  private _filter(value: Tag | string): any {
    let filterValue = '';
    if (typeof value === 'string'){
      filterValue = value.toLowerCase();
    }
    return this.tagService.getAll().pipe(
      filter(response => !!response),
      map((response) => {
        return response.data
          .filter(tag => !this.selectedTags?.map(selTag => selTag.id).includes(tag.id))
          .filter(tag => tag.name.toLowerCase().includes(filterValue));
      })
    );
  }

}
