import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../../shared/models/Contact';

@Component({
  selector: 'app-contacts-detail',
  templateUrl: './contacts-detail.component.html',
  styleUrls: ['./contacts-detail.component.css']
})
export class ContactsDetailComponent implements OnInit {

  @Input() contact?: Contact;
  @Output() back = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  goBack(): void{
    this.back.emit();
  }

  goEdit(): void{
    this.edit.emit();
  }

}
