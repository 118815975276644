import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {CrudComponent} from '../../../../shared/components/crud/crud.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PaginatedDataResponse} from '../../../../shared/models/respose/PaginatedDataResponse';
import {Contact} from '../../../../shared/models/Contact';
import {companyFormAttributes} from '../../../../shared/vars/companyFormAttributes';
import {BaseResponse} from '../../../../shared/models/respose/BaseResponse';
import {ContactService} from '../../../services/contact.service';
import {ComponentsConstants} from '../../../../shared/constants/ComponentsConstants';
import {Observable} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {Province} from '../../../../shared/models/Province';
import {City} from '../../../../shared/models/City';
import {ProvinceService} from '../../../services/province.service';
import {CityService} from '../../../services/city.service';
import {User} from '../../../../shared/models/User';
import {Order} from '../../../../shared/models/Order';
import {DirectionType} from '../../../enum/DirectionType';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {Pagination} from '../../../../shared/models/Pagination';
import {environment} from '../../../../../environments/environment';
import {CompanyRequest} from '../../../models/request/CompanyRequest';
import {AuthService} from '../../../../shared/services/auth.service';
import {ivaValidator} from '../../../../shared/validators/IvaValidator';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent extends CrudComponent implements OnInit {

  @Input() action = '';
  @Input() id = '';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Inserisci il testo...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'heading',
        'fontName',
        'backgroundColor',
        'link',
        'unlink',
        'insertVideo',
        'insertHorizontalRule',
      ]
    ],
  };
  public paginatedResponse: PaginatedDataResponse<Contact> = new PaginatedDataResponse<Contact>(0, [], 0, 0, 0);
  public components = ComponentsConstants;
  public provinces: Province[] = [];
  public cities: City[] = [];
  public contactUsers: User[] = [];
  public project = environment.project;
  public canChangeUser = false;
  protected _hasModal = true;

  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private router: Router,
    public contactService: ContactService,
    private userService: UserService,
    private provinceService: ProvinceService,
    private cityService: CityService,
    public authService: AuthService,
    private location: Location
  ) {
    super(modalService, fb);
  }

  ngOnInit(): void {

    if (this.action === 'edit') {
      this.contactService.getItem(+this.id).subscribe(result => this.edit(result.data));
    }

    this.order = new Order('company_name', DirectionType.asc);

    this.form = this.fb.group({
      ...companyFormAttributes,
      iva: [
        null,
        [Validators.required],
        [ivaValidator(this.contactService, 0)],
      ],
    });

    if (this.fromModal) {
      this.new();
    }
  }

  onFind(form: UntypedFormGroup): void {
    this.formSearch = form;
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void {
    const contact: CompanyRequest = this.formSearch?.value ?? new CompanyRequest();
    contact.is_company = true;
    this.contactService.getPaginatedList(pagination.elements, pagination.page, contact, this.order).subscribe(
      (response: BaseResponse<PaginatedDataResponse<Contact>>) => {
        this.paginatedResponse = response.data;
      }
    );
  }

  openTrashModal(content: TemplateRef<any>, contact: Contact): void {
    this.modalService.open(content, {ariaLabelledBy: 'appointment-detail-modal-basic-title'}).result.then((result) => {
      this.contactService.deleteItem(contact?.id ?? 0).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  onSubmit({value, valid}: { value: Contact, valid: boolean }): void {
    if (valid) {
      if (value.id) {
        this.callUpdate(value);
      } else {
        this.callRegister(value);
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  edit(contact?: Contact): void {
    this.canChangeUser = false;
    this.updateProvinces();
    this.updateCities(contact?.city?.province.id || 0);
    this.form.patchValue({
      ...contact,
      province_id: contact?.city?.province.id || '',
      source_id: contact?.source?.id || '',
      position_id: contact?.position?.id || '',
      contact_type_id: contact?.contact_type?.id || '',
      company_activity_id: contact?.company_activity?.id || '',
      user_id: contact?.user?.id || '',
    });
    this.checkUserChange(contact);

    this.form.get('iva')?.setAsyncValidators([ivaValidator(this.contactService, contact?.id ? contact.id : 0)]);

    this.contactUsers = contact?.user ? [contact.user] : [];
    this.showHideForm();
    this.showHideTable();
  }

  new(): void {
    super.new({is_company: true, user_id: this.authService.getUser().id});
    this.canChangeUser = false;
    this.checkUserChange();
  }

  clearFilters(): void{
    this.formSearch.reset({is_company: true});
  }

  callRegister(contact: Contact): void {
    this.contactService.register(contact).subscribe(response => {
      if (this.fromModal) {
        this.modalSaved = true;
      } else {
        this.afterInsert();
      }
    });
  }

  callUpdate(contact: Contact): void {
    this.contactService.updateItem(contact, contact.id ?? 0).subscribe(response => {
      this.afterInsert();
    });
  }

  updateProvinces(): void {
    if (this.provinces.length === 0) {
      this.provinceService.getAll().subscribe(result => {
        this.provinces = result.data;
      });
    }
  }

  updateCities(province_id: number): void {
    this.cityService.getAll(province_id).subscribe(result => {
      this.cities = result.data;
    });
  }

  getUserService(search: any, id?: number): Observable<any> {
    return this.userService.getAll();
  }

  exportCompanies(form: UntypedFormGroup): void {
    this.formSearch = form;
    this.export(this.contactService, null);
  }

  checkUserChange(contact?: Contact): void {
    if (!this.authService.canManageProspects()) {
      this.canChangeUser = false;
    } else if (contact && contact?.practices && contact?.practices?.length > 0) {
      this.canChangeUser = false;
    } else {
      this.canChangeUser = true;
    }
  }

  back(): void {
    this.location.back();
  }

  private afterInsert(): void {
    this.updateList({page: 1, elements: this.elements});
    this.showHideForm();
    this.showHideTable();
    if (this.action === 'edit') {
      this.back();
    }
  }
}
