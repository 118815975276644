<div [formGroup]="form" class="form-group">
  <label *ngIf="label">{{label}}</label>
  <input
    [readonly]="readonly"
    [type]="type"
    [min]="min"
    [max]="max"
    class="form-control"
    [ngClass]="getInvalidClass(controlName)"
    [placeholder]="label"
    [formControlName]="controlName"
    (input)="onInputChange($event)"
  >
  <app-input-errors [element]="form.get(controlName)"></app-input-errors>
</div>
