import {GroupCompanyComponent} from './../../admin/components/routes/user-settings/groupcompany/groupcompany.component';
import {StudyCertificatesComponent} from '../../admin/components/routes/study_certificates/studyCertificates.component';
import {RestTypesComponent} from '../../admin/components/routes/user-settings/rest-types/rest-types.component';
import {DepartmentsComponent} from '../../admin/components/routes/user-settings/departments/departments.component';
import {TasksComponent} from '../../admin/components/routes/tasks/tasks.component';
import {ExperienceYearsComponent} from '../../admin/components/routes/experience_years/experienceYears.component';
import {JobsComponent} from '../../admin/components/routes/jobs/jobs.component';
import {ContractTypesComponent} from '../../admin/components/routes/user-settings/contract-types/contract-types.component';
import {PositionsComponent} from '../../admin/components/routes/contact-settings/positions/positions.component';
import {SourcesComponent} from '../../admin/components/routes/contact-settings/sources/sources.component';
import {ContactTypesComponent} from '../../admin/components/routes/contact-settings/contact-types/contact-types.component';
import {CompanyActivitiesComponent} from '../../admin/components/routes/contact-settings/company-activities/company-activities.component';
import {ScheduleEventComponent} from '../../admin/components/routes/schedule-settings/schedule-event/schedule-event.component';
import {PracticeStatesComponent} from '../../admin/components/routes/practice-settings/practice-states/practice-states.component';
import {DocumentTypesComponent} from '../../admin/components/routes/user-settings/document-types/document-types.component';
import {ContactsComponent} from '../../admin/components/routes/contacts/contacts.component';
import {CompaniesComponent} from '../../admin/components/routes/companies/companies.component';
import {CostCentersComponent} from '../../admin/components/routes/user-settings/cost-centers/cost-centers.component';
import {AssignmentTypesComponent} from '../../admin/components/routes/assignment-settings/assignment-types/assignment-types.component';
import {PracticeTypesComponent} from '../../admin/components/routes/practice-settings/practice-types/practice-types.component';
import {
  PracticeStateNoteTypesComponent
} from '../../admin/components/routes/practice-settings/practice-state-note-types/practice-state-note-types.component';
import {
  DailyActivityTypesComponent
} from '../../admin/components/routes/practice-settings/daily-activity-types/daily-activity-types.component';
import {IncomeTypesComponent} from '../../admin/components/routes/contact-settings/income-types/income-types.component';
export const ComponentsConstants = {
  StudyCertificatesComponent,
  RestTypesComponent,
  GroupCompanyComponent,
  DepartmentsComponent,
  TasksComponent,
  ExperienceYearsComponent,
  JobsComponent,
  ContractTypesComponent,
  PositionsComponent,
  ContactTypesComponent,
  SourcesComponent,
  CompanyActivitiesComponent,
  ScheduleEventComponent,
  PracticeStatesComponent,
  DocumentTypesComponent,
  ContactsComponent,
  CompaniesComponent,
  CostCentersComponent,
  AssignmentTypesComponent,
  PracticeTypesComponent,
  PracticeStateNoteTypesComponent,
  DailyActivityTypesComponent,
  IncomeTypesComponent
};
