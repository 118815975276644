import {StudyCertificateService} from '../../admin/services/study-certificate.service';
import {SubjectService} from '../../admin/services/subject.service';
import {ExperienceYearService} from '../../admin/services/experience-year.service';
import {JobService} from '../../admin/services/job.service';
import {ClassificationService} from '../../admin/services/classification.service';
import {TaskService} from '../../admin/services/task.service';
import {CompanyActivityService} from '../../admin/services/company-activity.service';
import {PositionService} from '../../admin/services/position.service';
import {ContactTypeService} from '../../admin/services/contact-type.service';
import {SourceService} from '../../admin/services/source.service';
import {ContactService} from '../../admin/services/contact.service';
import {PracticeStateService} from '../../admin/services/practice-state.service';
import {ContractTypeService} from '../../admin/services/contract-type.service';
import {DepartmentService} from '../../admin/services/department.service';
import {RestTypeService} from '../../admin/services/rest-type.service';
import {GroupCompanyService} from '../../admin/services/groupcompany.service';
import {ScheduleEventService} from '../../admin/services/schedule-event.service';
import {SchedulableTypeService} from '../../admin/services/schedulable-type.service';
import {DocumentTypeService} from '../../admin/services/document-type.service';
import {PersonnelNumberService} from '../../admin/services/personnel-number.service';
import {CompanyDimensionService} from '../../admin/services/company-dimension.service';
import {GenderService} from '../../admin/services/gender.service';
import {CostCenterService} from '../../admin/services/cost-center.service';
import {AssignmentTypeService} from '../../admin/services/assignment-type.service';
import {PracticeTypeService} from '../../admin/services/practice-type.service';
import {PracticeStateNoteTypeService} from '../../admin/services/practice-state-note-type.service';
import {DailyActivityService} from '../../admin/services/daily-activity.service';
import {MachineTypeService} from '../../admin/services/machine-type.service';
import {MaritalStatusService} from '../../admin/services/marital-status.service';
import {InternalProjectService} from '../../admin/services/internal-project.service';
import {IncomeTypeService} from '../../admin/services/income-type.service';

export const DictionaryListConstants = {
  study_certificate: StudyCertificateService,
  subject: SubjectService,
  classification: ClassificationService,
  task: TaskService,
  experience_year: ExperienceYearService,
  job: JobService,
  company_activity: CompanyActivityService,
  position: PositionService,
  contact_type: ContactTypeService,
  source: SourceService,
  contact: ContactService,
  practice_state: PracticeStateService,
  contract_type: ContractTypeService,
  department: DepartmentService,
  rest_type: RestTypeService,
  gcompany: GroupCompanyService,
  event: ScheduleEventService,
  schedulable_type: SchedulableTypeService,
  document_type: DocumentTypeService,
  personnel_number: PersonnelNumberService,
  company_dimension: CompanyDimensionService,
  gender: GenderService,
  cost_center: CostCenterService,
  assignment_type: AssignmentTypeService,
  practice_type: PracticeTypeService,
  practice_state_note_type: PracticeStateNoteTypeService,
  daily_activity: DailyActivityService,
  machine_type: MachineTypeService,
  marital_status: MaritalStatusService,
  internal_project: InternalProjectService,
  income_type: IncomeTypeService
};
