import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {ConfigurationType} from '../../shared/enum/ConfigurationType';
import {Configuration} from '../../shared/models/Configuration';

@Injectable({
  providedIn: 'root'
})

export class ConfigurationService extends HttpService {



  getConfiguration(type: ConfigurationType): Observable<BaseResponse<Configuration>>{
    return this.get('configuration/' + type);
  }

  saveConfiguration(configuration: Configuration): Observable<BaseResponse<Configuration>>{
    return this.post('configuration', configuration);
  }
}
