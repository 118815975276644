import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseResponse} from '../models/respose/BaseResponse';
import {HttpService} from './http.service';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PaginationStorageService extends HttpService {

  readonly DEFAULT_PAGINATION_PER_PAGE = 50;

  constructor(
    http: HttpClient,
    router: Router) {
    super(
      http,
      router);
    this.load();
  }

  private setInCache(per_page: number): Observable<BaseResponse<boolean>>{
    return this.post('pagination/items/set', {per_page});
  }

  private getFromCache(): Observable<BaseResponse<number>>{
    return this.get('pagination/items/get');
  }

  private getFromStorage(): number{
    // tslint:disable-next-line:no-non-null-assertion
    return parseInt(localStorage.getItem('pagination_per_page') || this.DEFAULT_PAGINATION_PER_PAGE.toString(), undefined);
  }

  private setInStorage(per_page: number): void{
    localStorage.setItem('pagination_per_page', per_page.toString());
  }

  getElements(): number {
    return this.getFromStorage();
  }

  setElements(value: number): void{
    this.setInCache(value).subscribe(() => {
      this.setInStorage(value);
    });
  }

  load(): void{
    this.getFromCache().subscribe((response) => {
      this.setInStorage(response.data);
    });
  }
}
