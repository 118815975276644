import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {DailyActivity} from '../../shared/models/DailyActivity';

@Injectable({
  providedIn: 'root'
})
export class DailyActivityService extends DictionaryService<DailyActivity> {

  path = 'daily_activities';

}
