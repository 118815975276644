import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {Subject} from '../../shared/models/Subject';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubjectService extends HttpService {

  getAll(): Observable<BaseResponse<Subject[]>>{
    return this.get('subjects/index');
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: Subject,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<Subject>>>{
    const request = new PaginatedDataRequest<Subject>(entries, page, data, order);
    return this.post('subjects', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Subject): Observable<BaseResponse<Subject>>{
    return this.post('subjects/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Subject>>{
    return this.delete('subjects', id);
  }

  updateItem(request: Subject, id: number): Observable<BaseResponse<Subject>>{
    return this.put('subjects', request, id);
  }
}
