<div *ngIf="!companyFieldsVisible" class="card card-primary card-outline">

  <div class="card-body box-profile">
    <div class="text-center">
      <img alt="User profile picture" class="profile-user-img img-fluid img-circle"
           src="{{ null ?? '' | profilePicture}}">
    </div>
    <div class="text-center">
      <h3 class="profile-username">{{ contact?.first_name | titlecase }} {{ contact?.last_name | titlecase }}</h3>
    </div>

    <p class="text-muted text-center">{{ contact?.position?.name }}</p>

    <strong><i class="fas fa-calendar me-1"></i> Data di Nascita</strong>
    <p class="text-muted">
      {{ contact?.birth_date | date : 'dd-MM-yyyy' }}
    </p>
    <hr>
    <strong><i class="fas fa-at me-1"></i> Email</strong>
    <p class="text-muted">
      <a href="mailto:{{contact?.email}}">{{ contact?.email }}</a>
    </p>
    <br/>
    <button (click)="openModalSendMail()" class="btn btn-outline-primary btn-sm" i18n="email" type="button">
      <i class="fas fa-envelope"></i> Invia email aziendale
    </button>
    <hr>
    <strong><i class="fas fa-phone me-1"></i> Telefono Lavoro</strong>
    <p class="text-muted mb-0"><a (click)="clickTelephone.emit()"
                                  href="tel:{{contact?.work_number}}">{{ contact?.work_number }}</a></p>
    <button (click)="openModalSendWhatsapp(contact?.work_number || '')" *ngIf="contact?.work_number"
            class="btn btn-outline-primary btn-sm"
            i18n="whatsapp" type="button">
      <i class="fas fa-comment"></i> Invia messaggio Whatsapp
    </button>
    <hr>
    <strong><i class="fas fa-phone me-1"></i> Telefono Personale</strong>
    <p class="text-muted mb-0">
      <a (click)="clickTelephone.emit()" href="tel:{{contact?.mobile_number}}">{{ contact?.mobile_number }}</a>
    </p>
    <button (click)="openModalSendWhatsapp(contact?.mobile_number || '')" *ngIf="contact?.mobile_number"
            class="btn btn-outline-primary btn-sm"
            i18n="whatsapp" type="button">
      <i class="fas fa-comment"></i> Invia messaggio Whatsapp
    </button>
  </div>
</div>
<!-- Card campi persona giuridica -->
<div *ngIf="companyFieldsVisible">

  <div class="card card-primary card-outline">
    <div class="card-body box-profile">
      <h3 class="profile-username text-center">{{ contact?.company_name | titlecase }}</h3>
      <p class="text-muted text-center">{{ contact?.company_type }}</p>
      <hr>
      <div class="row">
        <div class="col-4">
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span class="info-box-text text-center text-muted">Dipendenti</span>
              <span class="info-box-number text-center text-muted mb-0">{{ contact?.dipendenti }}</span>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span class="info-box-text text-center text-muted">Fatturato</span>
              <span class="info-box-number text-center text-muted mb-0">{{ contact?.fatturato_desercizio }}</span>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="info-box bg-light">
            <div class="info-box-content">
              <span class="info-box-text text-center text-muted">Data inizio Attività</span>
              <span
                class="info-box-number text-center text-muted mb-0">{{ contact?.data_inizio_attivita | date :'dd-MM-yyyy' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row">
    <div class="d-flex flex-fill flex-column me-2">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title mb-0">Anagrafica</h3>
          <div class="card-tools">
            <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>

        <div class="card-body">
          <strong>Sito WEB</strong>
          <p class="text-muted"><a href="https://{{contact?.sito_web}}" target="_blank">{{ contact?.sito_web }}</a></p>
          <hr>
          <strong>Numero di telefono</strong>
          <p class="text-muted"><a href="tel:{{contact?.work_number}}">{{ contact?.work_number }}</a></p>
          <hr>
          <strong>Fax</strong>
          <p class="text-muted"><a href="tel:{{contact?.fax}}">{{ contact?.fax }}</a></p>
          <hr>
          <strong>Partita IVA</strong>
          <p class="text-muted">{{ contact?.iva }}</p>
          <hr>
          <strong>Codice Fiscale</strong>
          <p class="text-muted">
            {{ contact?.fiscal_code }}
          </p>
          <hr>
          <strong>Indirizzo</strong>
          <p class="text-muted">{{ contact?.via }} {{ contact?.frazione }} {{ contact?.city?.name }}
            ({{ contact?.city?.province?.name }})</p>
          <hr>
          <strong>Tipo Sede</strong>
          <p class="text-muted">{{ contact?.tipo_di_sede }}</p>
          <hr>
          <strong>E-Commerce</strong>
          <p class="text-muted"><a href="https://{{contact?.indirizzo_e_commerce}}"
                                   target="_blank">{{ contact?.indirizzo_e_commerce }}</a></p>
          <hr>
          <strong>Email</strong>
          <p class="text-muted">{{ contact?.email }}</p>
          <hr>
          <strong>Pec</strong>
          <p class="text-muted">{{ contact?.indirizzo_pec }}</p>
          <hr>
        </div>

      </div>
      <div class="card card-success">
        <div class="card-header">
          <h3 class="card-title mb-0">Social</h3>
          <div class="card-tools">
            <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>

        <div class="card-body">
          <strong>Social Attivi</strong>
          <p class="text-muted">{{ (contact?.social_attivi) ? 'SI' : 'NO' }}</p>
          <hr>
          <strong>Facebook</strong>
          <p class="text-muted"><a href="https://{{contact?.facebook}}" target="_blank">{{ contact?.facebook }}</a></p>
          <hr>
          <strong>Instagram</strong>
          <p class="text-muted"><a href="https://{{contact?.instagram}}" target="_blank">{{ contact?.instagram }}</a>
          </p>
          <hr>
          <strong>Linkedin</strong>
          <p class="text-muted"><a href="https://{{contact?.linkedin}}" target="_blank">{{ contact?.linkedin }}</a></p>
          <hr>
          <strong>Pinterest</strong>
          <p class="text-muted"><a href="https://{{contact?.pinterest}}" target="_blank">{{ contact?.pinterest }}</a>
          </p>
          <hr>
          <strong>YouTube</strong>
          <p class="text-muted"><a href="https://{{contact?.youtube}}" target="_blank">{{ contact?.youtube }}</a></p>
          <hr>
          <strong>Twitter</strong>
          <p class="text-muted"><a href="https://{{contact?.twitter}}" target="_blank">{{ contact?.twitter }}</a></p>
          <hr>
          <strong>Vimeo</strong>
          <p class="text-muted"><a href="https://{{contact?.vimeo}}" target="_blank">{{ contact?.vimeo }}</a></p>
          <hr>
        </div>

      </div>
      <div class="card card-warning">
        <div class="card-header">
          <h3 class="card-title mb-0">Dipendenti</h3>
          <div class="card-tools">
            <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>

        <div class="card-body">
          <strong>Numero dipendenti</strong>
          <p class="text-muted">{{ contact?.dipendenti }}</p>
          <hr>
          <strong>Trend dipendenti</strong>
          <p class="text-muted">{{ contact?.trend_dipendenti }}</p>
          <hr>
          <strong>Classe numero dipendenti</strong>
          <p class="text-muted">{{ contact?.classe_numero_dipendenti }}</p>
          <hr>
          <strong>Dipendenti contratto a tempo determinato</strong>
          <p class="text-muted">{{ contact?.percentuale_dipendenti_contratto_a_tempo_determinato }}%</p>
          <hr>
          <strong>Dipendenti contratto a tempo indeterminato</strong>
          <p class="text-muted">{{ contact?.percentuale_dipendenti_contratto_a_tempo_indeterminato }}%</p>
          <hr>
          <strong>Dipendenti contratto a tempo parziale</strong>
          <p class="text-muted">{{ contact?.percentuale_dipendenti_contratto_a_tempo_parziale }}%</p>
          <hr>
          <strong>Dipendenti contratto a tempo pieno</strong>
          <p class="text-muted">{{ contact?.percentuale_dipendenti_contratto_a_tempo_pieno }}%</p>
          <hr>
        </div>


      </div>
    </div>
    <div class="d-flex flex-fill flex-column ms-2">
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title mb-0">Info Azienda</h3>
          <div class="card-tools">
            <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>

        <div class="card-body">
          <strong>Forma Legale</strong>
          <p class="text-muted">{{ contact?.forma_legale }} ({{ contact?.forma_legale_dettagliata }})</p>
          <hr>
          <strong>Azienda della pubblica amministrazione</strong>
          <p class="text-muted">{{ contact?.azienda_della_pubblica_amministrazione ? 'SI' : 'NO' }}</p>
          <hr>
          <strong>Data di fondazione</strong>
          <p class="text-muted">{{ contact?.data_di_fondazione | date : 'dd-MM-yyyy' }}</p>
          <hr>
          <strong>Data di inizio attività</strong>
          <p class="text-muted">{{ contact?.data_inizio_attivita | date : 'dd-MM-yyyy' }}</p>
          <hr>
          <strong>Data costituzione</strong>
          <p class="text-muted">{{ contact?.data_costituzione | date : 'dd-MM-yyyy' }}</p>
          <hr>
          <strong>Data di iscrizione alla camera di commercio</strong>
          <p class="text-muted">{{ contact?.data_iscrizione_in_camera_di_commercio | date : 'dd-MM-yyyy' }}</p>
          <hr>
          <strong>Anno di iscrizione alla camera di commercio</strong>
          <p class="text-muted">{{ contact?.anno_iscrizione_in_camera_di_commercio }}</p>
          <hr>
          <strong>Startup innovativa</strong>
          <p class="text-muted">{{ contact?.startup_innovativa ? 'SI' : 'NO' }}</p>
          <hr>
          <strong>Pmi innovativa</strong>
          <p class="text-muted">{{ contact?.pmi_innovative ? 'SI' : 'NO' }}</p>
          <hr>
          <strong>Commerciabile</strong>
          <p class="text-muted">{{ contact?.marketable_truefalse ? 'SI' : 'NO' }}</p>
          <hr>
          <strong>Codice ATECO primario 2007</strong>
          <p class="text-muted">
            {{ contact?.codice_ateco_primario_2007 }} ({{ contact?.descrizione_codice_ateco_primario_2007 }})
          </p>
          <hr>
          <strong>Codice ATECO secondario 2007</strong>
          <p class="text-muted">
            {{ contact?.codice_ateco_secondario_2007 }}
          </p>
          <hr>
          <strong>Codice ATECO primario 2007 primo livello</strong>
          <p class="text-muted">{{ contact?.codice_ateco_primario_2007_primo_livello }}</p>
          <hr>
          <strong>Dimensione Azienda</strong>
          <p class="text-muted">{{ contact?.dimensione_azienda }}</p>
          <hr>
          <strong>SIC primario</strong>
          <p class="text-muted">{{ contact?.sic_primario }} ({{ contact?.descrizione_codice_sic_primario }})</p>
          <hr>
          <strong>SIC secondario</strong>
          <p class="text-muted">{{ contact?.sic_primario }}</p>
          <hr>
          <strong>Codice NACE</strong>
          <p class="text-muted">{{ contact?.nace }} ({{ contact?.descrizione_codice_nace }})</p>
          <hr>
          <strong>EBIT</strong>
          <p class="text-muted">{{ contact?.ebit }}</p>
          <hr>
          <strong>EBITDA</strong>
          <p class="text-muted">{{ contact?.ebitda }}</p>
          <hr>
          <strong>Numero CRIF</strong>
          <p class="text-muted">{{ contact?.crif_number }}</p>
          <hr>
          <strong>REA</strong>
          <p class="text-muted">{{ contact?.rea }}</p>
          <hr>
          <strong>Unità locali</strong>
          <p class="text-muted">{{ contact?.unita_locali }}</p>
          <hr>
          <strong>Tipologia unità locali</strong>
          <p class="text-muted">{{ contact?.tipologia_unita_locale }}</p>
          <hr>
        </div>

      </div>
      <div class="card card-danger">
        <div class="card-header">
          <h3 class="card-title mb-0">Fatturato</h3>
          <div class="card-tools">
            <button class="btn btn-tool" data-card-widget="collapse" title="Collapse" type="button">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>

        <div class="card-body">
          <strong>Anno Fatturato</strong>
          <p class="text-muted">{{ contact?.anno_fatturato }}</p>
          <hr>
          <strong>Fatturato d'esercizio</strong>
          <p class="text-muted">{{ contact?.fatturato_desercizio }}</p>
          <hr>
          <strong>Fatturato Consolidato</strong>
          <p class="text-muted">{{ contact?.fatturato_consolidato }}</p>
          <hr>
          <strong>Trend Fatturato d'esercizio</strong>
          <p class="text-muted">{{ contact?.trend_fatturato_di_esercizio }}</p>
          <hr>
        </div>

      </div>
    </div>
  </div>

</div>

