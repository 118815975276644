import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {PersonnelNumber} from '../../shared/models/PersonnelNumber';

@Injectable({
  providedIn: 'root'
})
export class PersonnelNumberService extends DictionaryService<PersonnelNumber> {

  path = 'personnel_numbers';

}
