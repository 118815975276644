import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {MaritalStatus} from '../../shared/models/MaritalStatus';

@Injectable({
  providedIn: 'root'
})
export class MaritalStatusService extends DictionaryService<MaritalStatus> {

  path = 'marital_statuses';

}
