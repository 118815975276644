import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {Position} from '../../shared/models/Position';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PositionService extends HttpService {

  getAll(): Observable<BaseResponse<Position[]>>{
    return this.get('positions');
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: Position,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<Position>>>{
    const request = new PaginatedDataRequest<Position>(entries, page, data, order);
    return this.post('positions', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Position): Observable<BaseResponse<Position>>{
    return this.post('positions/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Position>>{
    return this.delete('positions', id);
  }

  updateItem(request: Position, id: number): Observable<BaseResponse<Position>>{
    return this.put('positions', request, id);
  }
}
