import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {

  @Input() label = '';
  @Input() rows = 3;
  @Input() controlName = '';
  // @ts-ignore
  @Input() form: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  getInvalidClass = (element: string): string => {
    if (this.form !== undefined && this.form != null){
      return (this.form?.get(element)?.invalid && (this.form?.get(element)?.dirty || this.form?.get(element)?.touched)) ? 'is-invalid' : '';
    }
    return '';
  }

}
