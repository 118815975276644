import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {CompanyActivityService} from '../../../../services/company-activity.service';
import {CompanyActivity} from '../../../../../shared/models/CompanyActivity';

@Component({
  selector: 'app-company-activities',
  templateUrl: './company-activities.component.html',
  styleUrls: ['./company-activities.component.css']
})
export class CompanyActivitiesComponent extends DictionaryComponent<CompanyActivity> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: CompanyActivityService
  ) {
    super(modalService, fb, service);
  }

}
