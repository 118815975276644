import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseResponse} from 'src/app/shared/models/respose/BaseResponse';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {ContactRequest} from '../models/request/ContactRequest';
import {Contact} from '../../shared/models/Contact';
import {CompanyRequest} from '../models/request/CompanyRequest';
import {ExportService} from './export.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends ExportService<ContactRequest | CompanyRequest> {

  exportPath = 'contacts';

  getAll(): Observable<BaseResponse<Contact[]>> {
    return this.get('contacts/index');
  }

  getPaginatedList(entries?: number,
                   page?: number,
                   data?: ContactRequest | CompanyRequest,
                   order?: Order,
                   relations?: []): Observable<BaseResponse<PaginatedDataResponse<Contact>>> {
    const request = new PaginatedDataRequest<ContactRequest>(entries, page, data, order, relations);
    return this.post('contacts', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Contact): Observable<BaseResponse<Contact>> {
    return this.post('contacts/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Contact>> {
    return this.delete('contacts', id);
  }

  updateItem(request: Contact, id: number): Observable<BaseResponse<Contact>> {
    return this.put('contacts', request, id);
  }

  getItem(id: number): Observable<BaseResponse<Contact>> {
    return this.get('contacts/' + id);
  }

  setEmailGroup(request: { contact_id: number, email_groups: number[] }): Observable<BaseResponse<Contact>> {
    return this.post('contacts/email_group', request);
  }

  checkIva(request: { iva: number, contact_id: number }): Observable<BaseResponse<Contact>> {
    return this.post('contacts/check_iva', request);
  }

  assign(request: { user_id: number, contacts: number[] }): Observable<BaseResponse<any>> {
    return this.post('contacts/assign', request);
  }

}
