import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../shared/models/respose/BaseResponse';
import {StudyCertificate} from '../../shared/models/StudyCertificate';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StudyCertificateService extends HttpService {

  getAll(): Observable<BaseResponse<StudyCertificate[]>>{
    return this.get('study_certificates');
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: StudyCertificate,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<StudyCertificate>>>{
    const request = new PaginatedDataRequest<StudyCertificate>(entries, page, data, order);
    return this.post('study_certificates', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: StudyCertificate): Observable<BaseResponse<StudyCertificate>>{
    return this.post('study_certificates/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<StudyCertificate>>{
    return this.delete('study_certificates', id);
  }

  updateItem(request: StudyCertificate, id: number): Observable<BaseResponse<StudyCertificate>>{
    return this.put('study_certificates', request, id);
  }
}
