import {Injectable} from '@angular/core';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {CompanyDimension} from '../../shared/models/CompanyDimension';

@Injectable({
  providedIn: 'root'
})
export class CompanyDimensionService extends DictionaryService<CompanyDimension> {
  path = 'company_dimensions';
}
