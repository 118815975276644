import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseResponse} from 'src/app/shared/models/respose/BaseResponse';
import {User} from '../../shared/models/User';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {UserRequest} from '../models/request/UserRequest';
import {DataRequest} from '../../shared/models/request/DataRequest';
import {ExportService} from './export.service';
import {DirectionType} from '../enum/DirectionType';
import {UniformUserRequest} from '../models/request/UniformUserRequest';
import {Uniform} from '../../shared/models/Uniform';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ExportService<UserRequest> {

  exportPath = 'users';

  getPaginatedList(entries: number, page: number, data?: UserRequest, order?: Order): Observable<BaseResponse<PaginatedDataResponse<User>>>{
    const request = new PaginatedDataRequest<UserRequest>(entries, page, data, order);
    return this.post('users', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  getList(order: Order = new Order('id', DirectionType.desc), data?: UserRequest): Observable<BaseResponse<User[]>>{
    const request = new DataRequest<User>(data, order);
    return this.post('users/list', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  getFiltered(data?: UserRequest, order?: Order): Observable<BaseResponse<PaginatedDataResponse<User>>>{
    const request = new DataRequest<UserRequest>(data, order);
    return this.post('users', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: User): Observable<BaseResponse<User>>{
    return this.post('users/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<User>>{
    return this.delete('users', id);
  }

  updateItem(request: User, id: number): Observable<BaseResponse<User>>{
    return this.put('users', request, id);
  }

  getAll(): Observable<BaseResponse<User[]>>{
    return this.get('users');
  }

  getItem(id: number): Observable<BaseResponse<User>>{
    return this.get('users/' + id);
  }

  getItemUser(id: number): Observable<User> {
    return this.get('users/' + id).pipe(
      map(response => response.data) // Estrarre direttamente l'oggetto User
    );
  }

  exportPdf(id: number, logo: string, template: string): Observable<BaseResponse<string>>{
    return this.get('users/' + id + '/' + logo + '/' + template + '/export_pdf');
  }

  getSelectList(userId: number | null = null): Observable<BaseResponse<User[]>>{
    const request = {data: {user_id: userId}};
    return this.post('users/get_select_users', request);
  }

  addUniform(request: UniformUserRequest): Observable<BaseResponse<Uniform[]>>{
    return this.post('users/add_uniform', request);
  }

  deleteUniform(userId: number, uniformId: number): Observable<BaseResponse<Uniform[]>>{
    return this.deleteByPath('users/' + userId + '/delete_uniform/' + uniformId);
  }

}

