import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {PracticeState} from '../../shared/models/PracticeState';
import {GroupCompany} from '../../shared/models/GroupCompany';

@Injectable({
  providedIn: 'root'
})
export class GroupCompanyService extends DictionaryService<GroupCompany> {

  path = 'gcompany';

}
