import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewChild } from '@angular/core';
import {FileConstants} from '../../constants/FileConstants';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-input-file-multiple',
  templateUrl: './input-file-multiple.component.html',
  styleUrls: ['./input-file-multiple.component.css']
})
export class InputFileMultipleComponent implements OnInit {

  @Input() public form: UntypedFormGroup | undefined;
  @Input() public fileTypes: string[];
  @Input() public controlName = '';
  @Input() filePath = '';
  @ViewChild('fileUpload', {static: false}) inputVariable: ElementRef | undefined;

  @Input() public maxFileSizeKb = environment.max_file_size_kb;

  constructor(public toastService: ToastService,
              public fb: UntypedFormBuilder) {
    this.fileTypes = FileConstants.imageExtensions.concat(FileConstants.documentExtension.concat(FileConstants.pdfExtension));
  }

  get documentsForm(): UntypedFormArray{
    return this.form?.controls.documents as UntypedFormArray;
  }

  ngOnInit(): void {}

  checkExt(event: any): boolean{
    const regex = new RegExp('([a-zA-Z0-9\s_\\.\-:])+(' +
      this.fileTypes.join('|')
      + ')$');
    if (!regex.test(event.type.toLowerCase()) && !regex.test(event.name.toLowerCase())) {
        return false;
    }
    return true;
  }

  checkSize(event: any): boolean{
    const file_size_byte: number = event.size;
    const max_size_byte = this.maxFileSizeKb * 1024;
    return file_size_byte < max_size_byte;
  }

  getTextSize(kb_size: number): string{
    if (kb_size > 1024){
      return kb_size / 1024 + ' mb';
    }
    return kb_size + ' kb';
  }

  onFileChange(event: any): void{
    if (event.target.files && event.target.files.length) {
      let filecount = 0;
      for (const file of event.target.files){
        if (!this.checkSize(file)){
          this.resetDocuments();
          this.showMessage('Attenzione! Il file ' + file.name + ' è superiore a ' + this.getTextSize(this.maxFileSizeKb) + '!');
          break;
        }
        if (!this.checkExt(file)){
          this.resetDocuments();
          this.showMessage('Attenzione! Formato file ' + file.name + ' errato!');
          break;
        }
        this.insertFile(file);
        filecount++;

      }
      this.filePath = filecount + ' file caricati';
    }
  }

  resetDocuments(): void{
    this.filePath = '';
    this.documentsForm.clear();
  }

  showMessage(message: string): void{
    this.toastService.show(message, {class: 'bg-warning text-light', delay: 3000});
  }

  insertFile(file: any): void{
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      this.documentsForm.push(this.fb.group({
        document: [reader.result],
        original_name: [file.name !== '' ? file.name : '']
      }));
    };
  }





}
