import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendMailComponent } from '../components/utility/send-mail/send-mail.component';

@Injectable({
  providedIn: 'root'
})
export class SendMailModalService {

  constructor(private modalService: NgbModal) { }

  openModal(email: string, mittente: string): any{
    const modal = this.modalService.open(SendMailComponent, {centered: true, size: 'xl'});
    modal.componentInstance.email = email;
    modal.componentInstance.mittente = mittente;
    return modal;
  }
}
