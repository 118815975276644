import {Component, Injector, Input, OnInit, TemplateRef} from '@angular/core';
import {CrudComponent} from '../../../../shared/components/crud/crud.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {PaginatedDataResponse} from '../../../../shared/models/respose/PaginatedDataResponse';
import {Document} from '../../../../shared/models/Document';
import {BaseResponse} from '../../../../shared/models/respose/BaseResponse';
import {DocumentRequest} from '../../../models/request/DocumentRequest';
import {Pagination} from '../../../../shared/models/Pagination';
import {ComponentsConstants} from '../../../../shared/constants/ComponentsConstants';
import {ModelListConstants} from '../../../../shared/constants/ModelListConstants';
import {DocSignModalService} from '../../../services/docSignModal.service';
import {DocumentMultipleService} from '../../../services/document-multiple.service';
import {DocumentMultipleRequest} from '../../../models/request/DocumentMultipleRequest';

@Component({
  selector: 'app-documents-multiple',
  templateUrl: './documents-multiple.component.html',
  styleUrls: ['./documents-multiple.component.css']
})

export class DocumentsMultipleComponent extends CrudComponent implements OnInit {

  practice_id?: number;
  public paginatedResponse: PaginatedDataResponse<Document> = new PaginatedDataResponse<Document>(0, [], 0, 0, 0);
  public previewLoading = false;
  public pdfSrc = '';
  public components = ComponentsConstants;
  public name?: string;
  private service: any;
  constructor(
    public modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private injector: Injector,
    private docSignModalService: DocSignModalService,
    private documentService: DocumentMultipleService

  ) {
    super(modalService, fb);
  }

  @Input() enableDocumentTypeEdit = true;
  @Input() model?: any;
  @Input() serviceName = 'documentMultiple';
  @Input() modelName?: string;

  ngOnInit(): void {

    this.service = this.injector.get<any>(ModelListConstants[this.serviceName as keyof typeof ModelListConstants]);

    this.form = this.fb.group({
      document_type_id: ['', [Validators.required]],
      documents: this.fb.array([])
    });
    this.updateList({page: 1, elements: this.elements});
  }

  updateList(pagination: Pagination = {page: 1, elements: this.elements}): void{
    const document: DocumentRequest = {
      related_id: this.model?.id,
      related_type: 'App\\Models\\' + this.modelName
    };
    this.service.getPaginatedList(pagination.elements, pagination.page, document, this.order).subscribe(
      (response: BaseResponse<PaginatedDataResponse<Document>>) =>
      {
        this.paginatedResponse = response.data;
      }
    );
  }

  onSubmit({ value, valid }: { value: DocumentMultipleRequest, valid: boolean }): void{
    if (valid){
        this.callRegister(value);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  callRegister(document: DocumentMultipleRequest): void{
    document.related_id = this.model?.id;
    document.related_type = 'App\\Models\\' + this.modelName;
    this.service.register(document).subscribe(() => {
      this.form.reset();
      this.updateList({page: 1, elements: this.elements});
    });
  }

  loadedPreview($action: any): void{
    this.previewLoading = false;
  }

  openPdfPreviewModal(pdfPreview: any, document: Document): void{
    const modal = this.modalService.open(pdfPreview, { size: 'xl' });
    this.previewLoading = true;
    modal.shown.subscribe(() => {
      this.service.getItem(document?.id ?? 0).subscribe((result: any) => {
        this.pdfSrc = (result.data.file) ? result.data.file : '';
      });
    });

    modal.dismissed.subscribe(() => {
      this.pdfSrc = '';
    });

  }

  isPdf(fileName: string): boolean {
    return fileName.toLowerCase().endsWith('.pdf');
  }

  estensione(doc: string): string {
    const ultimoPunto = doc.lastIndexOf('.');
    if (ultimoPunto !== -1) {
      return doc.substring(ultimoPunto).toLowerCase();
    }
    return '';
  }

  leva_estensione(nomeFile: string): string {
    const ultimoPunto = nomeFile.lastIndexOf('.');
    if (ultimoPunto !== -1) {
      return nomeFile.substring(0, ultimoPunto);
    }
    return nomeFile;
  }

  download(document: Document): void{
    this.service.getItem(document?.id ?? 0).subscribe((result: any) => {
      this.downloadFile(result?.data?.file ?? '', '' + this.leva_estensione(document?.original_name ?? ''), this.estensione(document?.original_name ?? ''));
    });
  }

  onSignedDownloadClick(document: Document): void{
    this.service.getSignedItem(document?.id ?? 0).subscribe((result: any) => {
      this.downloadFile(result?.data ?? '', '' + document?.id);
    });
  }

  openTrashModal(content: TemplateRef<any>, document: Document): void{
    this.modalService.open(content, {ariaLabelledBy: 'document-modal-basic-title'}).result.then((result) => {
      this.service.deleteItem(document.id ?? 0).subscribe(() => this.updateList({page: 1, elements: this.elements}));
    }, (reason) => {
    });
  }

  openSignModal(document: Document): void{
    this.docSignModalService.openModal(document);
  }

  downloadPrivacyPolicy(): void{
    this.documentService.downloadPrivacyPolicy(this.model?.id, 'App\\Models\\' + this.modelName).subscribe(r =>
      this.downloadFile(r.data, 'Informativa sulla privacy'));
  }

  openSignFileModal(document: Document): void{
    this.docSignModalService.openSignFileModal(document).result.then(() => this.updateList({page: 1, elements: this.elements}));
  }

  openDoc(): void{
    const modalRef = this.modalService.open(NgbActiveModal);
    modalRef.componentInstance.name = 'Messaggio Test';
  }
}
