import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/shared/models/respose/BaseResponse';
import {Order} from '../../shared/models/Order';
import {PaginatedDataResponse} from '../../shared/models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../../shared/models/request/PaginatedDataRequest';
import {Document} from '../../shared/models/Document';
import {DocumentRequest} from '../../admin/models/request/DocumentRequest';
import {Router} from '@angular/router';
import {HttpService} from '../../shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentOtpService extends HttpService {

  constructor(
    http: HttpClient,
    router: Router) {
    super(http, router);
  }

  // tslint:disable-next-line:max-line-length
  getPaginatedList(entries: number, page: number, data?: DocumentRequest, order?: Order): Observable<BaseResponse<PaginatedDataResponse<Document>>>{
    const request = new PaginatedDataRequest<DocumentRequest>(entries, page, data, order);
    return this.post('documents', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: DocumentRequest): Observable<BaseResponse<Document>>{
    return this.post('documents/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Document>>{
    return this.delete('documents', id);
  }

  getItem(id: number): Observable<BaseResponse<Document>>{
    return this.get('documents/' + id);
  }
}
