import {Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder} from '@angular/forms';
import {DictionaryComponent} from '../../../shared/dictionary/dictionary.component';
import {ContractTypeService} from '../../../../services/contract-type.service';
import {ContractType} from '../../../../../shared/models/ContractType';

@Component({
  selector: 'app-contract-types',
  templateUrl: './contract-types.component.html',
  styleUrls: ['./contract-types.component.css']
})
export class ContractTypesComponent extends DictionaryComponent<ContractType> {

  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: ContractTypeService
  ) {
    super(modalService, fb, service);
  }

}
