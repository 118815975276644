import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DictionaryService} from '../../shared/services/dictionary.service';
import {PracticeState} from '../../shared/models/PracticeState';

@Injectable({
  providedIn: 'root'
})
export class PracticeStateService extends DictionaryService<PracticeState> {

  path = 'practice_states';

}
