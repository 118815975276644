import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  mustMatch(controlName: string, matchingControlName: string): ValidationErrors | null {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (control.value !== matchingControl.value) {
            control.setErrors({ mustMatch: true });
            return { mustMatch: true };
        } else {
            control.setErrors(null);
            return null;
        }
    };
  }

  onlyNumbers(control: AbstractControl): {[key: string]: any} | null {

    if (!/^\d+$/.test(control.value)) {
      return { onlyNumbers: true };
    }
    return null;
  }

  listEmailValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null;
    }

    const regExp: RegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const emails: string[] = value.split(';');
    const wrongEmails: string[] = [];

    emails.forEach((email) => {
      if (!regExp.test(email)){
        wrongEmails.push(email);
      }
    });

    return wrongEmails.length > 0 ? {emails: wrongEmails} : null;
  }

  rangeDateValidator(control: AbstractControl): ValidationErrors | null{
    const value: string = control.value;
    if (!value) {
      return null;
    }
    const date = Date.parse(value);
    const min = Date.parse('1900-01-01');
    const max = Date.now();
    return (date < min || date > max) ? {rangeDate: {rangeMin: min, rangeMax: max}} : null;
  }

  dateFrom1900(control: AbstractControl): ValidationErrors | null{
    const value: string = control.value;
    if (!value) {
      return null;
    }
    const date = Date.parse(value);
    const min = Date.parse('1900-01-01');
    return (date < min) ? {minDate: {min, date}} : null;
  }

}
