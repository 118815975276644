import {Component} from '@angular/core';
import {DictionaryComponent} from "../../../shared/dictionary/dictionary.component";
import {ScheduleEvent} from "../../../../../shared/models/ScheduleEvent";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {CustomValidationService} from "../../../../../shared/services/custom-validation.service";
import {ScheduleEventService} from "../../../../services/schedule-event.service";

@Component({
  selector: 'app-schedule-event',
  templateUrl: './schedule-event.component.html',
  styleUrls: ['./schedule-event.component.css']
})
export class ScheduleEventComponent extends DictionaryComponent<ScheduleEvent> {
  constructor(
    modalService: NgbModal,
    fb: UntypedFormBuilder,
    service: ScheduleEventService,
    public validationService: CustomValidationService
  ) {
    super(modalService, fb, service);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      name: ['', [Validators.required]],
      periodicity: [1, [Validators.required]],
      monthly_frequency: [0, [Validators.required]],
      notify_client: [false, [Validators.required]],
      client_mail_subject: [''],
      client_mail_text: [''],
      notify_operator: [false, [Validators.required]],
      operator_mail_subject: [''],
      operator_mail_text: [''],
      expiration_days: [1, [Validators.required]],
      after_expiration_days: [0, [Validators.required]],
      is_system: ['']
    });
    this.formSearch = this.fb.group({
      name: [''],
      periodicity: [''],
      double_expiration: ['']
    });
    this.updateList({ page: 1, elements: this.elements });
  }

  new(): void {
    this.showHideTable();
    this.showHideForm();
  }

  edit(object: ScheduleEvent): void {
    super.edit(object);
    this.convertNotifyValues();
    this.toggleValidators(this.form.get('notify_client')?.value, ['client_mail_text', 'client_mail_subject']);
    this.toggleValidators(this.form.get('notify_operator')?.value, ['operator_mail_text', 'operator_mail_subject']);
  }

  isDisabled(field: string): string | null {
    return (this.form.get(field)?.value === 0 || this.form.get(field)?.value === false || this.form.get(field)?.value === null) ? 'disabled' : null;
  }

  toggleValidators(event: boolean, fields: string[]): void {
    fields.forEach((field) => {
      if (event) {
        this.form.controls[field].setValidators([Validators.required]);
      } else {
        this.form.controls[field].clearValidators();
      }
      this.form.controls[field].updateValueAndValidity();
    });
  }

  onNotifyClientChange(event: any): void {
    const notifyClientValue = event ? true : false;
    this.form.patchValue({ notify_client: notifyClientValue });
    this.toggleValidators(notifyClientValue, ['client_mail_text', 'client_mail_subject']);
  }

  onNotifyOperatorChange(event: any): void {
    const notifyOperatorValue = event ? true : false;
    this.form.patchValue({ notify_operator: notifyOperatorValue });
    this.toggleValidators(notifyOperatorValue, ['operator_mail_text', 'operator_mail_subject']);
  }

  private convertNotifyValues(): void {
    const notifyClient = this.form.get('notify_client')?.value;
    const notifyOperator = this.form.get('notify_operator')?.value;
    this.form.patchValue({
      notify_client: notifyClient === 1 || notifyClient === true ? true : false,
      notify_operator: notifyOperator === 1 || notifyOperator === true ? true : false
    });
  }
}