<div class="d-flex flex-row justify-content-end">
  <div class="form-group me-2">
    <select (ngModelChange)="changeElements($event)" [(ngModel)]="elements" class="form-control">
      <option value="10">10</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
    </select>
  </div>

  <ngb-pagination
    (pageChange)=changePage($event)
    [(page)]="paginatedResponse.current_page"
    [collectionSize]="paginatedResponse.total"
    [maxSize]="4"
    [pageSize]="paginatedResponse.per_page"
    [rotate]="true"
    class="d-flex justify-content-end"
  >
  </ngb-pagination>
</div>

