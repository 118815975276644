import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ContactService} from '../../admin/services/contact.service';
import {BaseResponse} from '../models/respose/BaseResponse';
import {User} from '../models/User';

export function ivaValidator(contactService: ContactService, contact_id: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return contactService.checkIva({
        iva: control.value,
        contact_id})
        .pipe(
          map((result: BaseResponse<User>) =>
          {
            return result.data ? {ivaExists: {contact: result.data}} : null;
          })
        );
    };
}

